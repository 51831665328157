<template>
  <div class="content">
    <div class="container-fluid card">
      <div class="row">
        <div class="pageTitle">
          <span>{{
            plug_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }}</span>
          <div class="div0">
            <button
              class="btn btn-fill"
              @click="otpIssue()"
              style="background-color: #3bb6ca; border-color: #3bb6ca; "
            >
              OTP 발급
            </button>
          </div>

          <div class="div1">
            <button
              class="btn btn-fill btn-success"
              @click="modifyBtn(deviceId)"
            >
              수정하기
            </button>
          </div>
        </div>
      </div>

      <div class="row" style="display: flex">
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title text-center">{{ topDiv.site_name }}</h4>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title text-center">{{ topDiv.section_name }}</h4>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <p style="font-size:19px;" class="card-title text-center">
                {{ topDiv.site_address }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <device-detail-table
            @useStatus="usesingStatus"
            class="table-hover table-striped"
            :filterStatus="filterStatus"
            :deviceId="deviceId"
          ></device-detail-table>
        </card>
      </div>
    </div>
    <!-- //row -->

    <otp-check-modal
      v-if="showOtpCheckModal"
      @close="showOtpCheckModal = false"
      :serialNumber="plug_number"
    ></otp-check-modal>
    <otp-issue-modal
      v-if="showOtpIssueModal"
      @close="showOtpIssueModal = false"
      :serialNumber="plug_number"
    ></otp-issue-modal>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import DeviceDetailTable from "src/components/Table/DeviceDetailTable.vue";
import OtpCheckModal from "src/components/Modals/OtpCheckModal.vue";
import OtpIssueModal from "src/components/Modals/OtpIssueModal.vue";

export default {
  components: {
    Card,
    DeviceDetailTable,
    OtpCheckModal,
    OtpIssueModal
  },
  data() {
    return {
      useStatus: "",
      filterStatus: "all",
      deviceId: "",
      plug_number: "",
      topDiv: {
        site_name: "",
        section_name: "",
        site_address: ""
      },
      showOtpCheckModal: false,
      showOtpIssueModal: false
    };
  },
  watch: {
    $route(to, from) {
      Object.assign(this.$data, this.$options.data()); //데이터 리셋

      this.deviceId = this.$route.params.deviceId;
      alert(this.deviceId);
      //this.getSiteDetail(this.$route.params.siteId);
    }
  },
  created() {
    //초기 데이터 셋팅
    this.deviceId = this.$route.params.deviceId;
    this.getDeviceDetail();
  },
  methods: {
    modifyBtn(deviceId) {
      if (
        this.useStatus !== "OTP_ACTIVE" &&
        this.useStatus !== "ACTIVE" &&
        this.useStatus !== "UNLIMIT"
      ) {
        this.$router.push("/admin/device/modify/" + deviceId);
        alert("플러그 정보를 수정합니다.");
      } else alert("사용중인 플러그의 정보는 수정할 수 없습니다.");
      return false;
    },
    usesingStatus(status) {
      this.useStatus = status;
      console.log("status", this.useStatus);
    },
    otpIssue(event) {
      this.showOtpIssueModal = true;
    },
    //show the exact plug information
    async getDeviceDetail() {
      console.log(this.deviceId);
      const response = await this.$store.dispatch(
        "FETCH_GET_PLUG_DETAIL",
        this.deviceId
      );
      // console.log("response plug_detail:", response.data);

      //플러그 상세 불러오기
      const data = response.data;

      if (data.result == false || data.data.error) {
        var error = data.data.error;
        var errorText = "";
        if (error == "SESSION_EXPIRED") {
          errorText = "세션 만료 되었습니다.";
          //todo 홈으로 이동
        } else if (error == "INVALID_CORP") {
          errorText = "유효하지 않은 사업자 입니다.";
        } else if (error == "DB_ERROR") {
          errorText = "DB에 오류가 발생했습니다.";
        } else {
          errorText = "작업을 처리하는데 실패하였습니다.";
        }
        alert(errorText);
        return false;
      }
      this.plug_number = data.data.plug_id;
      this.plug_number = this.plug_number;
      this.topDiv.site_name = data.data.site_name;
      this.topDiv.section_name = data.data.section_name;
      this.topDiv.site_address = data.data.site_address;
    }
  }
};
</script>

<style scoped>
/* 플러그 상세 페이지 css */
.div0 {
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
.div1 {
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.container-fluid .row .card .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-fluid .row .dropdown .dropdown-menu {
  left: -130px;
}
.row {
  margin: 15px 0 0 0;
}
</style>
