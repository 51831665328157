<template>
  <div>
    <!-- 검색 영역 -->
    <div class="div1">
      <p class="p1">검색 기준</p>
      <base-select-search
        type="text"
        class="text-center selectInput seletbox"
        :items="searchOptionList"
        v-model="search.id"
      ></base-select-search>
      <div>
        <input
          type="date"
          id="datefield"
          v-model="search.sDate"
          min="2021-01-01"
          max="2050-13-13"
          class="datepic1"
        />
      </div>
      <div>
        <input
          type="date"
          id="datefield"
          v-model="search.eDate"
          min="2021-01-01"
          max="2050-13-13"
          class="datepic2"
        />
      </div>
    </div>

    <div class="div2">
      <p class="p3">구매 일자</p>
      <div class="col-sm-2">
        <base-input-search
          type="text"
          class="text-center selectInput sub2"
          v-model="search.searchText"
          @keypress="enterkey()"
        ></base-input-search>
      </div>
      <button
        type="submit"
        id="searchBtn"
        class="btn btn-info btn-fill float-right col-4 seabtn"
        @click="searchTicket()"
      >
        {{ $t("search") }}
      </button>
    </div>
    <!-- 검색 영역 END-->

    <div v-if="searchUseList.length > 0">
      <div class="pc-table">
        <div class="div-table">
          <div class="div-row">
            <div class="div-cell" v-for="column in tableColumns" :key="column">
              <span
                v-if="
                  column.substr(0, 3) == 'btn' || column.substr(0, 3) == 'adm'
                "
              ></span>
              <span v-else>
                {{ $t(column) }}
              </span>
            </div>
          </div>
          <div
            class="div-row"
            v-for="(item, index) in searchUseList"
            :key="index"
          >
            <div class="div-cell" v-for="column in tableColumns" :key="column">
              <div v-if="column == 'charge'">
                <span> {{ itemValue(item, column) | numCommaFilter }}</span>
              </div>
              <div v-else>
                {{ itemValue(item, column) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-cover">
        <ul class="pagination pagination-no-border">
          <li class="page-item prev-page">
            <button
              aria-label="Previous"
              @click="searchTicket(pageNum - 1)"
              v-bind:class="{ 'page-link': true, disabled: pageNum <= 1 }"
            >
              <span>&lt;&lt;</span>
            </button>
          </li>
          <li
            class="page-item"
            v-for="(item, index) in findPageCount"
            :key="item"
            :class="{ active: pageNum == index + 1 }"
          >
            <a class="page-link" @click="searchTicket(index + 1)">{{
              index + 1
            }}</a>
          </li>
          <li class="page-item page-pre next-page">
            <button
              @click="searchTicket(pageNum + 1)"
              v-bind:class="{
                'page-link': true,
                disabled: pageNum >= findPageCount
              }"
            >
              <span>&gt;&gt;</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div v-else-if="useList.length > 0">
      <div class="pc-table">
        <div class="div-table">
          <div class="div-row">
            <div class="div-cell" v-for="column in tableColumns" :key="column">
              <span
                v-if="
                  column.substr(0, 3) == 'btn' || column.substr(0, 3) == 'adm'
                "
              ></span>
              <span v-else>
                {{ $t(column) }}
              </span>
            </div>
          </div>
          <div class="div-row" v-for="(item, id) in useList" :key="id">
            <div
              class="div-cell"
              v-for="column in tableColumns"
              :key="column.id"
            >
              <div v-if="column == 'charge'">
                <span> {{ itemValue(item, column) | numCommaFilter }}</span>
              </div>
              <div v-else>
                {{ itemValue(item, column) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-cover">
        <ul class="pagination pagination-no-border">
          <li class="page-item prev-page">
            <button
              aria-label="Previous"
              @click="getList(pageNum - 1)"
              v-bind:class="{ 'page-link': true, disabled: pageNum <= 1 }"
            >
              <span>&lt;&lt;</span>
            </button>
          </li>
          <li
            class="page-item"
            v-for="(item, index) in pageCount"
            :key="item"
            :class="{ active: pageNum == index + 1 }"
          >
            <a class="page-link" @click="getList(index + 1)">{{ index + 1 }}</a>
          </li>
          <li class="page-item page-pre next-page">
            <button
              @click="getList(pageNum + 1)"
              v-bind:class="{
                'page-link': true,
                disabled: pageNum >= pageCount
              }"
            >
              <span>&gt;&gt;</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <div class="emptyContent">표시할 내역이 없습니다.</div>
    </div>
  </div>
</template>

<script>
const tableColumns = [
  "siteName",
  "user_id",
  "user_name",
  "mobile_number",
  "start_date",
  "end_date",
  "charge",
  "usetime",
  "leftTime"
];
import "vue2-datepicker/index.css";
// npm install dayjs --save
import dayjs from "dayjs";

export default {
  name: "using-table",
  data() {
    return {
      dateFrom: dayjs()
        .subtract(1, "year")
        .add(1, "day")
        .format("YYYY-MM-DD"),
      dateTo: dayjs().format("YYYY-MM-DD"),
      i: 15,
      pages: { page: 1, pageSize: 15 },
      paginatedDate: [],
      maxLine: 30,
      moreLine: 5,
      listSize: 15,
      useList: [],
      searchUseList: [],
      pageArray: [],
      pageNum: 0,
      pageSize: 15,
      tableColumns: tableColumns,
      totalNum: 0,
      showOtpCheckModal: false,
      showOtpIssueModal: false,
      serialNumber: "",
      lastMobile: "",
      phoneNum: "",
      start: "",
      plug_record_id: "",
      usedTime: "",
      //검색에 쓰일 리스트
      searchDatePropList: [
        { id: "startDate", name: "시작일" },
        { id: "endDate", name: "종료일" }
      ],
      siteList: [],
      search: {
        sDate: dayjs()
          .subtract(1, "year")
          .add(1, "day")
          .format("YYYY-MM-DD"),
        eDate: dayjs().format("YYYY-MM-DD"),
        id: "",
        searchText: ""
      },
      searchOptionList: [
        { id: "all", name: "전체" },
        { id: "site_name", name: "사이트 명" },
        { id: "user_name", name: "사용자 이름" },
        { id: "mob_phone", name: "사용자 핸드폰" }
      ]
    };
  },
  props: {
    listArray: {
      type: Array,
      required: false
    },
    item: {
      type: Object
    },
    column: {
      type: String
    },
    filterStatus: {
      type: String
    }
  },
  created() {
    //초기 데이터 셋팅
    this.getList();
  },
  watch: {
    filterStatus: function() {
      this.getList(1);
    }
  },
  computed: {
    pageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
    findPageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    }
  },
  methods: {
    enterkey() {
      if (window.event.keyCode == 13) {
        this.searchTicket();
      }
    },
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column];
    },
    async getList(page) {
      // 검색 조건 유효성 체크
      var valid = this.checkSearchValid();
      if (!valid) {
        return;
      }

      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;
      const response = await this.$store.dispatch(
        "FETCH_GET_TICKET_LIST",
        this.pages
      );
      console.log("response ticket_list: ", response.data);

      this.useList = [];
      var list = response.data.data.ticket_list;
      this.totalNum = response.data.data.total;
      for (var item of list) {
        var temp = {
          siteName: item.site_name,
          user_id: item.user_id,
          user_name: item.user_name,
          mobile_number: item.mob_phone,
          start_date: item.created,
          end_date: item.end_date,
          charge: item.charge,
          usetime: 300 - item.left_time,
          leftTime: item.left_time
        };
        this.useList.push(temp);
      }
    },
    async searchTicket(page) {
      //시작일, 종료일 유효성 체크
      if (this.search.id === "") {
        this.search.id = "all";
      }
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;

      var data = {};
      data.dateFrom = this.search.sDate;
      data.dateTo = this.search.eDate;
      data.id = this.search.id;
      data.searchValue = this.search.searchText;
      data.page = this.pages.page;
      data.lines = this.pages.pageSize;

      const response = await this.$store.dispatch("SEARCH_TICKET", data);
      console.log(response);
      this.searchUseList = [];
      var list = response.data.data.find_ticket_list;
      this.totalNum = response.data.data.total;
      if (list.length === 0) {
        this.searchUseList = [];
        this.useList = [];
      } else {
        for (var item of list) {
          var temp = {
            siteName: item.site_name,
            user_id: item.user_id,
            user_name: item.user_name,
            mobile_number: item.mob_phone,
            start_date: item.created,
            end_date: item.end_date,
            charge: item.charge,
            usetime: 300 - item.left_time,
            leftTime: item.left_time
          };
          this.searchUseList.push(temp);
        }
      }

      //데이터 셋팅
    },
    checkSearchValid() {
      //시작일, 종료일 유효성 체크
      if (this.search.sDate > this.search.eDate) {
        alert("날짜의 입력 범위를 올바르게 입력해주세요.");
        return false;
      }

      return true;
    }
  }
};
</script>

<style scoped>
h1 {
  color: #454545;
  text-align: center;
}

/* 버튼 */
.adminIssue {
  background: red;
  color: white;
  word-break: keep-all;
}
/*mobile-table css */
.mobile-table .row-title {
  font-size: 25px;
  font-weight: bold;
  width: 70%;
}

.mobile-table .status {
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 18px;
  right: 60px;
  float: right;
}

/* .col-sm-2 {
  -ms-flex: 0 0 16.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 16.666667%;
  max-width: 60.666667%;
  margin: 0 0 0 100px;
} */
.div1 {
  width: 400px;
  position: absolute;
  margin-top: 0px;
}
.div2 {
  padding-top: 60px;
  width: 600px;
  float: left;
}
p {
  width: 100px;
  margin: 0 0 0 20px;
  font-weight: bold;
  position: absolute;
}
/* 사작일 */
.startday {
  margin-left: 135px;
}

/* 날짜 */
.datepic1 {
  height: 31px;
  margin: 42px 0 0 130px;
  position: absolute;
  border-radius: 4px;
  border: 1px solid #4388c1;
  padding-left: 10px;
}
.datepic2 {
  height: 31px;
  margin: 42px 0 0 300px;
  position: absolute;
  border-radius: 4px;
  border: 1px solid #4388c1;
  padding-left: 10px;
}
/* 사이트명 */
.sub {
  margin: 0 0 0 120px;
}

/* 사용상태 */
.sub1 {
  margin: -48px 0 0 590px;
}
.p1 {
  margin: 5px 0 0 20px;
}

/*검색  */
.sub2 {
  margin: -73px 0 0 288px;
}
.p2 {
  margin: -39px 0 0 313px;
}
.p3 {
  margin: 15px 0 0 20px;
}

/* 검색 버튼 */
.seabtn {
  width: 70px;
  font-weight: 400;
  border-radius: 4px;
  background-color: #73bce6;
  border: 1px solid #4388c1;
  color: white;
  height: 30px;
  font-size: 13px;
  padding: 0;
  margin: -12px 0 20px 500px;
}
.btn-info.btn-fill:hover,
.btn-info.btn-fill:focus,
.btn-info.btn-fill:active,
.btn-info.btn-fill.active,
.open > .btn-info.btn-fill.dropdown-toggle {
  background-color: #73bce6;
  color: #ffffff;
}
.emptyContent {
  padding-top: 200px;
}
.seletbox {
  margin: 0 0 0 130px;
}
</style>
