<template>
  <!-- <ul class="nav nav-mobile-menu">
    <li class="nav-item">
      <a class="nav-link" @click="logout()">
        <span class="no-icon">Log out</span>
      </a>
    </li>
  </ul> -->
</template>
<script>
  export default {
    name: 'mobile-menu',
     methods: {
       logout() {
        this.$store.commit('logout');
        localStorage.setItem('token', '');
        alert("로그아웃 되었습니다.");
        this.$router.push("/login");
      }
     }
  }
</script>
<style>
</style>
