/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row d-flex justify-content-center align-items-center">
        <card class="col-11" style="max-width: 1500px">
          <div slot="header" class="joinForm-title">
            <img
              class="btn btn-default backBtn col-xs-2"
              @click="$router.go(-1)"
              src="~@/assets/imgs/return.png"
            />
            <h4 class="card-title text-center col-xs-10">회원가입</h4>
          </div>
          <form>
            <div class="row">
              <base-term
                class="col-sm-12"
                label="이용약관"
                id="terms1"
                :contents="term1_contents"
                msg="이용 약관에 동의하시겠습니까?"
                v-model="signup.check_term1"
              ></base-term>

              <base-term
                class="col-sm-12"
                label="개인정보 처리 방침"
                id="terms2"
                :contents="term2_contents"
                msg="개인정보 처리 방침에 동의하시겠습니까?"
                v-model="signup.check_term2"
              ></base-term>

              <base-input
                class="col-sm-9"
                id="serial_num"
                maxlength="15"
                type="text"
                v-model="signup.serial_num"
                placeholder="플러그 시리얼넘버"
                label="플러그 시리얼넘버"
              />

              <div class="col-sm-3 infoform-btn-div">
                <span
                  class="btn twice-check"
                  style="padding-top: 9px"
                  @click="checkPlug"
                  >등록 확인</span
                >
              </div>
              <p class="status col-12" :style="status.serial_num.style">
                {{ isSerialValid }} {{ status.serial_num.message }}
              </p>
              <base-input
                class="col-sm-9"
                id="useremail"
                type="text"
                v-model="signup.email"
                placeholder="이메일"
                label="이메일"
              />
              <div class="col-sm-3 infoform-btn-div">
                <span
                  class="btn twice-check"
                  style="padding-top: 9px"
                  @click="checkEmail"
                  >중복 확인</span
                >
              </div>
              <p class="status col-12" :style="status.email.style">
                {{ isEmailValid }} {{ status.email.message }}
              </p>
              <base-input
                class="col-12"
                id="password"
                type="password"
                v-model="signup.password"
                placeholder="비밀번호"
                label="비밀번호"
              />
              <p class="status col-12" :style="status.password.style">
                {{ isPasswordValid }}
              </p>
              <base-input
                class="col-12"
                id="repass"
                type="password"
                v-model="checkPassword"
                placeholder="비밀번호 재확인"
                label="비밀번호 재확인"
              />
              <p class="status col-12" :style="status.repass.style">
                {{ isCheckpasswordValid }}
              </p>
              <base-input
                class="col-12"
                type="text"
                label="이름"
                placeholder="이름"
                v-model="signup.name"
              ></base-input>
              <p class="status col-12" :style="status.name.style">
                {{ isNameValid }}
              </p>
              <base-input
                class="col-12"
                type="text"
                label="전화번호"
                placeholder="지역번호를 포함한 전화번호"
                v-model="signup.tel_number"
              ></base-input>
              <p class="status col-12" :style="status.tel.style">
                {{ isTelValid }}
              </p>
              <!-- <base-input
                label="주소"
                class="col-sm-10 add"
                type="text"
                placeholder="우편번호"
                v-model="signup.postcode"
                disabled
              ></base-input>
              <div class="col-sm-2 infoform-btn-div">
                <button
                  type="button"
                  class="btn find-address"
                  @click="layer_pop_post = true"
                >
                  찾기
                </button>
              </div>
              <daum-address
                class="col-12 daum-address"
                id="address"
                name="address"
                :show="layer_pop_post"
                @close="getPostcode"
              />

              <base-input
                class="col-12 add"
                type="text"
                placeholder="주소"
                v-model="signup.address_0"
                disabled
              ></base-input>
              <base-input
                class="col-12"
                type="text"
                placeholder="상세 주소"
                v-model="signup.address_1"
              ></base-input> -->

              <base-input
                class="col-sm-9"
                type="text"
                placeholder="-를 제외한 숫자만 입력"
                label="휴대폰번호"
                :readonly="isReadonly"
                v-model="signup.mob_number"
                maxlength="11"
                oninput="this.value = this.value.replace(/[^0-9.]/g,
                '').replace(/(\..*)\./g, '$1');"
              ></base-input>
              <div class="col-sm-3 infoform-btn-div">
                <button
                  type="button"
                  class="btn btn-primary auth"
                  @click="requestSignupCode"
                >
                  인증번호 받기
                </button>
              </div>
              <p class="status col-6" :style="status.mobile.style">
                {{ isPhoneValid }} {{ status.mobile.message }}
              </p>

              <base-input
                class="col-sm-9"
                type="text"
                placeholder="인증번호"
                label="인증번호"
                v-model="signup.checkcode"
              ></base-input>
              <div class="col-sm-3 infoform-btn-div">
                <button
                  type="button"
                  class="btn btn-primary auth"
                  @click="verifySignupCode"
                >
                  확 인
                </button>
              </div>
              <p class="status col-6" :style="status.checkcode.style">
                {{ status.checkcode.message }}
              </p>
              <button
                class="signup btn btn-fill btn-primary btn-block col-12"
                type="button"
                v-bind:style="
                  isSignUpAvaliable == false
                    ? 'pointer-events: none'
                    : 'pointer-events:auto'
                "
                :disabled="isSignUpAvaliable == false"
                @click="signupBtn"
              >
                가입하기
              </button>
            </div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
// import DaumAddress from "src/components/DaumAddress.vue";
import {
  validateSerialNum,
  validateEmail,
  validatePhone,
  validatePassword,
  validateTel,
  validateName,
} from "src/components/Utils/Validate.js";
import Term1 from "@/assets/terms/term1.txt";
import Term2 from "@/assets/terms/term2.txt";

export default {
  components: {
    Card,
    BaseInput,
    // DaumAddress,
  },
  data() {
    return {
      term1_contents: "",
      term2_contents: "",
      checkPassword: "",
      // layer_pop_post: false,
      signup: {
        // address_0: "",
        // postcode: "",
        // address_1: "",
        serial_num: "",
        email: "",
        password: "",
        name: "",
        tel_number: "",
        mob_number: "",
        checkcode: "",
        code: "",
        check_term1: false,
        check_term2: false,
      },
      status: {
        checkcode: { message: "", style: "", complete: false },
        serial_num: { message: "", style: "", complete: false },
        email: { message: "", style: "", complete: false },
        password: { message: "", style: "", complete: false },
        repass: { message: "", style: "", complete: false },
        mobile: { message: "", style: "", complete: false },
        tel: { message: "", style: "", complete: false },
        name: { message: "", style: "", complete: false },
      },
      style: {
        warn: "color: red",
        success: "color: green",
      },
      isReadonly: false,
    };
  },
  created: function () {
    //약관 불러오기
    this.term1_contents = Term1;
    this.term2_contents = Term2;
  },
  computed: {
    isSerialValid() {
      this.status.serial_num.complete = false;
      if (this.signup.serial_num === "") {
        return;
      } else if (validateSerialNum(this.signup.serial_num)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.serial_num.message = "등록 확인 버튼을 눌러주세요.";
        this.status.serial_num.style = this.style.warn;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.serial_num.message =
          "시리얼 넘버 형식이 올바르지 않습니다.";
        this.status.serial_num.style = this.style.warn;
      }
    },
    isEmailValid() {
      this.status.email.complete = false;
      if (this.signup.email === "") {
        return;
      } else if (validateEmail(this.signup.email)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.email.message = "중복 확인 버튼을 눌러주세요.";
        this.status.email.style = this.style.warn;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.email.message = "이메일 형식이 올바르지 않습니다.";
        this.status.email.style = this.style.warn;
      }
    },
    isPhoneValid() {
      if (this.signup.mob_number === "") {
        return;
      } else if (validatePhone(this.signup.mob_number)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.mobile.style = this.style.warn;
        this.status.mobile.complete = true;
        this.status.mobile.message = "인증번호 받기버튼을 눌러주세요.";
      } else {
        this.status.mobile.style = this.style.warn;
        this.status.mobile.complete = false;
        this.status.mobile.message = "형식에 맞지 않는 번호입니다.";
      }
    },
    isTelValid() {
      if (this.signup.tel_number === "") {
        return;
      } else if (validateTel(this.signup.tel_number)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.tel.style = this.style.success;
        this.status.tel.complete = true;
        return "사용 가능한 전화번호입니다.";
      } else {
        this.status.tel.style = this.style.warn;
        this.status.tel.complete = false;
        return "지역번호를 포함한 전화번호를 입력해주세요. 하이픈(-)을 빼고 입력해주세요.";
      }
    },
    isNameValid() {
      if (this.signup.name === "") {
        return;
      } else if (validateName(this.signup.name)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.name.style = this.style.success;
        this.status.name.complete = true;
        return "";
      } else {
        this.status.name.style = this.style.warn;
        this.status.name.complete = false;
        return "영어 또는 한글만 입력 가능합니다.";
      }
    },
    isPasswordValid() {
      if (this.signup.password === "") {
        return;
      } else if (validatePassword(this.signup.password)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.password.style = this.style.success;
        this.status.password.complete = true;
        return "사용 가능한 비밀번호입니다.";
      } else {
        this.status.password.style = this.style.warn;
        this.status.password.complete = false;
        return "영소문자와 숫자를 혼용하여 8~20자 이내로 설정해주세요.";
      }
    },
    isCheckpasswordValid() {
      if (this.checkPassword === "") {
        return "";
      } else if (this.signup.password === this.checkPassword) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.repass.style = this.style.success;
        this.status.repass.complete = true;
        return "비밀번호가 일치합니다.";
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.repass.style = this.style.warn;
        this.status.repass.complete = false;
        return "비밀번호가 일치하지 않습니다.";
      }
    },
    isSignUpAvaliable() {
      var result = false;
      if (
        this.status.serial_num.complete &&
        this.status.email.complete &&
        this.status.password.complete &&
        this.status.repass.complete &&
        this.status.mobile.complete &&
        this.status.tel.complete &&
        this.status.checkcode.complete &&
        this.status.name.complete &&
        this.signup.check_term1 &&
        this.signup.check_term2
      ) {
        result = true;
      } else {
        result = false;
      }

      return result;
    },
  },
  methods: {
    signupBtn() {
      if (
        this.status.serial_num.complete &&
        this.status.email.complete &&
        this.status.password.complete &&
        this.status.repass.complete &&
        this.status.mobile.complete &&
        this.status.tel.complete &&
        this.status.checkcode.complete &&
        this.status.name.complete &&
        this.signup.check_term1 &&
        this.signup.check_term2
      ) {
        //  document.getElementsByClassName("signup")[0].disabled = false;
        this.signUp();
        return;
      }
      return alert("회원가입에 실패하였습니다.");
    },
    async signUp() {
      const response = await this.$store.dispatch(
        "FETCH_CREATE_PROVIDER",
        this.signup
      );
      if (response.data.result === true) {
        sessionStorage.setItem("signup", true);
        alert("회원가입에 성공하였습니다.");
        this.$router.push("/login");
      } else {
        alert("오류가 발생했습니다. 잠시 후 다시 시도해보세요.");
      }
    },
    // getPostcode(show, postcode, adress) {
    //   var me = this;
    //   if (show) {
    //     me.layer_pop_post = false;
    //     me.signup.address_0 = adress;
    //     me.signup.postcode = postcode;
    //     me.signup.address_1 = "";
    //   } else {
    //     me.layer_pop_post = false;
    //   }
    // },
    async checkEmail() {
      const result = await this.$store.dispatch(
        "FETCH_CHECK_PROVIDER_EMAIL",
        this.signup.email
      );
      if (result.data.data === null) {
        this.status.email.message = "사용 가능한 이메일주소입니다.";
        this.status.email.style = this.style.success;
        this.status.email.complete = true;
      } else if (result.data.data.error === "ACCOUNT_EXISTS") {
        this.status.email.message = "이미 사용중인 이메일주소입니다.";
        this.status.email.style = this.style.warn;
        this.status.email.complete = false;
      } else if (this.signup.email === "") {
        this.status.email.message = "필수정보입니다";
        this.status.email.style = this.style.warn;
        this.status.email.complete = false;
      } else {
        this.status.email.message = "형식에 맞지 않는 이메일주소입니다.";
        this.status.email.style = this.style.warn;
        this.status.email.complete = false;
      }
    },
    async checkPlug() {
      // alert(this.signup.serial_num);
      const result = await this.$store.dispatch(
        "FETCH_CHECK_PROVIDER_SERIAL_NUM",
        this.signup.serial_num
      );
      if (result.data.data === null) {
        this.status.serial_num.message = "사용 가능한 시리얼 넘버입니다.";
        this.status.serial_num.style = this.style.success;
        this.status.serial_num.complete = true;
      } else if (result.data.data.error === "ACCOUNT_EXISTS") {
        this.status.serial_num.message = "이미 등록된 시리얼 넘버입니다.";
        this.status.serial_num.style = this.style.warn;
        this.status.serial_num.complete = false;
      } else if (this.signup.serial_num === "") {
        this.status.serial_num.message = "필수정보입니다";
        this.status.serial_num.style = this.style.warn;
        this.status.serial_num.complete = false;
      } else if (result.data.data.error === "unidentified") {
        this.status.serial_num.message = "등록되지 않은 시리얼 넘버입니다.";
        this.status.serial_num.style = this.style.warn;
        this.status.serial_num.complete = false;
      } else {
        this.status.serial_num.message = "형식에 맞지 않는 시리얼넘버입니다.";
        this.status.serial_num.style = this.style.warn;
        this.status.serial_num.complete = false;
      }
    },
    async requestSignupCode() {
      var regPhone = this.signup.mob_number;
      if (regPhone.length !== 11) {
        alert("휴대폰 번호를 확인해주세요.");
        return false;
      }
      alert("인증번호가 발송되었습니다.");
      this.status.mobile.style = this.style.success;
      this.status.mobile.message = "인증번호가 발송되었습니다.";
      const result = await this.$store.dispatch(
        "FETCH_REQUEST_SIGNUP_CODE",
        regPhone
      );
      this.signup.code = result.data.data.check_code;
      //   휴대전화 본인인증 API를 이용한 본인인증
    },
    async verifySignupCode() {
      var verify = new Object();
      verify.check_code = this.signup.code;
      verify.code = this.signup.checkcode;
      verify.mobile_number = this.signup.mob_number;

      const response = await this.$store.dispatch(
        "FETCH_VERIFY_SIGNUP_CODE",
        verify
      );
      console.log("입력한 본인인증 코드 : ", this.signup.checkcode);
      if (response.data.data === null) {
        //todo  api 생기면 수정해야함
        this.status.checkcode.style = this.style.success;
        this.status.checkcode.complete = true;
        this.status.checkcode.message = "본인인증에 성공하였습니다.";
        this.isReadonly = true;
      } else {
        this.status.checkcode.style = this.style.warn;
        this.status.checkcode.complete = false;
        this.status.checkcode.message = "본인인증에 실패하였습니다.";
        this.isReadonly = false;
      }
    },
  },
};
</script>

<style scoped>
* {
  font-family: Noto Sans KR;
  font-style: normal;
}

.container-fluid {
  max-width: 1000px;
  padding-top: 12vh;
}

.joinForm-title {
  margin-bottom: 25px;
}

.card-title {
  font-weight: bold;
  font-size: 25px;
}

#accountAuth {
  height: 25px;
  padding: 2px 0;
  top: 603px;
  right: 38px;
  position: absolute;
  font-weight: 500;
  font-size: 12.5px;
  line-height: 21px;
  width: 90px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
}
#overlap {
  height: 25px;
  padding: 2px 0;
  top: 6.3rem;
  right: 2rem;
  position: absolute;
  font-weight: 500;
  font-size: 12.5px;
  line-height: 21px;
  width: 90px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
}

.auth {
  min-width: 60px;
  width: 100%;
  height: 40px;
  padding: 2px 0;
  font-weight: 500;
  font-size: 15px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
}

.twice-check {
  min-width: 60px;
  width: 100%;
  height: 40px;
  padding: 2px 0;
  font-weight: 500;
  font-size: 15px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
  margin: 0px;
}

/* .find-address {
  min-width: 60px;
  width: 100%;
  height: 40px;
  padding: 2px 0;
  font-weight: 500;
  font-size: 15px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
  margin: 0px;
} */
.signup {
  height: 65px;
  margin: 15px 0px 15px 0px;
  background: #156ab2;
  border-radius: 3px;
}
.status {
  position: relative;
  top: -10px;
  left: 10px;
  font-size: 12.5px;
  margin: 0px 0px 10px 0px;
  height: 10px;
}

.backBtn {
  position: absolute;
  left: 15px;
  height: 35px;
  padding: 5px 10px;
}

.infoform-btn-div {
  margin: auto auto 16px auto;
}

/* .daum-address {
  margin-bottom: 25px;
} */
</style>
