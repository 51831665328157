<template>
  <div class="content">
    <div class="container-fluid">
      <card>
        <h4 class="settitle">매뉴얼 다운로드</h4>
        <div class="col-md-10">
          <div class="row">
            <a
              href="../../../_web_api/manual/건물관리자 매뉴얼 _ 20231101.pdf"
              download
            >
              <button
                type="button"
                class="btn btn-info btn-fill float-right"
                id="link1"
                value="확인"
              >
                관리자 매뉴얼 (전체)
              </button>
            </a>
            <hr />
            <a
              href="../../../_web_api/manual/건물관리자 매뉴얼 _ 20231101_01 기기 설치하기.pdf"
              download
            >
              <button
                type="button"
                class="btn btn-info btn-fill float-right"
                id="link1"
                value="확인"
              >
                관리자 매뉴얼 _ 1 기기 설치
              </button>
            </a>
            <a
              href="../../../_web_api/manual/건물관리자 매뉴얼 _ 20231101_02 기기 관리하기.pdf"
              download
            >
              <button
                type="button"
                class="btn btn-info btn-fill float-right"
                id="link1"
                value="확인"
              >
                관리자 매뉴얼 _ 2 기기 관리
              </button>
            </a>
            <a
              href="../../../_web_api/manual/건물관리자 매뉴얼 _ 20231101_03 웹 관리하기.pdf"
              download
            >
              <button
                type="button"
                class="btn btn-info btn-fill float-right"
                id="link1"
                value="확인"
              >
                관리자 매뉴얼 _ 3 웹 관리
              </button>
            </a>
            <a
              href="../../../_web_api/manual/건물관리자 매뉴얼 _ 20231101_04 사용 방법.pdf"
              download
            >
              <button
                type="button"
                class="btn btn-info btn-fill float-right"
                id="link1"
                value="확인"
              >
                관리자 매뉴얼 _ 4 사용 방법
              </button>
            </a>
            <hr />
            <a
              href="../../../_web_api/manual/쉐어플러그 설치안내서_간략_231101.pdf"
              download
            >
              <button
                type="button"
                class="btn btn-info btn-fill float-right"
                id="link1"
                value="확인"
              >
                관리자 간단 설치 안내서
              </button>
            </a>
          </div>
        </div>
      </card>
      <card>
        <h4 class="settitle">쉐어플러그 기기 관리 App</h4>
        <div class="col-md-10">
          <div class="row">
            <button
              type="button"
              class="btn btn-info btn-fill float-right"
              id="link1"
              value="확인"
              @click="link('https://m.shareplug.co.kr/providerguide/')"
            >
              App 설치 및 사용 방법
            </button>
            <a href="../../../_web_api/shareplug.apk">
              <button
                type="button"
                class="btn btn-info btn-fill float-right"
                id="link1"
                value="확인"
              >
                App 다운로드 (Apk 파일)
              </button>
            </a>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import { validatePassword } from "src/components/Utils/Validate.js";

export default {
  components: {
    Card,
    BaseInput,
  },
  data() {
    return {
      user: {
        pw: "",
      },
      condition: {
        pass: 0,
      },
    };
  },
  computed: {
    vPass() {
      return validatePassword(this.user.pw);
    },
  },
  methods: {
    async checkpwd() {
      const response = await this.$store.dispatch(
        "COMPARE_PASSWORD",
        this.user.pw
      );
      if (response.data.result) {
        this.condition.pass = 1;
      } else {
        this.condition.pass = 2;
      }
    },
    async checkBtn() {
      await this.checkpwd();
      console.log("condition : ", this.condition.pass);
      if (this.condition.pass === 1) {
        this.$router.push("/admin/user/myInfo");
      }
    },
    async link(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
hr {
  border: none;
  border-top: 1px dotted #f00;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 100%;
}
.col-md-10 {
  width: 300px;
  margin-left: -15px;
}
.passtext {
  width: 100%;
  display: inline-block;
}
.passinput1 {
  width: 100%;
  display: inline-block;
}
.btn-info.btn-fill:hover,
.btn-info.btn-fill:focus,
.btn-info.btn-fill:active,
.btn-info.btn-fill.active,
.open > .btn-info.btn-fill.dropdown-toggle {
  display: inline-block;
}
.float-right {
  width: 100px;
  height: 30%;
  padding: 0;
  margin-bottom: 20px;
}
.row {
  padding-left: 15px;
  padding-bottom: 0.5rem;
}
#saveBtn {
  height: 30px;
  width: 80px;
}
#link1 {
  height: 30px;
  width: 200px;
}
#link2 {
  height: 30px;
  width: 200px;
}
.warn {
  margin-bottom: 0;
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  color: #717171;
  padding: 1px 0 0 0px;
  margin-top: 7px;
  margin-left: 5px;
}
</style>
