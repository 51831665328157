<template>
  <div class="content">
    <div class="container-fluid">
      <card class="card-wrapper">
        <h4 slot="header" v-if="form_type == 'add'" class="card-title">
          {{ $t("addNewSite") }}
        </h4>
        <h4 slot="header" v-else class="card-title">{{ $t("modifySite") }}</h4>

        <form>
          <!-- 사이트명 -->

          <div class="row">
            <div class="col-8">
              <label class="control-label title_size">
                사이트명({{ len.site }}자 이내)
                <a :style="status.required.style"> *필수</a></label
              >
              <base-input
                type="text"
                placeholder="사이트명"
                v-model="site.site_name"
              >
              </base-input>
            </div>
          </div>

          <!-- 전기 종류 -->

          <div class="row">
            <div class="col-12">
              <label class="control-label title_size">
                전기 종류 <a :style="status.required.style"> *필수</a></label
              >

              <div class="electric_type-group">
                <div class="electric_type" @click="site.electric_type = 1">
                  <img
                    class="electric_type-img"
                    v-show="site.electric_type == 1"
                    src="~@/assets/imgs/site/site_01_on.png"
                  />
                  <img
                    class="electric_type-img"
                    v-show="site.electric_type != 1"
                    src="~@/assets/imgs/site/site_01_off.png"
                  />
                </div>
                <div class="electric_type" @click="site.electric_type = 2">
                  <img
                    class="electric_type-img"
                    v-show="site.electric_type == 2"
                    src="~@/assets/imgs/site/site_02_on.png"
                  />
                  <img
                    class="electric_type-img"
                    v-show="site.electric_type != 2"
                    src="~@/assets/imgs/site/site_02_off.png"
                  />
                </div>
                <div class="electric_type" @click="site.electric_type = 3">
                  <img
                    class="electric_type-img"
                    v-show="site.electric_type == 3"
                    src="~@/assets/imgs/site/site_03_on.png"
                  />
                  <img
                    class="electric_type-img"
                    v-show="site.electric_type != 3"
                    src="~@/assets/imgs/site/site_03_off.png"
                  />
                </div>
                <div class="electric_type" @click="site.electric_type = 4">
                  <img
                    class="electric_type-img"
                    v-show="site.electric_type == 4"
                    src="~@/assets/imgs/site/site_04_on.png"
                  />
                  <img
                    class="electric_type-img"
                    v-show="site.electric_type != 4"
                    src="~@/assets/imgs/site/site_04_off.png"
                  />
                </div>
                <div class="electric_type" @click="site.electric_type = 5">
                  <img
                    class="electric_type-img"
                    v-show="site.electric_type == 5"
                    src="~@/assets/imgs/site/site_05_on.png"
                  />
                  <img
                    class="electric_type-img"
                    v-show="site.electric_type != 5"
                    src="~@/assets/imgs/site/site_05_off.png"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- 섹션 -->
          <div class="row">
            <div class="col-12">
              <label class="control-label title_size" style="width: 500px">
                섹션 이름({{ len.section }}자 이내)
                <a :style="status.required.style"> *필수</a>
              </label>
              <label class="control-label title_size" style="width: 120px">
                최대 사용 제한
              </label>
              <label class="control-label title_size"> 정렬 순번 </label>
              <section-input
                type="text"
                placeholder="섹션명"
                id="'section_name_'+index"
                v-for="(section, index) in sections"
                v-bind:key="index"
                v-model="section.name"
                style="width: 685px"
              >
                <span slot="addonRight">
                  <span class="input-group-append">
                    <div
                      class="input-group-text section_add-btn"
                      v-if="index == 0"
                      @click="addSectionForm()"
                    >
                      <span>+</span>
                    </div>
                    <div
                      class="input-group-text section_add-btn"
                      @click="deleteSectionForm(index)"
                    >
                      <span>-</span>
                    </div>

                    <!-- 20230106 jak 순번 제한 추가 -->
                    <div style="width: 70px; margin-left: 50px">
                      <input type="text" v-bind:key="index"
                      v-model="section.max" placeholder="제한" maxlength="2"
                      oninput="this.value = this.value.replace(/[^0-9.]/g,
                      '').replace(/(\..*)\./g, '$1');"" />
                    </div>

                    <div style="width: 70px; margin-left: 50px">
                      <input type="text" v-bind:key="index"
                      v-model="section.num" placeholder="순번" maxlength="2"
                      oninput="this.value = this.value.replace(/[^0-9.]/g,
                      '').replace(/(\..*)\./g, '$1');"" />
                    </div>

                    <!-- 20230106 jak 순번 제한 추가 -->
                  </span>
                </span>
              </section-input>
            </div>
          </div>

          <!-- 주소 -->

          <label class="control-label title_size">
            주소 <a :style="status.required.style"> *필수</a></label
          >
          <button
            type="button"
            class="btn find-address"
            @click="layer_pop_post = true"
          >
            주소검색
          </button>

          <daum-address
            class="col-12 daum-address"
            id="address"
            name="address"
            :show="layer_pop_post"
            @close="getPostcode"
          />
          <div class="col-8">
            <base-input
              type="text"
              class="postzip"
              placeholder="우편번호"
              v-model="site.zipcode"
              disabled
            ></base-input>
            <base-input
              type="text"
              placeholder="주소"
              v-model="site.address_0"
              disabled
            ></base-input>
            <base-input
              type="text"
              placeholder="상세 주소"
              v-model="site.address_1"
            ></base-input>
          </div>
          <!-- 정기권 선택 -->

          <div class="toggle_btn" style="margin:right:50px;">
            <label class="control-label title_size"> 정기권 </label>
            <div class="can-toggle can-toggle--size-large">
              <input id="c" type="checkbox" />
              <label for="c" style="width: 50px">
                <div
                  ref="ticket_flag"
                  class="can-toggle__switch"
                  data-checked="사용"
                  data-unchecked="미사용"
                  @click="clickTicketFlag()"
                ></div>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="display-contents" v-show="site.ticket_flag">
              <!-- <div class="col-md-4"> -->
              <label class="control-label pay_label"> 금액 </label>
              <base-input
                type="text"
                placeholder="정기권 금액"
                v-model="site.price"
                oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
              >
              </base-input>
              <!-- </div> -->

              <!-- <div class="col-md-4"> -->
              <label class="control-label pay_label">
                구매코드(숫자4자리)
              </label>
              <base-input
                type="text"
                placeholder="구매코드(숫자4자리)"
                v-model="site.voucherCode"
                maxlength="4"
              >
              </base-input>
              <!-- </div> -->
            </div>
          </div>

          <!-- 사이트 상태 타이틀 -->
          <div class="row">
            <div class="col-10">
              <label class="control-label title_size mt-4"> 사이트 상태</label>
              <div class="row div-statebox">
                <div class="col-3">
                  <input
                    type="radio"
                    v-model="site.selected"
                    value="true"
                    style="margin-right: 4px"
                  />사용
                </div>
                <div class="col-7">
                  <input
                    type="radio"
                    name=""
                    id="chk_usetime_off"
                    v-model="site.selected"
                    label="all"
                    value="false"
                    style="margin-right: 4px"
                  />사용 안함

                  <input
                    type="checkbox"
                    class="form-check-box site_text"
                    v-model="site.status"
                    value="true"
                    unchecked-value="false"
                    :disabled="site.selected != 'false'"
                  /><label
                    class="lb_otpstr"
                    :class="{ active: site.selected == 'false' }"
                    >( 사용 안함 시에도 OTP 발급 가능 )</label
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- 계좌인증 -->
          <br />
          <h4 slot="header" class="card-title title_size" v-if="show_accinput">
            계좌 인증 <a :style="status.required.style"> *필수</a>
          </h4>
          <form>
            <div class="d-flex" v-if="show_accinput">
              <div class="col-3">
                <base-select
                  v-model="selected"
                  class="bank_select"
                  :items="account_info.bank_name"
                  valueKey="name"
                  title="은행 선택"
                >
                </base-select>
              </div>

              <!-- 예금주 -->
              <div class="col-2">
                <base-input
                  class="bankname"
                  type="text"
                  placeholder="예금주"
                  v-model="account_info.acc_holder"
                ></base-input>
              </div>

              <!-- 계좌번호 입력  -->

              <div class="col-6">
                <base-input
                  type="text"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                  placeholder="계좌번호 -를 제외한 숫자만 입력"
                  label=""
                  v-model="account_info.acc_num"
                ></base-input>
              </div>
            </div>

            <div class="col-12 infoform-btn-div" v-if="show_accinput">
              <!-- axios 업로드 방식 -->
              <label>사본 등록</label>
              <b-form-file
                label="사본 등록"
                truncate-length="22"
                v-model="account_image"
                class="filesave"
                plain
                @click="test()"
                >사본 등록</b-form-file
              >
            </div>

            <!-- 계좌인증 텍스트 -->
            <div class="row" v-if="show_accinput">
              <p class="account_notice">
                <span
                  >- 사본을 등록하시면 영업일 기준 24시간 이내 운영자가 확인 후
                  승인 처리 해드립니다. 통장 사본 이미지 파일이 필요합니다.
                </span>
                <span
                  >- 계좌 인증이 완료된 계정만 정상적으로 정산이 진행될 수
                  있습니다.</span
                >
                <span
                  >- 계좌를 수정하실 경우마다 하단에 히스토리가 남습니다. 해당
                  히스토리는 항상 꼼꼼하게 확인하시기 바랍니다.</span
                ><br />
              </p>
            </div>

            <!-- 계좌번호 테이블  -->
            <div class="row">
              <table id="tb_acoount_history">
                <card
                  class="strpied-tabled-with-hover"
                  body-classes="table-full-width table-responsive"
                >
                  <accountTable
                    @dele_id="del_id"
                    @acc_count="ac_count"
                    @acc_account="ac_acount"
                    v-if="linkcheck !== 'add'"
                    class="table-hover table-striped"
                  >
                  </accountTable>
                </card>
              </table>
            </div>
          </form>

          <!-- 저장하기 -->
          <div class="text-right row">
            <div class="col-10">
              <button
                type="submit"
                class="btn btn-info btn-fill"
                id="saveBtn"
                @click.prevent="saveBtn()"
              >
                저 장
              </button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import accountTable from "src/components/Table/accountTable.vue";
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import BaseSelect from "src/components/Inputs/BaseSelect.vue";
import SectionInput from "src/components/Inputs/SectionInput.vue";
import DaumAddress from "src/components/DaumAddress.vue";

// import EditProfileForm from './UserProfile/EditProfileForm.vue'
// import UserCard from './UserProfile/UserCard.vue'

export default {
  components: {
    Card,
    BaseInput,
    BaseSelect,
    SectionInput,
    accountTable,
    DaumAddress,
  },
  data() {
    return {
      // show_accinput: false, 원래 상태임, 나중에 바꿔야함.
      show_accinput: true,
      delete_id: 0,
      account_count: 0,
      acc_account: "",
      //ac_count: 0,
      linkcheck: "",
      status: {
        required: { style: "" },
      },
      style: {
        warn: "color:red",
        success: "display:none",
      },
      form_type: "add", //등록인지 수정인지 구분
      site: {
        selected: true,
        status: "false", //otp 사용 여부
        options: [
          { item: true, name: "사용" },
          { item: false, name: "사용 안함" },
        ],
        site_id: "",
        site_name: "",
        electric_type: "",
        address_0: "",
        address_1: "",
        zipcode: "",
        price: "",
        voucherCode: "",
        ticket_flag: false,
        status: false,
        isUseTimeOffCheck: false,
      },
      section: {
        page: 1,
        pageSize: 20,
        siteId: "",
      },
      section_num: 0,
      sections: [{ name: "", id: "", num: "1", max: "2", plugCtn: "" }],
      postcode: "",
      plugs: [{ plug: "", name: "" }],
      layer_pop_post: false,
      country: "",
      max_cnt_section: 20, //생성할 수 있는 섹션 최대 개수,
      // isUseTimeOffCheck: false

      //계좌번호
      selected: "",
      account_info: {
        acc_requested: "",
        bank_name: [
          { name: "국민은행", id: "1" },
          { name: "기업은행", id: "2" },
          { name: "우리은행", id: "3" },
          { name: "신한은행", id: "4" },
          { name: "하나은행", id: "5" },
          { name: "농협", id: "6" },
          { name: "산업은행", id: "7" },
          // { name: "국민은행", id: "8" },
          { name: "수협은행", id: "9" },
          { name: "신협중앙회", id: "10" },
          { name: "지역농,축협", id: "11" },
          { name: "새마을금고", id: "12" },
          { name: "우체국", id: "13" },
          // { name: "저축은행", id: "14" },
          { name: "한국씨티", id: "15" },
          { name: "카카오뱅크", id: "16" },
          { name: "케이뱅크", id: "17" },
          { name: "토스뱅크", id: "18" },
          { name: "전북은행", id: "19" },
          { name: "광주은행", id: "20" },
          { name: "대구은행", id: "21" },
          { name: "경남은행", id: "22" },
          { name: "부산은행", id: "23" },
          { name: "제주은행", id: "24" },
          { name: "산림조합중앙회", id: "25" },
          { name: "SC제일은행", id: "26" },
          { name: "뱅크오브아메리카", id: "27" },
          { name: "도이치은행", id: "28" },
          { name: "중국은행", id: "29" },
          { name: "중국건설은행", id: "30" },
          { name: "중국공상은행", id: "31" },
          { name: "BNP파리바은행", id: "32" },
          { name: "BOA", id: "33" },
          { name: "HSBC은행", id: "34" },
          { name: "JP모간체이스은행", id: "35" },
        ],
        acc_holder: "",
        acc_num: "",
        ip_address: "",
        status: "",
        acc_check: "",
      },
      account_image: [],
      now_time: [],
      len: {
        site: 20,
        section: 20,
      },
      dateTime: "",
    };
  },
  watch: {
    $route(to, from) {
      Object.assign(this.$data, this.$options.data()); //데이터 리셋

      if (this.$route.params.siteId) {
        //수정일경우
        this.form_type = "modify";
        this.getSiteDetail(this.$route.params.siteId);
      }
    },
    //변수 입력값에 조건 넣기
    "site.site_name": function () {
      this.site.site_name = this.site.site_name
        .replace(/[^0-9|^a-z|^A-Z|^ㄱ-ㅎ|^ㅏ-ㅣ|^가-힣| ]/, "")
        .substring(0, this.len.site);
    },
    // "site.price": function () {
    //   this.site.price = this.site.price.replace(/[^0-9]/, "").substring(0, 8);
    //   this.site.price = Number(this.site.price);
    // },
    "site.voucherCode": function () {
      this.site.voucherCode = this.site.voucherCode
        .replace(/[^0-9]/, "")
        .substring(0, this.len.voucherCode);
    },
    "site.selected": function () {
      if (this.site.selected == "true") {
        this.site.status = false;
      }
    },
    sections: {
      handler: function (val, oldVal) {
        val.filter(function (v, i) {
          if (v && v.name) {
            console.log(v.num);
            v.name = v.name
              .replace(/[^0-9|^a-z|^A-Z|^ㄱ-ㅎ|^ㅏ-ㅣ|^가-힣| ]/, "")
              .substring(0, 20);
          }
        });
      },
      deep: true,
    },
  },

  async created() {
    var today = new Date();
    var date =
      today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate();
    var time =
      today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
    this.dateTime = date + "" + time;

    this.linkcheck = this.$route.fullPath.split("/site/")[1];
    if (this.$route.params.siteId) {
      //수정일경우
      this.form_type = "modify";
      this.status.required.style = this.style.success;
      this.getSiteDetail(this.$route.params.siteId);
    } else {
      this.status.required.style = this.style.warn;
      this.show_accinput = true;
    }
    this.ac_acount();
  },

  methods: {
    del_id(account_id) {
      this.show_accinput = true;
      this.delete_id = account_id;
    },
    ac_count(count) {
      this.account_count = count;
    },
    ac_acount(status) {
      this.acc_account = status;

      if (status === "R") {
        this.show_accinput = false;
      }
    },

    async submit() {
      const formData = new FormData();
      formData.append("account_image", this.account_image);
      formData.append("time", this.dateTime);
      formData.append("request", "File_Upload");
      await axios
        .post("api.php", formData, {
          baseURL: "https://console.shareplug.co.kr/_web_api",
          headers: {
            accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
          // withCredentials: true,
        })
        .then(function (res) {
          console.log(res);
        });
    },
    saveBtn() {
      if (this.form_type == "modify") {
        this.updateSite();
      } else {
        this.addSite();
      }
    },

    async addAccount(site_id) {
      if (!this.acc_val()) {
        return false;
      }
      const modifying = new Object();
      modifying.site_id = site_id;
      modifying.bank_name = this.selected;
      modifying.acc_holder = this.account_info.acc_holder;
      modifying.acc_num = this.account_info.acc_num;
      modifying.acc_upload =
        "https://console.shareplug.co.kr/_web_api/account_uploadFile/" +
        this.dateTime +
        this.account_image.name;

      const response = await this.$store.dispatch(
        "FETCH_CREATE_ACCOUNT",
        modifying
      );
      await this.$store.dispatch("FETCH_GET_ACCOUNT_CANCEL", this.delete_id);
      console.log(response.data);
      this.submit();
    },
    async updateAccount(site_id) {
      if (!this.acc_val()) {
        return false;
      }

      const modifying = new Object();
      // modifying.acc_requested= this.accountnum.acc_requested;
      modifying.site_id = site_id;
      modifying.bank_name = this.selected;
      modifying.acc_holder = this.account_info.acc_holder;
      modifying.acc_num = this.account_info.acc_num;
      modifying.acc_upload =
        "https://console.shareplug.co.kr/_web_api/account_uploadFile/" +
        this.dateTime +
        this.account_image.name;

      const response = await this.$store.dispatch(
        "FETCH_UPDATE_ACCOUNT",
        modifying
      );
      await this.$store.dispatch("FETCH_GET_ACCOUNT_CANCEL", this.delete_id);
      console.log(response.data);
      this.submit();
    },
    //섹션
    addSectionForm() {
      var len = this.sections.length;
      console.log("len : ", len);
      if (len >= this.max_cnt_section) {
        alert("섹션을 더이상 추가할 수 없습니다.");
        return;
      }
      for (var section of this.sections) {
        if (!section.name) {
          alert("섹션명을 차례대로 입력해주세요.");
          return;
        }
      }

      this.sections.push({ name: "", id: "", num: "", max: "2", plugCtn: "" });
    },
    deleteSectionForm(i) {
      console.log("i:", i);
      if (this.sections.length === 1) {
        alert("섹션은 필수적으로 하나 이상 등록해야 합니다.");
      } else {
        if (this.form_type == "add") {
          if (this.plugs[0]["plug"] === "") {
            this.sections.splice(i, 1);
          }
        } else {
          console.log(this.sections[i]);
          console.log(this.plugs[i]);
          if (this.plugs[i] === undefined) {
            this.sections.splice(i, 1);
          } else if (this.plugs[i].plug.length > 0) {
            console.log("등록plug 수: ", this.plugs[i].plug.length);
            alert("플러그가 존재하는 섹션은 삭제가 불가합니다.");
          } else {
            this.sections.splice(i, 1);
            this.plugs.splice(i, 1); // jak 20220106 추가
          }
        }
      }
    },
    maxLength(id, maxLen) {
      //input 텍스트박스 글자수 제한
      var inputValue = document.getElementById(id).value;
      if (maxLen == null) {
        maxLen =
          $("#" + id).attr("maxLength") != null
            ? $("#" + id).attr("maxLength")
            : 1000;
      }

      if (inputValue.length > maxLen) {
        document.getElementById(id).value = inputValue.substring(0, maxLen);
      }
    },
    getPostcode: function (show, zipcode, address) {
      var me = this;
      if (show) {
        me.layer_pop_post = false;
        me.site.address_0 = address;
        me.site.zipcode = zipcode;
        me.site.address_1 = "";
      } else {
        me.layer_pop_post = false;
      }
      console.log("me", me.site.address_0 + "  " + me.site.zipcode);
    },
    clickTicketFlag: function () {
      this.site.ticket_flag = !this.site.ticket_flag;
    },
    acc_val: function () {
      if (
        this.selected === "" ||
        this.account_info.acc_holder === "" ||
        this.account_info.acc_num === "" ||
        this.account_image === "" ||
        this.account_image === null
      ) {
        return false;
      } else {
        console.log("acc_val = true");
        return true;
      }
    },
    getExtensionOfFilename(filename) {
      var _fileLen = filename.length;

      /**
       * lastIndexOf('.')
       * 뒤에서부터 '.'의 위치를 찾기위한 함수
       * 검색 문자의 위치를 반환한다.
       * 파일 이름에 '.'이 포함되는 경우가 있기 때문에 lastIndexOf() 사용
       */
      var _lastDot = filename.lastIndexOf(".");

      // 확장자 명만 추출한 후 소문자로 변경
      var _fileExt = filename.substring(_lastDot, _fileLen).toLowerCase();

      return _fileExt;
    },
    checkValidate: function (mode) {
      if (this.account_image.length === 0) {
        return true;
      } else {
        let checkedFile = true;
        if (!this.account_image) {
          checkedFile = false;
        } else {
          let fileExt = this.getExtensionOfFilename(this.account_image.name);
          switch (fileExt) {
            case ".jpeg":
            case ".jpg":
            case ".png":
            case ".gif":
            case "bmp":
              checkedFile = true;
              break;
            default:
              checkedFile = false;
          }
        }
        if (!checkedFile) {
          alert("이미지 파일만 등록하실수 있습니다.");
          return false;
        }
      }

      if (this.sections) {
        var CheckSectionMax = true;
        var CheckList = this.sections;

        for (var key in CheckList) {
          if (CheckList[key].max === "") {
            CheckSectionMax = false;
          }

          if (CheckList[key].num === "") {
            CheckList[key].num = "99";
          }
          // console.log(CheckList[key].max);
        }

        if (!CheckSectionMax) {
          alert("사용제한을 입력해주세요.");
          return false;
        }
      } else {
        alert("최대사용제한을 입력해주세요.");
      }

      if (this.site.site_name === "") {
        alert("사이트명을 입력하세요");
        return false;
      } else if (this.site.electric_type === "") {
        alert("전기 종류를 선택하세요");
        return false;
      } else if (
        this.form_type !== "modify" &&
        this.show_accinput &&
        !this.acc_val()
      ) {
        alert("계좌 정보를 등록하세요.");
        console.log("계좌정보 갯수: ", this.account_count);
        return false;
      } else if (!this.sections[this.sections.length - 1].name) {
        alert("섹션 이름을 입력하세요.");
        return false;
      } else if (this.site.address_0 === "") {
        alert("주소를 입력하세요");
        return false;
      }
      //정기권 사용을 눌렀을 경우
      if (this.site.ticket_flag) {
        if (this.site.price == "" || this.site.price == "0") {
          alert("정기권 금액은 1원 이상 입력하세요.");
          return false;
        } else if (this.site.voucherCode.length != 4) {
          alert("구매코드를 4자리 입력하세요.");
          return false;
        }
      }
      if (mode === "add") {
        if (!this.account_image) {
          alert("계좌 인증시에는 반드시 통장사본 사진이 필요합니다.");
          return false;
        }
      }

      //20230107 jak 기능추가 차후 계좌 인증정보 추가 할것
      if (
        this.account_image.length !== 0 ||
        this.account_info.acc_holder !== "" ||
        this.account_info.acc_num !== "" ||
        this.selected !== "" ||
        this.selected === "all"
      ) {
        if (this.selected === "" || this.selected === "all") {
          alert("은행명을 선택해주세요.");
          return false;
        }

        if (this.account_info.acc_holder === "") {
          alert("예금주를 입력해주세요.");
          return false;
        }

        if (this.account_info.acc_num === "") {
          alert("계좌번호를 입력해주세요.");
          return false;
        }

        if (this.account_image.length === 0) {
          alert("통장 사본 사진을 등록해주세요.");
          return false;
        }
      }

      alert(
        "승인되지 않은 계좌 변경 요청건이 있을 경우, 추가적인 계좌 변경을 요청할 수 없습니다. 기존 건을 삭제하거나 승인을 기다리시기 바랍니다"
      );
      return true;
    },

    async updateSite() {
      const mode = "mod";
      if (!this.checkValidate(mode)) {
        return false;
      }
      const response = await this.$store.dispatch(
        "FETCH_UPDATE_SITE",
        this.site
      );
      console.log("update site", response.data);
      const data = response.data;
      // console.log(data.data.site_id);

      if (data.result == false || data.data.error) {
        var error = data.data.error;
        var errorText = "";
        if (error == "SESSION_EXPIRED") {
          errorText = "세션 만료 되었습니다.";
          //todo 홈으로 이동
        } else if (error == "INVALID_CORP") {
          errorText = "유효하지 않은 사업자 입니다.";
        } else if (error == "DB_ERROR") {
          errorText = "DB에 오류가 발생했습니다.";
        } else {
          errorText = "작업을 처리하는데 실패하였습니다.";
        }
        alert(errorText);
        return false;
      }
      this.updateAccount(response.data.data.site_id);
      this.updateSection(response.data.data.site_id);
    },
    async addSite() {
      const mode = "add";
      if (!this.checkValidate(mode)) {
        return false;
      }
      const response = await this.$store.dispatch(
        "FETCH_CREATE_SITE",
        this.site
      );
      console.log("add_site", response.data);

      //사이트 등록
      if (response.data.result === false) {
        alert("error");
      }

      this.addSection(response.data.data.site_id);
      this.addAccount(response.data.data.site_id);
    },

    async addSection(site_id) {
      var addCount = 0; //섹션 등록 완료시 addCount++
      console.log(site_id);
      const params = new URLSearchParams();
      params.append("request", "create_section");
      params.append("site_id", site_id);
      // params.append("section_id", section_id);
      params.append("name", "");
      params.append("num", "");
      params.append("max", "");
      // params.append("type", '');
      params.append(
        "max_active_plug",
        this.$store.state.section.max_active_plug
      );
      // var section_arr = [...this.sections].reverse(); 2023.01.06 jak
      var section_arr = [...this.sections];
      for (var section of section_arr) {
        if (this.form_type === "modify" && addCount === 0) {
          params.append("type", "modify");
        } else {
          params.append("type", "add");
        }

        params.set("name", section.name);
        params.set("num", section.num);
        params.set("max", section.max);
        console.log(section.name);
        console.log(params);

        await this.$axios.post("", params).then((res) => {
          const data = res.data;
          console.log("data: " + JSON.stringify(data));
          console.log(res.data);

          if (data.result == true && !data.data.error) {
            addCount++;
          } else {
            var error = data.data.error;
            var errorText = "";
            if (error == "SESSION_EXPIRED") {
              errorText = "세션 만료 되었습니다.";
              this.$router.push("/login");
            } else if (error == "INVALID_CORP") {
              errorText = "유효하지 않은 사업자 입니다.";
            } else if (error == "DB_ERROR") {
              errorText = "DB에 오류가 발생했습니다.";
            } else {
              errorText = "작업을 처리하는데 실패하였습니다.";
            }
            alert(errorText);
            return false;
          }
        });
      } //for문end

      if (Number(addCount) == this.sections.length) {
        alert("정상적으로 사이트가 등록되었습니다.");
        this.$router.push("/admin/site/list");
      }
    },
    async updateSection(site_id) {
      // var section_arr = [...this.sections].reverse(); 2023.01.06 jak
      var section_arr = [...this.sections];
      let data = [];
      data.id = site_id;
      data.name = section_arr;
      console.log(data);

      const response = await this.$store.dispatch("UPDATE_SECTION", data);
      console.log(response);

      if (response.data.result === true) {
        alert("정상적으로 사이트가 등록되었습니다.");
        this.$router.push("/admin/site/list");
      }
    },

    async getSiteDetail(siteId) {
      const response = await this.$store.dispatch(
        "FETCH_GET_SITE_DETAIL",
        siteId
      );
      console.log("response site_detail: ", response.data);
      if (
        response.data.result == false ||
        (response.data.data && response.data.data.error)
      ) {
        var error = response.data.data.error;
        var errorText = "";
        if (error == "SESSION_EXPIRED") {
          errorText = "세션 만료 되었습니다.";
          //todo 홈으로 이동
        } else if (error == "INVALID_CORP") {
          errorText = "유효하지 않은 사업자 입니다.";
        } else if (error == "DB_ERROR") {
          errorText = "DB에 오류가 발생했습니다.";
        } else {
          errorText = "작업을 처리하는데 실패하였습니다.";
        }
        alert(errorText);

        //저장 버튼 비활성화
        var btn = document.getElementById("saveBtn");
        btn.disabled = "disabled";

        return false;
      }

      //todo 변수 셋팅
      this.site.site_id = response.data.data.site_id;
      this.site.site_name = response.data.data.name;
      this.site.electric_type = response.data.data.type;
      this.site.address_0 = response.data.data.address_0;
      this.site.address_1 = response.data.data.address_1;
      this.site.zipcode = response.data.data.zipcode;
      this.site.price = response.data.data.subscribe_price;
      this.site.voucherCode = response.data.data.purchase_code;
      //this.site.ticket_flag = response.data.data.subscribe === 'approved' ? true : false;
      var ticket = response.data.data.subscribe === "approved" ? true : false;
      this.site.selected =
        response.data.data.status === "ACTIVE" ? "true" : "false";
      if (
        this.site.selected == "false" &&
        response.data.data.otp === "otp_use"
      ) {
        this.site.status = true;
      } else {
        //this.site.otp = response.data.data.otp;
        this.site.status = false;
      }
      // if(response.data.data.subscribe === 'approved') {
      //   this.site.ticket_flag  = !this.site.ticket_flag;
      //   // !this.clickTicketFlag;
      // }

      //정기권을 사용중이라면
      if (ticket) {
        var elem = this.$refs.ticket_flag;
        elem.click();
      }

      this.getSectionList(siteId);
    },

    async getSectionList() {
      this.section.siteId = this.site.site_id;
      const response = await this.$store.dispatch(
        "FETCH_GET_SECTION_LIST",
        this.section
      );
      console.log("response section_list:", response.data);

      // this.sectionList = response.data.data.sections;

      const data = response.data;
      console.log("data: " + JSON.stringify(data));

      if (data.result != true || !data.data || data.data.error) {
        var error = data.data ? data.data.error : "";
        var errorText = "";
        if (error == "SESSION_EXPIRED") {
          errorText = "세션 만료 되었습니다.";
          //todo 홈으로 이동
        } else if (error == "INVALID_CORP") {
          errorText = "유효하지 않은 사업자 입니다.";
        } else if (error == "DB_ERROR") {
          errorText = "DB에 오류가 발생했습니다.";
        } else {
          errorText = "작업을 처리하는데 실패하였습니다.";
        }
        alert(errorText);

        //저장 버튼 비활성화
        var btn = document.getElementById("saveBtn");
        btn.disabled = "disabled";

        return false;
      }

      var PlugCount = "";
      var list = data.data.sections;

      this.sections = [];

      for (var section of list) {
        PlugCount = section.plug.length;
        this.sections.push({
          name: section.name,
          id: section.section_id,
          num: section.section_priority,
          max: section.max_active_plug,
          plugCtn: PlugCount,
        });
      }

      this.plugs = [];

      for (var section of list) {
        this.plugs.push({
          plug: section.plug,
          name: section.name,
        });
      }
    },
  },
};
</script>
<style scoped>
.card-wrapper {
  min-width: 800px;
}

.card-wrapper form {
  width: 800px;
}

.filesave {
  width: 250px;
}
.card-title {
  font-weight: 400;
  padding-top: 5px;
}
/* 타이틀 사이즈 */
.card form [class*="col-"]:first-child {
  padding-left: 10px;
}
.title_size {
  font-size: 15px;
  font-weight: bold;
  color: #9a9a9a;
}

.postzip {
  width: 250px;
}

/* 산슈 사이트 생성시 필수항목에 발생 */
.Required {
  color: red;
}

.d-flex {
  margin-left: -10px;
}
/* 사본등록 영역 */
.infoform-btn-div {
  margin: -10px auto auto -5px;
}

/* 계좌 인증 텍스트 영역 */
.account_notice {
  padding: 5px 18px;
}

.account_notice span {
  display: block;
  font-weight: 400;
  font-size: 13px;
  color: #9a9a9a;
}

/* 계좌인증 테이블 영역 */
table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

/* 테이블 역역안쪽 텍스트 사이즈 */
#tb_acoount_history {
  font-size: 14px;
}

/* 신규사이트 등록 */
.find-address {
  font-size: 12px;
  width: 90px;
  height: 25px;
  padding: 4px;
  margin: 11px 0px 11px 15px;
  background-color: #e9ecef;
  color: #777;
}

/* 전기 종류 선택 */
.electric_type-group {
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.electric_type {
  margin-right: 4px;
}

.electric_type-img {
  width: 134px;
  height: 120px;
}

/* 섹션 영역 */

.input-group-text {
  width: 35px;
}

.input-group-text span {
  text-align: center;
  margin: 0 auto;
}

.section1 {
  padding-left: 15px !important;
  padding-right: 5px !important;
}

/* 사이트 상태 메세지 영역 */
.div-statebox {
  margin: 0px 0px 0px 5px !important;
}
.div-statebox > div {
  display: flex;
  margin: -20px 0 0 0;
  line-height: 2.3;
}
.row {
  margin-top: 20px;
}
.site_text {
  margin-left: 15px;
}
.lb_otpstr {
  font-size: 12px;
  line-height: 36px;
  margin: 0 0 0 6px;
}
.lb_otpstr.active {
  color: black;
}
.check_st {
  margin-top: 8px;
  margin-left: -2px;
  color: #9a9a9a;
  font-size: 12px;
}

/* 우편번호 영역 */
.add_code {
  width: 150px;
  margin-right: 10px;
}
.pay_label {
  margin-left: 20px;
  margin-right: 20px;
}
table[data-v-265a2f24] {
  width: 80%;
  border-collapse: collapse;
  text-align: center;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding: 0;
  margin-right: auto;
  margin-left: auto;
}
.text-center {
  width: 67%;
}
</style>

<style lang="scss">
@mixin can-toggle-branding(
  $can-toggle-off-color: #777,
  $can-toggle-on-color: #42d0ed,
  $can-toggle-inactive-text: rgba(white, 0.5),
  $can-toggle-transition: cubic-bezier(0, 1, 0.5, 1)
) {
  input[type="checkbox"] {
    &[disabled] ~ label {
      color: rgba($can-toggle-off-color, 0.5);
    }

    &:focus ~ label,
    &:hover ~ label {
      .can-toggle__switch {
        background-color: $can-toggle-off-color;
        &:after {
          color: darken($can-toggle-off-color, 10%);
        }
      }
    }
    &:hover ~ label {
      color: darken($can-toggle-off-color, 5%);
    }
    &:checked {
      ~ label {
        &:hover {
          color: darken($can-toggle-on-color, 3%);
        }

        .can-toggle__switch {
          background-color: lighten($can-toggle-on-color, 5%);
          &:after {
            color: darken($can-toggle-on-color, 5%);
          }
        }
      }

      &:focus,
      &:hover {
        ~ label {
          .can-toggle__switch {
            background-color: $can-toggle-on-color;
            &:after {
              color: darken($can-toggle-on-color, 10%);
            }
          }
        }
      }
    }
  }

  label {
    .can-toggle__switch {
      transition: background-color 0.3s $can-toggle-transition;
      background: lighten($can-toggle-off-color, 5%);
      &:before {
        color: $can-toggle-inactive-text;
      }
      &:after {
        // Autoprefixer choked here, so making the prefixes explicit
        -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
        transition: transform 0.3s $can-toggle-transition;
        color: $can-toggle-off-color;
      }
    }
  }
}

@mixin can-toggle-appearance(
  $can-toggle-width: 134px,
  $can-toggle-height: 36px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 14px,
  $can-toggle-switch-font-size: 12px,
  $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
) {
  $can-toggle-switch-width: $can-toggle-width/2;

  input[type="checkbox"] {
    &:focus ~ label,
    &:hover ~ label {
      .can-toggle__switch {
        &:after {
          box-shadow: $can-toggle-shadow;
        }
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:after {
            transform: translate3d(
              $can-toggle-width -
                ($can-toggle-switch-width + $can-toggle-offset),
              0,
              0
            );
          }
        }
      }
      &:focus,
      &:hover {
        ~ label {
          .can-toggle__switch {
            &:after {
              box-shadow: $can-toggle-shadow;
            }
          }
        }
      }
    }
  }

  label {
    font-size: $can-toggle-label-font-size;

    .can-toggle__switch {
      height: $can-toggle-height;
      flex: 0 0 $can-toggle-width;
      border-radius: $can-toggle-border-radius;

      &:before {
        left: $can-toggle-width/2;
        font-size: $can-toggle-switch-font-size;
        line-height: $can-toggle-height;
        width: $can-toggle-width/2;
        padding: 0 12px;
      }

      &:after {
        top: $can-toggle-offset;
        left: $can-toggle-offset;
        border-radius: $can-toggle-border-radius/2;
        width: $can-toggle-switch-width - $can-toggle-offset;
        line-height: $can-toggle-height - ($can-toggle-offset * 2);
        font-size: $can-toggle-switch-font-size;
      }

      &:hover {
        &:after {
          box-shadow: $can-toggle-shadow;
        }
      }
    }
  }
}

.can-toggle {
  position: relative;
  margin-bottom: 15px;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  //overflow: hidden;

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &[disabled] ~ label {
      pointer-events: none;
      .can-toggle__switch {
        opacity: 0.4;
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:before {
            content: attr(data-unchecked);
            left: 0;
          }

          &:after {
            content: attr(data-checked);
          }
        }
      }
      // &:focus,
      // &:hover {
      //   ~ label {
      //   }
      // }
    }
  }

  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;

    .can-toggle__switch {
      position: relative;

      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
      }

      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: white;
        transform: translate3d(0, 0, 0);
      }
    }

    // Default values for .can-toggle class
    @include can-toggle-branding;
    @include can-toggle-appearance;

    &.can-toggle--size-large {
      @include can-toggle-appearance(
        160px,
        50px,
        4px,
        2px,
        14px,
        14px,
        0 4px 4px rgba(black, 0.4) 0.4
      );
    } // Switch shadow on hover/
  }
}
</style>
