<template>
  <div>
    <!-- 검색 영역 -->
    <div>
      <h4 class="pageTitle">
        {{ $t("menu.device.list") }}
        <button class="PlugPlus" @click="$router.push('/admin/device/add')">
          플러그 추가
        </button>
      </h4>
    </div>

    <div class="div1">
      <p>사이트명</p>
      <base-select-search
        type="text"
        class="text-center sub"
        :items="siteList"
        title="사이트명"
        v-model="search.site"
        @change="getSectionList()"
      ></base-select-search>

      <p class="p2">섹션 명</p>
      <base-select-search
        type="text"
        class="text-center sub2"
        :items="sectionList"
        title="섹션명"
        v-model="search.section"
      ></base-select-search>
    </div>

    <div class="div2">
      <p class="p3">플러그 상태</p>

      <input
        type="radio"
        name=""
        id="chk_usetime_off"
        v-model="search.useStatus"
        label="all"
        value="all"
        class="radiobtn sub3"
        style="margin-right: 4px"
      />전체
      <input
        type="radio"
        id="chk_usetime_off"
        v-model="search.useStatus"
        label="waiting"
        class="radiobtn"
        value="using"
      />사용중
      <input
        type="radio"
        id="chk_usetime_off"
        v-model="search.useStatus"
        label="waiting"
        class="radiobtn"
        value="waiting"
      />대기중
      <p class="p1">사용 상태</p>
      <input
        type="radio"
        id="chk_usetime_off"
        v-model="search.plugStatus"
        label="all"
        value="all"
        class="radiobtn sub1"
        style="margin-right: 4px"
      />전체
      <input
        type="radio"
        v-model="search.plugStatus"
        value="select"
        class="radiobtn"
      />상태 선택
      <div v-if="this.search.plugStatus == 'select'" class="sub5">
        <input
          type="checkbox"
          class="selbtn"
          v-model="search.onoff"
          value="'ON'"
        />ON
        <input
          type="checkbox"
          class="selbtn"
          v-model="search.onoff"
          value="'OFF'"
        />OFF
        <input
          type="checkbox"
          class="selbtn"
          v-model="search.onoff"
          value="'BROKEN'"
        />
        고장
      </div>
      <p class="p4">검색</p>
      <base-input-search
        placeholder="플러그 ID만 검색가능"
        type="text"
        class="text-center sub4"
        v-model="search.searchValue"
      ></base-input-search>
      <button
        type="submit"
        id="searchBtn"
        class="btn btn-info btn-fill searchbtn"
        @click="findPlug()"
      >
        검 색
      </button>
    </div>

    <!-- 검색 영역 END-->
    <div v-if="searchData.length > 0">
      <div class="pc-table">
        <div class="div-table">
          <div class="div-row">
            <div
              class="div-cell"
              v-for="column in Search_tableColumns"
              :key="column"
            >
              <span v-if="column.substr(0, 3) == 'btn'"></span>
              <span v-else>
                {{ $t(column) }}
              </span>
            </div>
          </div>
          <div class="div-row" v-for="(item, index) in searchData" :key="index">
            <div
              class="div-cell"
              v-for="column in Search_tableColumns"
              :key="column"
            >
              <span v-if="hasValue(item, column) && column == 'otpissue'">
                <button
                  v-if="itemValue(item, 'time') !== 22200000000000"
                  class="issueOTP"
                  @click="otpIssue(index)"
                >
                  {{ $t("otpissue") }}
                </button>
                <button
                  v-if="itemValue(item, 'time') == 22200000000000"
                  class="unlimitCancel"
                  @click="unlimitCancel(index)"
                >
                  {{ $t("unlimit-cancel") }}
                </button>
              </span>
              <div v-else-if="column == 'status'">
                <span
                  v-if="itemValue(item, 'status') == 'Available'"
                  class="status_notuse"
                >
                  준비중</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'ACTIVE'"
                  class="status_use"
                  >사용중</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'Wait'"
                  class="status_wait"
                  >대기중</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'OTP_ACTIVE'"
                  class="status_use"
                  >사용중</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'UNLIMIT'"
                  class="status_use"
                  >사용중</span
                >
              </div>
              <div v-else-if="column == 'plug_status'">
                <span
                  v-if="itemValue(item, 'plug_status') == 'BROKEN'"
                  class="status_notuse"
                >
                  고장</span
                >
                <span
                  v-else-if="itemValue(item, 'plug_status') == 'ON'"
                  class="status_use"
                >
                  ON</span
                >
                <span v-else-if="itemValue(item, 'plug_status') == 'OFF'">
                  OFF</span
                >
              </div>

              <div v-else-if="hasValue(item, column) && column == 'plug_id'">
                <a
                  class="pointer link"
                  @click="linkDeviceDetail(item.plug_uid)"
                  >{{
                    itemValue(item, column)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                  }}</a
                >
              </div>
              <div v-else-if="hasValue(item, column) && column == 'PlugCharge'">
                {{ itemValue(item, column) | numCommaFilter }}
              </div>
              <div v-else>
                {{ itemValue(item, column) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="btn-cover">
        <ul class="pagination pagination-no-border pagination-">
          <li class="page-item prev-page">
            <button
              aria-label="Previous"
              @click="findPlug(pageNum - 1)"
              v-bind:class="{ 'page-link': true, disabled: pageNum == 1 }"
            >
              <span>&lt;&lt;</span>
            </button>
          </li>
          <li
            class="page-item"
            v-for="(item, index) in findPageCount"
            :key="item"
            :class="{ active: pageNum == index + 1 }"
          >
            <a class="page-link" @click="findPlug(index + 1)">{{
              index + 1
            }}</a>
          </li>
          <li class="page-item page-pre next-page">
            <button
              @click="findPlug(pageNum + 1)"
              v-bind:class="{
                'page-link': true,
                disabled: pageNum >= findPageCount,
              }"
            >
              <span>&gt;&gt;</span>
            </button>
          </li>
        </ul>
        <otp-check-modal
          v-if="showOtpCheckModal"
          @close="showOtpCheckModal = false"
          :serialNumber="serialNumber"
        ></otp-check-modal>
        <otp-issue-modal
          v-if="showOtpIssueModal"
          @close="showOtpIssueModal = false"
          :serialNumber="serialNumber"
          :plug_name="plug_name"
        ></otp-issue-modal>
      </div>
    </div>

    <div v-else-if="tableData.length > 0">
      <div class="pc-table">
        <div class="div-table">
          <div class="div-row">
            <div class="div-cell" v-for="column in tableColumns" :key="column">
              <span v-if="column.substr(0, 3) == 'btn'"></span>
              <span v-else>
                {{ $t(column) }}
              </span>
            </div>
          </div>
          <div class="div-row" v-for="(item, index) in tableData" :key="index">
            <div class="div-cell" v-for="column in tableColumns" :key="column">
              <span v-if="hasValue(item, column) && column == 'otpissue'">
                <button
                  v-if="itemValue(item, 'time') !== 22200000000000"
                  class="issueOTP"
                  @click="otpIssue(index)"
                >
                  {{ $t("otpissue") }}
                </button>
                <button
                  v-if="itemValue(item, 'time') == 22200000000000"
                  class="unlimitCancel"
                  @click="unlimitCancel(index)"
                >
                  {{ $t("unlimit-cancel") }}
                </button>
              </span>
              <div v-else-if="column == 'status'">
                <span
                  v-if="itemValue(item, 'status') == 'Available'"
                  class="status_notuse"
                >
                  준비중</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'ACTIVE'"
                  class="status_use"
                  >사용중</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'Wait'"
                  class="status_wait"
                  >대기중</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'OTP_ACTIVE'"
                  class="status_use"
                  >사용중</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'UNLIMIT'"
                  class="status_use"
                  >사용중</span
                >
              </div>
              <div v-else-if="column == 'plug_status'">
                <span
                  v-if="itemValue(item, 'plug_status') == 'BROKEN'"
                  class="status_notuse"
                >
                  고장</span
                >
                <span
                  v-else-if="itemValue(item, 'plug_status') == 'ON'"
                  class="status_use"
                >
                  ON</span
                >
                <span v-else-if="itemValue(item, 'plug_status') == 'OFF'">
                  OFF</span
                >
              </div>

              <div v-else-if="hasValue(item, column) && column == 'plug_id'">
                <a
                  class="pointer link"
                  @click="linkDeviceDetail(item.plug_uid)"
                  >{{
                    itemValue(item, column)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                  }}</a
                >
              </div>

              <div v-else-if="hasValue(item, column) && column == 'PlugCharge'">
                {{ itemValue(item, column) | numCommaFilter }}
              </div>
              <div v-else>
                {{ itemValue(item, column) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="btn-cover">
        <ul class="pagination pagination-no-border pagination-">
          <li class="page-item prev-page">
            <button
              aria-label="Previous"
              @click="getDeviceList(pageNum - 1)"
              v-bind:class="{ 'page-link': true, disabled: pageNum == 1 }"
            >
              <span>&lt;&lt;</span>
            </button>
          </li>
          <li
            class="page-item"
            v-for="(item, index) in pageCount"
            :key="item"
            :class="{ active: pageNum == index + 1 }"
          >
            <a class="page-link" @click="getDeviceList(index + 1)">{{
              index + 1
            }}</a>
          </li>
          <li class="page-item page-pre next-page">
            <button
              @click="getDeviceList(pageNum + 1)"
              v-bind:class="{
                'page-link': true,
                disabled: pageNum >= pageCount,
              }"
            >
              <span>&gt;&gt;</span>
            </button>
          </li>
        </ul>
        <otp-check-modal
          v-if="showOtpCheckModal"
          @close="showOtpCheckModal = false"
          :serialNumber="serialNumber"
        ></otp-check-modal>
        <otp-issue-modal
          v-if="showOtpIssueModal"
          @close="showOtpIssueModal = false"
          :serialNumber="serialNumber"
          :seedNumber="seedNumber"
          :plug_name="plug_name"
        ></otp-issue-modal>
      </div>
    </div>
    <div v-else>
      <div class="emptyContent">표시할 내역이 없습니다.</div>
    </div>
  </div>
</template>

<script>
// 매안태아불 컬럼
const tableColumns = [
  "siteName",
  "section",
  "plug_id",
  // "plugName",
  "plug_status",
  "PlugDate",
  "PlugCharge",
  "status",
  "otpissue",
];

// 검색 데이터 테이블 컬럼
const Search_tableColumns = [
  "siteName",
  "section",
  "plug_id",
  // "plugName",
  "plug_status",
  "PlugDate",
  "PlugCharge",
  "status",
  "otpissue",
];

import OtpCheckModal from "src/components/Modals/OtpCheckModal.vue";
import OtpIssueModal from "src/components/Modals/OtpIssueModal.vue";

export default {
  name: "device-detail-table",
  components: {
    OtpCheckModal,
    OtpIssueModal,
  },
  data() {
    return {
      // otp 모달창 여부
      showOtpCheckModal: false,
      showOtpIssueModal: false,

      // 페이지 넘버
      pageNum: 0,

      // 테이블컬럼
      tableColumns: tableColumns,
      Search_tableColumns: Search_tableColumns,

      // 테이블데이터
      tableData: [],

      // 토탈 넘버
      totalNum: 0,

      // 시리얼넘버
      serialNumber: "",

      // 시드넘버
      seedNumber: "",

      // 플러그 명
      plug_name: "",

      //
      start: "",

      // 플러그 레코드 id
      plug_record_id: "",

      // 페이지 갯수
      pages: { page: 1, pageSize: 15, status: this.filterStatus },
      //검색에 쓰일 리스트

      // 사이트 리스트
      siteList: [],

      // 섹션리스트
      sectionList: [],

      // 검색 데이터
      searchData: [],

      // 검색 데이터
      search: {
        site: "",
        section: "",
        plugStatus: "all",
        onoff: [],
        useStatus: "all",
        plugName: "",
        searchValue: "",
      },

      // 섹션 데이터
      section: {
        //섹션 리스트 불러올 때 사용
        page: 1,
        pageSize: 9999,
        siteId: "",
      },
    };
  },
  props: {
    listArray: {
      type: Array,
      required: false,
    },
    item: {
      type: Object,
    },
    column: {
      type: String,
    },
    filterStatus: {
      type: String,
    },
  },
  created() {
    //검색창 초기 데이터 셋팅
    this.getSiteList();

    //초기 데이터 셋팅
    this.getDeviceList();
  },
  watch: {
    filterStatus: function () {
      this.getDeviceList();
    },
  },
  computed: {
    pageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
    findPageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column];
    },

    //  디바이스 디테일 데이터로 이동
    linkDeviceDetail(deviceId) {
      // deviceId.stopPropagation();
      alert("상세 페이지로 이동합니다. ");
      this.$router.push("/admin/device/detail/" + deviceId);
    },

    // otp 사용 여부
    otpIssue(index) {
      this.serialNumber = this.tableData[index].plug_id;
      this.seedNumber = this.tableData[index].seed_num;
      this.plug_name = this.tableData[index].plugName;
      if (this.tableData[index].plug_status != "ON") {
        alert(
          "해당 플러그는 고장 또는 OFF상태의 플러그입니다. 다른 플러그를 사용하세요 "
        );
        return false;
      }

      if (this.tableData[index].otpissue === 1) {
        this.showOtpIssueModal = true;
      } else {
        alert(
          '현재 사이트 상태가 "사용 안함" 이기 때문에 OTP 발급이 불가합니다. "사용중"으로 변경하시거나,  "사용 안함 시에도  OTP 발급 가능" 옵션을 설정하시기 바랍니다'
        );
      }
    },

    // 무제한 취소
    async unlimitCancel(index) {
      this.serialNumber = this.tableData[index].plug_id;
      this.start = this.tableData[index].start;
      this.plug_record_id = this.tableData[index].plug_record_id;
      var unlimit_data = {
        plug_id: this.serialNumber,
        start_time: this.start,
        plug_rec_id: this.plug_record_id,
      };
      const response = await this.$store.dispatch(
        "UNLIMIT_CANCEL",
        unlimit_data
      );
      console.log("unlimit-cancel: ", response);
      alert(this.$i18n.t("msg.success.undo"));
    },

    // 플러그 리스트
    async getDeviceList(page) {
      this.pageNum = page ? page : 1;
      this.pages.status = this.filterStatus;
      this.pages.page = this.pageNum;
      const response = await this.$store.dispatch(
        "FETCH_GET_PLUG_LIST",
        this.pages
      );
      console.log(response.data);

      //기기 리스트 불러오기
      console.log("showtotal : ", response.data.data);

      var list = response.data.data.plug_list;
      this.totalNum = response.data.data.total;
      this.tableData = [];
      for (var item of list) {
        var temp = {
          plug_uid: item.plug_uid,
          provider_id: item.provider_id,
          plug_id: item.plug_id,
          plugName: item.name,
          site_id: item.site_id,
          siteName: item.site_name,
          section: item.section_name,
          status: item.status,
          PlugCharge: item.charge < 0 ? "관리자" : item.charge,
          // charge: item.charge = null ? 'null': item.charge,
          PlugDate: item.created.split(" ")[0], //todo 수정필요
          startTime: item.created.split(" ")[1],
          startTime: this.$getDateYYYYMMDD(item.time_from),
          endTime: this.$getDateYYYYMMDD(item.time_to),
          time: item.time_to,
          start: item.time_from,
          otpissue: item.otp == "otp_use" ? 1 : 0,
          plug_status: item.plug_status,
          plug_record_id: item.plug_record_id,
          seed_num: item.seed_num,
        };
        this.tableData.push(temp);
        // console.log(this.tableData[0].endTime)
      }
    },

    // 플러그 검색
    async findPlug(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;
      if (this.search.site === "") {
        this.search.site = "all";
      }
      if (this.search.section === "") {
        this.search.section = "all";
      }
      var data = {};
      data.siteName = this.search.site;
      data.onoff = this.search.onoff;
      data.sectionName = this.search.section;
      data.useStatus = this.search.useStatus;
      data.plugStatus = this.search.plugStatus;
      data.searchValue = this.search.searchValue;
      data.site = this.search.site;
      data.page = this.pages.page;
      data.lines = this.pages.pageSize;

      const response = await this.$store.dispatch("SEARCH_PLUG_LIST", data);
      console.log(response);
      this.searchData = [];
      var list = response.data.data.find_plug_list;
      this.totalNum = response.data.data.total;
      if (list.length === 0) {
        this.searchData = [];
        this.tableData = [];
      } else {
        for (var item of list) {
          var temp = {
            plug_uid: item.plug_uid,
            provider_id: item.provider_id,
            section_id: item.section_id,
            plug_id: item.plug_id,
            plugName: item.name,
            site_id: item.site_id,
            siteName: item.site_name,
            section: item.section_name,
            status: item.status,
            plug_status: item.plug_status,
            PlugCharge: item.charge < 0 ? "관리자" : item.charge,
            PlugDate: item.created.split(" ")[0],
            startTime: item.created.split(" ")[1],
            startTime: this.$getDateYYYYMMDD(item.time_from),
            endTime: this.$getDateYYYYMMDD(item.time_to),
            time: item.time_to,
            start: item.time_from,
            otpissue: item.otp == "otp_use" ? 1 : 0,
            plug_record_id: item.plug_record_id,
          };
          this.searchData.push(temp);
        }
      }
    },

    async getSiteList() {
      //사이트 리스트 불러오기
      const response = await this.$store.dispatch(
        "FETCH_GET_SITE_LIST",
        this.pages
      );

      //데이터 셋팅
      var list = response.data.data.site_list;
      for (var item of list) {
        var temp = {
          id: item.site_id,
          name: item.name,
        };
        this.siteList.push(temp);
      }
    },
    async getSectionList() {
      //값 초기화
      this.sectionList = [];

      //섹션 리스트 불러오기
      this.section.siteId = this.search.site;
      const response = await this.$store.dispatch(
        "FETCH_GET_SECTION_LIST",
        this.section
      );

      //데이터 셋팅
      var list = response.data.data.sections;
      for (var item of list) {
        var temp = {
          id: item.section_id,
          name: item.name,
        };
        this.sectionList.push(temp);
      }
    },
  },
};
</script>

<style scoped>
.pageTitle {
  padding-left: 15px;
  padding-top: 5px;
  font-weight: 400;
}
/*mobile-table css */
.mobile-table .userNumber {
  font-size: 25px;
  font-weight: bold;
  float: left;
  width: 70%;
}

.mobile-table .status {
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 18px;
  right: 60px;
  float: right;
}

/* 검색버튼 */
.searchbtn {
  width: 70px;
  font-weight: 400;
  border-radius: 4px;
  background-color: #73bce6;
  border: 1px solid #4388c1;
  color: white;
  font-size: 13px;
  padding: 0;
  height: 30px;
  margin: -56px 0 0 350px;
}

/* 라디오버튼(플러그 상태, 사용상태) */
.radiobtn {
  margin: 5px 8px 0 15px;
}

/* 플러그 추가 버튼 */
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  text-align: right;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  /* background-color: #e9ecef; */
}
.PlugPlus {
  display: block;
  width: 100px;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  border-radius: 4px;
  background-color: #73bce6;
  border: 1px solid #4388c1;
  color: white;
  font-size: 13px;
  padding: 0;
  padding-left: 15px;
  margin-left: 90%;
  margin-top: -34px;
}

.selbtn {
  margin: 5px 10px 0 15px;
}

.div1 {
  width: 400px;
  position: absolute;
  margin-top: 20px;
}
.div2 {
  padding-top: 80px;
  width: 800px;
  float: left;
}
p {
  width: 100px;
  margin: 0 0 0 20px;
  font-weight: bold;
  position: absolute;
}
/* 사이트명 */
.sub {
  margin: 0 0 0 120px;
}

/* 섹션명 */
.p2 {
  margin: -30px 0 0 400px;
}
.sub2 {
  margin: -31px 0 0 485px;
}

/* 사용상태 */
.sub3 {
  margin: -30px 0 0 120px;
}
.p3 {
  margin: 0 0 0 383px;
}

/*플러그상태 라인  */
.p1 {
  margin: -25px 0 0 20px;
}
.sub1 {
  margin: -30px 0 0 140px;
}
/* 검색 */
.p4 {
  margin: 33px 0 0 20px;
}
.sub4 {
  margin: 30px 0 0 122px;
}
.btn-info.btn-fill:hover,
.btn-info.btn-fill:focus,
.btn-info.btn-fill:active,
.btn-info.btn-fill.active,
.open > .btn-info.btn-fill.dropdown-toggle {
  background-color: #73bce6;
  color: #ffffff;
}
.emptyContent {
  padding-top: 200px;
}
/* 상태선택 시 발생하는 버튼 */
.sub5 {
  width: 250px;
  margin: 10px 0 0 470px;
}
</style>
