<template>
  <div :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import { create } from "domain";

export default {
  data() {
    return {
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      logoutTimer: null,
    };
  },
  // async created(){
  //   console.log(this.$store.state.isLogin)
  //   if(this.$store.state.isLogin === false){
  //     console.log("undefined")
  //   }else{
  //     console.log(this.$store.state.provider_profile.provider_profile.email)
  //     console.log("asd")
  //   }
  // },
  methods: {
    isMobileCheck(event) {
      var dwidth = window.innerWidth;
      if (dwidth <= 768) {
        this.$store.state.isMobile = true;
      } else {
        this.$store.state.isMobile = false;
      }
    },

    /** Session time-out timer function, 4*1000 -> 4 seconds, warn that session is closing. 10*1000 -> 10 seconds,
     * Change Warning message or remove the function and the this.warningTimer.
     * logoutUser -> add logout action into the function to clear the session and change the time from 10 seconds to more.
     * resetTimer-> resets the timer for warningMessage and logoutUser, when the user makes an action or moves the mouse.
     */
    setTimers() {
      if (this.$store.state.isLogin === true) {
        this.warningTimer = setTimeout(
          this.warningMessage,
          1000 * 60 * 60 * 24
        );
        this.logoutTimer = setTimeout(this.logoutUser, 1000 * 60 * 60 * 11);
        // this.logoutTimer = setTimeout(this.logoutUser, 1000 * 30);
      }
    },
    warningMessage() {
      console.log("warning message");
    },
    logoutUser() {
      if (this.$store.state.isLogin === true) {
        //console.log(this.$store.state.isLogin);
        alert("일정시간 입력이 없어 로그아웃 되었습니다.");
        //logout 기능
        this.$store.dispatch("LOGOUT");
        sessionStorage.clear();
        localStorage.clear(); ///clears store state
        window.location.reload();
      }
      // }
    },
    resetTimer() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },
  },
  mounted() {
    // console.log("abc", sessionStorage);
    window.addEventListener("resize", this.isMobileCheck);
    if (this.$store.state.isLogin === true) {
      this.events.forEach(function (event) {
        window.addEventListener(event, this.resetTimer);
      }, this);
    }
  },
  destroyed() {
    if (this.$store.state.isLogin === true) {
      this.events.forEach(function (event) {
        window.removeEventListener(event, this.resetTimer);
      }, this);
      this.resetTimer();
    }
  },
  create() {
    var CheckLogin = false;
    // asds
    var pageCheck = window.location.pathname;
    if (pageCheck === "/login") {
      var CheckLogin = true;
    }

    if (!this.$store.state.isLogin && !CheckLogin) {
      window.location.href = "/login";
    }
  },
};
</script>
<style lang="scss">
.vue-notifyjs.notifications {
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }
  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }

  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
</style>
