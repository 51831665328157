import Vue from "vue";
import VueRouter from "vue-router";
import store from "../vue-store.js";

import ComingSoon from "src/pages/ComingSoon.vue";

import DashboardLayout from "../layout/DashboardLayout.vue";
// GeneralViews
import NotFound from "../pages/NotFoundPage.vue";

// Admin pages
import Overview from "src/pages/Overview.vue";
import UserProfile from "src/pages/UserProfile.vue";
import TableList from "src/pages/TableList.vue";
import Typography from "src/pages/Typography.vue";
import Icons from "src/pages/Icons.vue";
import Maps from "src/pages/Maps.vue";
import Notifications from "src/pages/Notifications.vue";
import Upgrade from "src/pages/Upgrade.vue";

/* 사이트 */
import AddSite from "src/pages/site/AddSite.vue";
import SiteList from "src/pages/site/SiteList.vue";
import SiteDetail from "src/pages/site/SiteDetail.vue";

/* 플러그 */
import AddDevice from "src/pages/device/AddDevice.vue";
import DeviceList from "src/pages/device/DeviceList.vue";
import DeviceDetail from "src/pages/device/DeviceDetail.vue";

/*사용 관리 */
import UsingList from "src/pages/using/UsingList.vue";
import BillingList from "src/pages/using/BillingList.vue";
import TicketList from "src/pages/using/TicketList.vue";

/*정산 관리 */
// import PaidList from "src/pages/paid/PaidList.vue";

/* 사용자 */
import JoinForm from "src/pages/user/JoinForm.vue";
// import UserInfoForm from "src/pages/user/UserInfoForm.vue";
import Login from "src/pages/user/Login.vue";
import FindId from "src/pages/user/FindId.vue";
import FindPswd from "src/pages/user/FindPswd.vue";
import passwordInput from "src/pages/user/passwordInput.vue";
import myInfo from "src/pages/user/myInfo.vue";
import passChange from "src/pages/user/passChange.vue";
import infoChange from "src/pages/user/infoChange.vue";

import manual from "src/pages/user/manual.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/login",
  },
  {
    path: "/soon",
    name: "Soon",
    component: ComingSoon,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "로그인",
    },
  },
  {
    path: "/signup",
    name: "SignUpUser",
    component: JoinForm,
    meta: {
      title: "회원가입",
    },
  },
  {
    path: "/find/id",
    name: "FindId",
    component: FindId,
    meta: {
      title: "ID찾기",
    },
  },
  {
    path: "/find/pswd",
    name: "FindPswd",
    component: FindPswd,
    meta: {
      title: "비밀번호찾기",
    },
  },

  {
    path: "/admin",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
      requiresSession: true,
    },
    children: [
      {
        path: "user/manual",
        name: "manual",
        component: manual,
        meta: {
          title: "매뉴얼",
        },
      },
      {
        path: "overview",
        name: "Overview",
        component: Overview,
        meta: {
          title: "대시보드",
        },
      },
      {
        path: "user/passwordInput",
        name: "passwordInput",
        component: passwordInput,
        meta: {
          title: "내정보확인",
        },
      },
      {
        path: "user/infoChange",
        name: "infoChange",
        component: infoChange,
        meta: {
          title: "회원정보수정",
        },
      },

      {
        path: "user/myInfo",
        name: "myInfo",
        component: myInfo,
        meta: {
          title: "내정보",
        },
      },
      {
        path: "user/passChange",
        name: "passChange",
        component: passChange,
        meta: {
          title: "비밀번호변경",
        },
      },
      {
        path: "site/list",
        name: "siteList",
        component: SiteList,
        meta: {
          title: "사이트 리스트",
        },
      },
      {
        path: "site/detail/:siteId",
        name: "siteDetail",
        component: SiteDetail,
        meta: {
          title: "사이트 상세",
        },
      },
      {
        path: "site/add",
        name: "addSite",
        component: AddSite,
        meta: {
          title: "신규 사이트 등록",
        },
      },
      {
        path: "site/modify/:siteId",
        name: "modifySite",
        component: AddSite,
        meta: {
          title: "사이트 수정",
        },
      },
      {
        path: "device/list",
        name: "deviceList",
        component: DeviceList,
        meta: {
          title: "플러그 리스트",
        },
      },
      {
        path: "device/detail/:deviceId",
        name: "deviceDetail",
        component: DeviceDetail,
        meta: {
          title: "플러그 상세",
        },
      },
      {
        path: "device/add",
        name: "addDevice",
        component: AddDevice,
        meta: {
          title: "신규 플러그 등록",
        },
      },
      {
        path: "device/modify/:deviceId",
        name: "modifyDevice",
        component: AddDevice,
        meta: {
          title: "플러그 수정",
        },
      },
      {
        path: "using/list",
        name: "usingList",
        component: UsingList,
        meta: {
          title: "사용 내역",
        },
      },
      {
        path: "billing/list",
        name: "billingList",
        component: BillingList,
        meta: {
          title: "정산 내역",
        },
      },
      {
        path: "ticket/list",
        name: "ticketList",
        component: TicketList,
        meta: {
          title: "정기권 구매 내역",
        },
      },
      // {
      //   path: 'user/signup',
      //   name: 'SignUpUser',
      //   component: UserInfoForm
      // },
      // {
      //   path: 'user/login',
      //   name: 'Login',
      //   component: Login
      // },
      {
        path: "user",
        name: "User",
        component: UserProfile,
      },
      {
        path: "table-list",
        name: "Table List",
        component: TableList,
      },
      {
        path: "typography",
        name: "Typography",
        component: Typography,
      },
      {
        path: "icons",
        name: "Icons",
        component: Icons,
      },
      {
        path: "maps",
        name: "Maps",
        component: Maps,
      },
      {
        path: "notifications",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: "upgrade",
        name: "Upgrade to PRO",
        component: Upgrade,
      },
    ],
  },
  { path: "*", component: NotFound },
];

const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "nav-item active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (sessionStorage.getItem("token")) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }

  // const reqSession = to.matched.some(route => route.meta.reqiresSession)
  // if(!reqSession) next()

  // if(router.app.$session.exists()){
  //   next()
  // }else{
  //   next({name: 'Login'})
  //   alert("세션이 완료되었습니다.")
  // }
});

const DEFAULT_TITLE = "쉐어플러그";
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? DEFAULT_TITLE + "_" + to.meta.title
      : DEFAULT_TITLE;
  });
});

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default router;
