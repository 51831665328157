//vue-store.js
import { _ } from "core-js";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import {
  request_signup_code,
  verify_signup_code,
  login_provider,
  get_provider_detail,
  check_provider_email,
  check_provider_serial_num,
  create_provider,
  find_provider,
  get_plug_record_list,
  get_plug_record_list_money,
  get_ticket_list,
  get_plug_list,
  update_get_plug_list,
  get_account_record_list,
  get_account_cancel,
  get_site_list,
  delete_site,
  get_plug_detail,
  get_section_list,
  create_plug,
  create_site,
  get_site_detail,
  get_plug_site_list,
  update_site,
  get_plug_record_detail,
  comp_password,
  update_provider,
  make_otp,
  send_otp,
  create_account,
  unlimit_cancel,
  check_plug_id,
  update_account,
  search_using,
  search_ticket,
  search_plug_list,
  search_billing,
  request_password_change,
  reset_password,
  logout,
  update_section,
} from "src/Api/index.js";
// import { get } from 'core-js/core/dict';

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    isMobile: false,
    country: "KOR", //접속 국가, 현재는 KOR로 고정
    section: {
      max_active_plug: 99, //섹션 등록 시 플러그 최대 활성 가능 수
    },
    token: sessionStorage.getItem("token") || "", //로그인 여부 판별 토큰
    user: {
      //로그인한 사용자 정보
      user_id: "",
      user_email: "",
    },
    signup: false,
    // 회원가입 성공 실패 여부
    isLogin: false,
    // 로그인 여부
    provider_profile: "",
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, user) {
      state.status = "success";
      state.token = user.token;
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.user = "";
      state.isLogin = false;
    },
    SIGNUP(state, signup) {
      console.log(signup);
      // if (signup.data === null) {
      state.signup = !state.signup;
      // }
      console.log("this.$store.state.signup: ", state.signup);
    },
    IS_LOGIN(state, LOGIN_PROVIDER) {
      state.isLogin = LOGIN_PROVIDER.data.result;
      state.isLogin
        ? console.log("로그인 성공")
        : console.log(state.isLogin, "로그인 실패");
    },
    PROVIDER_PROFILE(state, profile) {
      state.provider_profile = profile;
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    getLoginUser: (state) => state.user,
  },
  actions: {
    async FETCH_REQUEST_SIGNUP_CODE({ commit }, mobile_num) {
      console.log("actions mobile_num: ", mobile_num);
      commit;
      const response = await request_signup_code(mobile_num);
      console.log("actions response: ", response);
      return response;
    },
    async FETCH_VERIFY_SIGNUP_CODE({ commit }, verify) {
      console.log("actions verify: ", verify);
      commit;
      const response = await verify_signup_code(verify);
      console.log("actions response: ", response);
      return response;
    },
    async LOGIN_PROVIDER({ commit }, provider) {
      const response = await login_provider(provider);
      console.log("action response: ", response);
      commit("IS_LOGIN", response);
      return response;
    },
    async GET_PROVIDER_DETAIL({ commit }) {
      const response = await get_provider_detail();
      commit("PROVIDER_PROFILE", response.data.data);
      return response;
    },
    async FETCH_CHECK_PROVIDER_EMAIL({ commit }, email) {
      console.log("actions email: ", email);
      commit;
      const response = await check_provider_email(email);
      console.log("actions response: ", response);
      return response;
    },
    async FETCH_CHECK_PROVIDER_SERIAL_NUM({ commit }, serial_num) {
      console.log("actions serial_num: ", serial_num);
      commit;
      const response = await check_provider_serial_num(serial_num);
      console.log("actions response: ", response);
      return response;
    },
    async FETCH_CREATE_PROVIDER({ commit }, memberInf) {
      console.log("action memberInf: ", memberInf);
      const response = await create_provider(memberInf);
      commit("SIGNUP", response);
      console.log("action response: ", response);
      return response;
    },
    async FETCH_CREATE_ACCOUNT({ commit }, accountnum) {
      console.log("action accountnum: ", accountnum);
      const response = await create_account(accountnum);
      commit;
      console.log("action response: ", response);
      return response;
    },
    async FETCH_UPDATE_ACCOUNT({ commit }, accountnum) {
      console.log("action accountnum: ", accountnum);
      const response = await update_account(accountnum);
      commit;
      console.log("action response: ", response);
      return response;
    },
    async FETCH_FIND_PROVIDER({ commit }, check) {
      const response = await find_provider(check);
      commit;
      console.log("response:", response);
      return response;
    },
    async FETCH_GET_TICKET_LIST({ commit }, pages) {
      const response = await get_ticket_list(pages);
      commit;
      return response;
    },
    async FETCH_GET_PLUG_RECORD_LIST({ commit }, pages) {
      const response = await get_plug_record_list(pages);
      commit;
      console.log("response: ", response);
      return response;
    },
    async FETCH_GET_PLUG_RECORD_LIST_MONEY({ commit }, pages) {
      const response = await get_plug_record_list_money(pages);
      commit;
      console.log("response: ", response);
      return response;
    },
    async FETCH_GET_ACCOUNT_RECORD_LIST({ commit }, pages) {
      const response = await get_account_record_list(pages);
      commit;
      console.log("response: ", response);
      return response;
    },
    async FETCH_GET_ACCOUNT_CANCEL({ commit }, data) {
      const response = await get_account_cancel(data);
      commit;
      console.log("response: ", response);
      return response;
    },
    async FETCH_GET_PLUG_RECORD_DETAIL({ commit }, pages) {
      const response = await get_plug_record_detail(pages);
      commit;
      return response;
    },
    async FETCH_GET_PLUG_LIST({ commit }, pages) {
      const response = await get_plug_list(pages);
      commit;
      return response;
    },
    async UPDATE_GET_PLUG_LIST({ commit }, pages) {
      const response = await update_get_plug_list(pages);
      commit;
      return response;
    },

    async FETCH_GET_PLUG_SITE_LIST({ commit }, pages) {
      const response = await get_plug_site_list(pages);
      commit;
      console.log("response:", response);
      return response;
    },
    async FETCH_GET_SITE_LIST({ commit }, pages) {
      const response = await get_site_list(pages);
      commit;
      return response;
    },
    async FETCH_DELETE_SITE({ commit }, siteId) {
      const response = await delete_site(siteId);
      commit;
      return response;
    },
    async FETCH_GET_PLUG_DETAIL({ commit }, deviceId) {
      const response = await get_plug_detail(deviceId);
      commit;
      return response;
    },
    async FETCH_GET_SECTION_LIST({ commit }, section) {
      const response = await get_section_list(section);
      commit;
      return response;
    },
    async FETCH_CREATE_PLUG({ commit }, plug) {
      const response = await create_plug(plug);
      commit;
      return response;
    },
    async FETCH_CREATE_SITE({ commit }, site) {
      const response = await create_site(site);
      commit;
      return response;
    },
    async FETCH_GET_SITE_DETAIL({ commit }, siteId) {
      const response = await get_site_detail(siteId);
      commit;
      return response;
    },
    async FETCH_UPDATE_SITE({ commit }, site) {
      const response = await update_site(site);
      commit;
      return response;
    },
    async COMPARE_PASSWORD({ commit }, password) {
      const response = await comp_password(password);
      commit;
      return response;
    },
    async UPDATE_PROVIDER({ commit }, modifying) {
      commit;
      const response = await update_provider(modifying);
      return response;
    },
    async FETCH_SEND_OTP({ commit }, otpdata) {
      const response = await send_otp(
        otpdata.mobile,
        otpdata.otp,
        otpdata.plug_id,
        otpdata.otp_time,
        otpdata.plug_name
      );
      commit;
      return response;
    },
    async FETCH_MAKE_OTP({ commit }, data) {
      const response = await make_otp(
        data.otp_time,
        data.pay_code,
        data.seed_num
      );
      commit;
      return response;
    },
    async UNLIMIT_CANCEL({ commit }, unlimit_data) {
      const response = await unlimit_cancel(
        unlimit_data.plug_id,
        unlimit_data.start_time,
        unlimit_data.plug_rec_id
      );
      commit;
      return response;
    },
    async CHECK_PLUG_ID({ commit }, plug_id) {
      const response = await check_plug_id(plug_id);
      commit;
      return response;
    },
    async SEARCH_USING({ commit }, data) {
      const response = await search_using(data);
      commit;
      return response;
    },
    async SEARCH_TICKET({ commit }, data) {
      const response = await search_ticket(data);
      commit;
      return response;
    },
    async SEARCH_BILLING({ commit }, data) {
      const response = await search_billing(data);
      commit;
      return response;
    },
    async SEARCH_PLUG_LIST({ commit }, data) {
      const response = await search_plug_list(data);
      commit;
      return response;
    },
    async REQUEST_PASSWORD_CHANGE({ commit }, user) {
      const response = await request_password_change(
        user.id,
        user.name,
        user.phone
      );
      commit;
      return response;
    },
    async RESET_PASSWORD({ commit }, password) {
      const response = await reset_password(password);
      commit;
      return response;
    },
    async LOGOUT({ commit }) {
      const response = await logout();
      commit;
      sessionStorage.clear();
      store.state.isLogin = false;
      //console.log(store.state.isLogin);
      // alert("로그아웃 되었습니다.");
      return response;
    },
    async UPDATE_SECTION({ commit }, data) {
      const response = await update_section(data);
      commit;
      return response;
    },
  },
});

export default store;
