<template>
  <component :is="tag" @click.native="hideSidebar" v-bind="$attrs" tag="li">
    <!-- v-on: 클릭 시 hideSidebar 작동 
$attrs: props로 인식(및 추출)되지 않는 부모 범위 속성 바인딩입니다. 
컴포넌트에 선언된 props가 없을 때 class와 style을 제외하고 
모든 부모 범위 바인딩을 기본적으로 포함하며 v-bind="$attrs"를 통해 
내부 컴포넌트로 전달할 수 있습니다 - 하이 오더 컴포넌트(HOC)를 작성할 때 유용합니다. -->
    <a class="nav-link" v-bind="$attrs">
      <slot>
        <i v-if="link.icon" :class="link.icon"></i>
        <p>{{ link.name }}</p>
      </slot>
    </a>
  </component>
</template>
<script>
export default {
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: true,
    },
  },
  props: {
    link: {
      type: [String, Object],
      default: () => {
        return {
          name: "",
          path: "",
          icon: "",
        };
      },
    },
    tag: {
      type: String,
      default: "router-link",
    },
  },
  methods: {
    hideSidebar() {
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style></style>
