<template>
  <div class="content">
    <div class="row">
      <base-dropdown
        tag="div"
        class="small"
        style="position: absolute; right: 0px"
        allow="left"
      >
        <template slot="title"> </template>
      </base-dropdown>
    </div>

    <div class="row">
      <div class="col-12">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <device-table
            class="table-hover table-striped"
            :filterStatus="filterStatus"
          >
          </device-table>
        </card>
      </div>
    </div>
    <!-- //row -->
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import DeviceTable from "src/components/Table/DeviceTable.vue";

// import EditProfileForm from './UserProfile/EditProfileForm.vue'
// import UserCard from './UserProfile/UserCard.vue'

export default {
  components: {
    Card,
    DeviceTable,
  },
  data() {
    return {
      filterStatus: "all",
    };
  },
  methods: {},
};
</script>
<style scoped>
.icon-img20 {
  width: 20px;
  height: 20px;
}

.nav-link {
  display: inline;
  line-height: 25px;
}

/* 플러그 리스트 페이지 css */

.container-fluid {
  padding: 15px;
}

.container-fluid .row .card {
  height: 80%;
}
</style>
