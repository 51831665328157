<template>
  <div>
    <slot name="title">
      <h1></h1>
    </slot>

    <table class="table">
      <thead>
        <slot name="columns">
          <tr>
            <th v-for="column in columns" :key="column">
              <span
                v-if="except.indexOf(column.substr(0, 3).toLowerCase()) < 0"
                >{{ $t(column) }}</span
              >
            </th>
          </tr>
        </slot>
      </thead>
      <tbody>
        <slot
          name="rows"
          :paginatedData="paginatedData"
          :hasValue="hasValue"
          :itemValue="itemValue"
        >
          <tr v-for="(item, index) in paginatedData" :key="index">
            <td v-for="column in columns" :key="column">
              <span v-if="hasValue(item, column)">
                <span v-if="column.substr(0, 3).toLowerCase() == 'btn'">
                  <slot name="btn_{{test}}"> test1 </slot>
                  <!--  slot에 변수 들어갈 수 있는지 찾아보기 -->
                </span>
                <span v-else>
                  {{ itemValue(item, column) }}
                </span>
              </span>
            </td>
          </tr>
        </slot>
      </tbody>
    </table>

    <div class="btn-cover">
      <ul class="pagination pagination-no-border pagination-">
        <li class="page-item prev-page">
          <button
            aria-label="Previous"
            class="page-link"
            :disabled="pageNum === 0"
            @click="prevPage"
          >
            <span>&lt;&lt;</span>
          </button>
        </li>
        <li
          class="page-item"
          v-for="(item, index) in pageCount"
          :key="item"
          :class="{ active: pageNum == index }"
        >
          <a class="page-link" @click="pageNum = index">{{ index + 1 }}</a>
        </li>
        <li class="page-item page-pre next-page">
          <button
            :disabled="pageNum >= pageCount - 1"
            @click="nextPage"
            class="page-link"
          >
            <span>&gt;&gt;</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "paginated-list",
  data() {
    return {
      pageNum: 0,
      except: ["btn"],
      test: "te"
    };
  },
  props: {
    listArray: {
      type: Array,
      required: false
    },
    pageSize: {
      type: Number,
      required: false,
      default: 10
    },
    columns: {
      type: Array,
      required: false,
      default: []
    },
    data: {
      type: Array,
      required: false,
      default: []
    },
    item: {
      type: Object
    },
    column: {
      type: String
    }
  },
  methods: {
    nextPage() {
      this.pageNum += 1;
    },
    prevPage() {
      this.pageNum -= 1;
    },
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    showAlert() {
      console.log("자식 showAlert");
    }
  },
  computed: {
    pageCount() {
      let listLeng = this.data.length,
        listSize = this.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;

      return page;
    },
    paginatedData() {
      const start = this.pageNum * this.pageSize,
        end = start + this.pageSize;
      return this.data.slice(start, end);
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
table th {
  font-size: 1.2rem;
}
/* table tr {
        height: 2rem;
        text-align: center;
        border-bottom: 1px solid #505050;
    }
    table tr:first-of-type {
        border-top: 2px solid #404040;
    } */
table tr td {
  padding: 1rem 0;
  font-size: 1.1rem;
}
.btn-cover {
  margin-top: 1.5rem;
  text-align: center;
  display: flex;
}
.btn-cover .page-btn {
  width: 5rem;
  height: 2rem;
  letter-spacing: 0.5px;
}
.btn-cover .page-count {
  padding: 0 1rem;
}
.btn-cover .pagination {
  margin: 0 auto;
}
</style>
