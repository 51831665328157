<template>
  <div>
    <!-- 검색 영역 -->
    <div class="div1">
      <p class="p1">정산 생성 일자</p>
      <div>
        <input
          type="date"
          id="datefield"
          v-model="search.sDate"
          min="2021-01-01"
          max="2050-13-13"
          onfocus="this.max=new Date().toISOString().split('T')[0]"
          class="datepic1"
        />
      </div>
      <div>
        <input
          type="date"
          id="datefield"
          v-model="search.eDate"
          min="2021-01-01"
          max="2050-13-13"
          onfocus="this.max=new Date().toISOString().split('T')[0]"
          class="datepic2"
        />
      </div>
    </div>

    <div class="div2">
      <p class="p3">사이트명</p>
      <div class="col-sm-2">
        <base-select-search
          type="text"
          class="text-center selectInput sub"
          :items="siteList"
          title="사이트명"
          v-model="search.site"
        ></base-select-search>
      </div>
      <button
        type="submit"
        id="searchBtn"
        class="btn btn-info btn-fill float-right col-4 seabtn"
        @click="searchBilling()"
      >
        {{ $t("search") }}
      </button>
    </div>
    <!-- 검색 영역 END-->
    <div v-if="searchUseList.length > 0">
      <div class="pc-table">
        <div class="div-table">
          <div class="div-row">
            <div class="div-cell" v-for="column in tableColumns" :key="column">
              <span
                v-if="
                  column.substr(0, 3) == 'btn' || column.substr(0, 3) == 'adm'
                "
              ></span>
              <span v-else>
                {{ $t(column) }}
              </span>
            </div>
          </div>
          <div
            class="div-row"
            v-for="(item, index) in searchUseList"
            :key="index"
          >
            <div class="div-cell" v-for="column in tableColumns" :key="column">
              <div v-if="hasValue(item, column) && column == 'billInfo'">
                <div id="User_List" data-app>
                  <v-dialog
                    class="m_dialog"
                    v-model="dialog"
                    persistent
                    max-width="85%"
                    :retain-focus="false"
                  >
                    <!-- retain-focus: false => prevents overloading of a stack -->
                    <template v-slot:activator="{ on, attrs }">
                      <button
                        v-bind="attrs"
                        v-on="on"
                        @click="getSearchDetail(index)"
                        style="red"
                        class="uselog_btn"
                      >
                        내역 보기
                      </button>
                    </template>

                    <!-- 모달창 텍스트내용 -->
                    <v-card class="modal-wrapper">
                      <v-card-title> 전체 사용 내역 </v-card-title>

                      <!-- 모달창 테이블 -->
                      <!--  -->
                      <div v-if="popup_tableData.length > 0">
                        <!-- <div class="scroll"> -->
                        <div class="div-table">
                          <div class="div-row">
                            <div
                              class="div-cell"
                              v-for="column in popup_tableColumns"
                              :key="column.id"
                              type="text"
                            >
                              <span>
                                {{ column.msg }}
                              </span>
                            </div>
                          </div>
                          <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                          <div
                            class="div-row"
                            v-for="(item, index) in popup_tableData"
                            :key="index.id"
                          >
                            <div
                              class="div-cell"
                              v-for="column in popup_tableColumns"
                              :key="column.id"
                              type="text"
                            >
                              <div
                                v-if="
                                  column.id == 'minute' || column.id == 'charge'
                                "
                              >
                                <span>
                                  {{
                                    itemValue(item, column.id) | numCommaFilter
                                  }}</span
                                >
                              </div>
                              <div v-else>
                                {{ itemValue(item, column.id) }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- </div> -->
                        <!-- //미정산 -->
                        <div class="mijung">미정산</div>
                        <!-- <div class="scroll"> -->

                        <div class="div-table">
                          <div class="div-row">
                            <div
                              class="div-cell"
                              v-for="(column, index) in mijung_tableColumns"
                              :key="index"
                            >
                              <span>
                                {{ column.msg }}
                              </span>
                            </div>
                          </div>
                          <div
                            class="div-row"
                            v-for="(item, index) in mijung_tableData"
                            :key="index"
                          >
                            <div
                              class="div-cell"
                              v-for="(column, index) in mijung_tableColumns"
                              :key="index"
                            >
                              <div
                                v-if="
                                  column.id == 'minute' || column.id == 'charge'
                                "
                              >
                                <span>
                                  {{
                                    itemValue(item, column.id) | numCommaFilter
                                  }}</span
                                >
                              </div>
                              <div v-else>
                                {{ itemValue(item, column.id) }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- </div> -->
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="btn btn-info btn-fill float-right close"
                          style="color: white"
                          text
                          @click="reset()"
                        >
                          닫기
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- /// 여기까지 -->
                </div>
              </div>
              <div
                v-if="
                  hasValue(item, column) &&
                  (column == 'totalPaid' || column == 'totalPaid10')
                "
              >
                {{ itemValue(item, column) | numCommaFilter }}
              </div>
              <div v-else>
                {{ itemValue(item, column) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-cover">
        <ul class="pagination pagination-no-border">
          <li class="page-item prev-page">
            <button
              aria-label="Previous"
              @click="searchBilling(pageNum - 1)"
              v-bind:class="{ 'page-link': true, disabled: pageNum <= 1 }"
            >
              <span>&lt;&lt;</span>
            </button>
          </li>
          <li
            class="page-item"
            v-for="(item, index) in findPageCount"
            :key="item"
            :class="{ active: pageNum == index + 1 }"
          >
            <a class="page-link" @click="searchBilling(index + 1)">{{
              index + 1
            }}</a>
          </li>
          <li class="page-item page-pre next-page">
            <button
              @click="searchBilling(pageNum + 1)"
              v-bind:class="{
                'page-link': true,
                disabled: pageNum >= findPageCount,
              }"
            >
              <span>&gt;&gt;</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div v-else-if="useList.length > 0">
      <div class="pc-table">
        <div class="div-table">
          <div class="div-row">
            <div class="div-cell" v-for="column in tableColumns" :key="column">
              <span>
                {{ $t(column) }}
              </span>
            </div>
          </div>
          <div class="div-row" v-for="(item, index) in useList" :key="index">
            <div class="div-cell" v-for="column in tableColumns" :key="column">
              <div v-if="hasValue(item, column) && column == 'billInfo'">
                <div id="User_List" data-app>
                  <v-dialog
                    class="m_dialog"
                    v-model="dialog"
                    persistent
                    max-width="85%"
                    :retain-focus="false"
                  >
                    <!-- retain-focus: false => prevents overloading of a stack -->
                    <template v-slot:activator="{ on, attrs }">
                      <button
                        v-bind="attrs"
                        v-on="on"
                        @click="getDetail(index)"
                        style="red"
                        class="uselog_btn"
                      >
                        내역 보기
                      </button>
                    </template>

                    <!-- 모달창 텍스트내용 -->
                    <v-card>
                      <v-card-title> 전체 사용 내역 </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="btn btn-info btn-fill float-right close"
                          style="color: white"
                          text
                          @click="reset()"
                        >
                          닫기
                        </v-btn>
                      </v-card-actions>

                      <!-- 모달창 테이블 -->
                      <!--  -->
                      <div v-if="popup_tableData.length > 0">
                        <div class="div-table">
                          <div class="div-row">
                            <div
                              class="div-cell"
                              v-for="column in popup_tableColumns"
                              :key="column.id"
                              type="text"
                            >
                              <span>
                                {{ column.msg }}
                              </span>
                            </div>
                          </div>
                          <div
                            class="div-row"
                            v-for="(item, index) in popup_tableData"
                            :key="index.id"
                          >
                            <div
                              class="div-cell"
                              v-for="column in popup_tableColumns"
                              :key="column.id"
                              type="text"
                            >
                              <div
                                v-if="
                                  column.id == 'minute' || column.id == 'charge'
                                "
                              >
                                <span>
                                  {{
                                    itemValue(item, column.id) | numCommaFilter
                                  }}</span
                                >
                              </div>
                              <div v-else>
                                {{ itemValue(item, column.id) }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- //미정산 -->
                        <div class="mijung">미정산</div>
                        <!-- <div class="scroll"> -->

                        <div class="div-table">
                          <div class="div-row">
                            <div
                              class="div-cell"
                              v-for="(column, index) in mijung_tableColumns"
                              :key="index"
                            >
                              <span>
                                {{ column.msg }}
                              </span>
                            </div>
                          </div>
                          <div
                            class="div-row"
                            v-for="(item, index) in mijung_tableData"
                            :key="index"
                          >
                            <div
                              class="div-cell"
                              v-for="(column, index) in mijung_tableColumns"
                              :key="index"
                            >
                              <div
                                v-if="
                                  column.id == 'minute' || column.id == 'charge'
                                "
                              >
                                <span>
                                  {{
                                    itemValue(item, column.id) | numCommaFilter
                                  }}</span
                                >
                              </div>
                              <div v-else>
                                {{ itemValue(item, column.id) }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- </div> -->
                      </div>
                      <div v-else>
                        <div class="emptyContent">표시할 내역이 없습니다.</div>
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="btn btn-info btn-fill float-right close"
                          style="color: white"
                          text
                          @click="reset()"
                        >
                          닫기
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <!-- /// 여기까지 -->
                </div>
              </div>

              <div
                v-if="
                  hasValue(item, column) &&
                  (column == 'totalPaid' || column == 'totalPaid10')
                "
              >
                {{ itemValue(item, column) | numCommaFilter }}
              </div>
              <div v-else>
                {{ itemValue(item, column) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-cover">
        <ul class="pagination pagination-no-border">
          <li class="page-item prev-page">
            <button
              aria-label="Previous"
              @click="getList(pageNum - 1)"
              v-bind:class="{ 'page-link': true, disabled: pageNum <= 1 }"
            >
              <span>&lt;&lt;</span>
            </button>
          </li>
          <li
            class="page-item"
            v-for="(item, index) in pageCount"
            :key="item"
            :class="{ active: pageNum == index + 1 }"
          >
            <a class="page-link" @click="getList(index + 1)">{{ index + 1 }}</a>
          </li>
          <li class="page-item page-pre next-page">
            <button
              @click="getList(pageNum + 1)"
              v-bind:class="{
                'page-link': true,
                disabled: pageNum >= pageCount,
              }"
            >
              <span>&gt;&gt;</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <div class="emptyContent">표시할 내역이 없습니다.</div>
    </div>
  </div>
</template>

<script>
const tableColumns = [
  "siteName",
  "totalPaid",
  "totalPaid10",
  "billCreated",
  "billDone",
  "billFinishedDate",
  "billInfo",
];

import "vue2-datepicker/index.css";
import dayjs from "dayjs";

export default {
  name: "normal-table",
  data() {
    return {
      i: 15,
      pages: { page: 1, pageSize: 15 },
      paginatedDate: [],
      maxLine: 30,
      moreLine: 15,
      listSize: 15,
      useList: [],
      searchUseList: [],
      pageArray: [],
      pageNum: 0,
      pageSize: 15,
      tableColumns: tableColumns,
      totalNum: 0,
      showOtpCheckModal: false,
      showOtpIssueModal: false,
      serialNumber: "",
      lastMobile: "",
      phoneNum: "",
      start: "",
      plug_record_id: "",
      usedTime: "",
      //검색에 쓰일 리스트
      searchDatePropList: [
        { id: "startDate", name: "시작일" },
        { id: "endDate", name: "종료일" },
      ],
      siteList: [],
      dialog: false,
      search: {
        sDate: dayjs().subtract(1, "year").add(1, "day").format("YYYY-MM-DD"),
        eDate: new Date().toISOString().slice(0, 10),
        site: "",
        searchText: "",
      },
      popup_tableData: [],
      popup_tableColumns: [
        // 모달창 테이블 컬럼
        { id: "num", msg: "NO" },
        { id: "plug_id", msg: "플러그 ID" },
        // { id: "plug_name", msg: "플러그 명" },
        { id: "mob_phone", msg: "시용자 핸드폰" },
        { id: "time_from", msg: "시작 시간" },
        { id: "time_to", msg: "종료 시간" },
        { id: "minute", msg: "선택 상품" },
        { id: "charge", msg: "결제 금액" },
      ],
      bill_detail_ret: {
        num: "",
        plug_id: "",
        plug_name: "",
        mob_phone: "",
        time_from: "",
        time_to: "",
        minute: "",
        charge: "",
      },
      missed_plug_ret: "",
      mijung_tableColumns: [
        { id: "num", msg: "NO" },
        { id: "plug_id", msg: "플러그 ID" },
        // { id: "plug_name", msg: "플러그 명" },
        { id: "mob_phone", msg: "사용자 핸드폰" },
        { id: "time_from", msg: "시작 시간" },
        { id: "time_to", msg: "종료 시간" },
        { id: "minute", msg: "선택 상품" },
        { id: "charge", msg: "결제 금액" },
        { id: "use_comment", msg: "미반영 이유" },
      ],
      mijung_tableData: [],
    };
  },
  props: {
    listArray: {
      type: Array,
      required: false,
    },
    item: {
      type: Object,
    },
    column: {
      type: String,
    },
    filterStatus: {
      type: String,
    },
  },
  created() {
    //초기 데이터 셋팅
    this.getList();

    this.getSiteList();
  },
  watch: {
    filterStatus: function () {
      this.getList(1);
    },
  },
  computed: {
    pageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
    findPageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
  },
  methods: {
    getSearchDetail(index) {
      this.popup_tableData = [];
      this.bill_detail_ret = this.searchUseList[index].bill_detail_ret;
      for (var i = 0; i < this.bill_detail_ret.length; i++) {
        this.popup_tableData.push({
          num: this.bill_detail_ret.length - i,
          plug_id: this.bill_detail_ret[i].plug_id,
          plug_name: this.bill_detail_ret[i].plug_name,
          mob_phone: this.bill_detail_ret[i].mobile_number,
          time_from: this.$getDateYYYYMMDD(this.bill_detail_ret[i].time_from),
          time_to: this.$getDateYYYYMMDD(this.bill_detail_ret[i].time_to),
          minute:
            this.bill_detail_ret[i].minute === 18000
              ? "정기권"
              : this.bill_detail_ret[i].minute,
          charge: this.bill_detail_ret[i].charge,
        });
      }
      this.mijung_tableData = [];
      this.missed_plug_ret = this.searchUseList[index].missed_plug_ret;
      for (var i = 0; i < this.missed_plug_ret.length; i++) {
        this.mijung_tableData.push({
          num: this.missed_plug_ret.length - i,
          plug_id: this.missed_plug_ret[i].plug_id,
          plug_name: this.missed_plug_ret[i].plug_name,
          mob_phone: this.missed_plug_ret[i].mobile_number,
          time_from: this.$getDateYYYYMMDD(this.missed_plug_ret[i].time_from),
          time_to: this.$getDateYYYYMMDD(this.missed_plug_ret[i].time_to),
          minute:
            this.missed_plug_ret[i].minute === 18000
              ? "정기권"
              : this.missed_plug_ret[i].minute,
          charge:
            this.missed_plug_ret[i].bill_status === "1"
              ? "-" + this.missed_plug_ret[i].charge
              : this.missed_plug_ret[i].charge,
          use_comment: this.missed_plug_ret[i].bill_comment,
        });
      }
    },
    getDetail(index) {
      this.popup_tableData = [];
      this.bill_detail_ret = this.useList[index].bill_detail_ret;
      for (var i = 0; i < this.bill_detail_ret.length; i++) {
        this.popup_tableData.push({
          num: this.bill_detail_ret.length - i,
          plug_id: this.bill_detail_ret[i].plug_id,
          plug_name: this.bill_detail_ret[i].plug_name,
          mob_phone: this.bill_detail_ret[i].mobile_number,
          time_from: this.$getDateYYYYMMDD(this.bill_detail_ret[i].time_from),
          time_to: this.$getDateYYYYMMDD(this.bill_detail_ret[i].time_to),
          minute:
            this.bill_detail_ret[i].minute === 18000
              ? "정기권"
              : this.bill_detail_ret[i].minute,
          charge: this.bill_detail_ret[i].charge,
        });
      }
      this.mijung_tableData = [];
      this.missed_plug_ret = this.useList[index].missed_plug_ret;
      for (var i = 0; i < this.missed_plug_ret.length; i++) {
        this.mijung_tableData.push({
          num: this.missed_plug_ret.length - i,
          plug_id: this.missed_plug_ret[i].plug_id,
          plug_name: this.missed_plug_ret[i].plug_name,
          mob_phone: this.missed_plug_ret[i].mobile_number,
          time_from: this.$getDateYYYYMMDD(this.missed_plug_ret[i].time_from),
          time_to: this.$getDateYYYYMMDD(this.missed_plug_ret[i].time_to),
          minute:
            this.missed_plug_ret[i].minute === 18000
              ? "정기권"
              : this.missed_plug_ret[i].minute,
          charge:
            this.missed_plug_ret[i].bill_status === "1"
              ? "-" + this.missed_plug_ret[i].charge
              : this.missed_plug_ret[i].charge,
          use_comment: this.missed_plug_ret[i].bill_comment,
        });
      }
    },
    async getSiteList() {
      //사이트 리스트 불러오기
      const response = await this.$store.dispatch(
        "FETCH_GET_SITE_LIST",
        this.pages
      );

      //데이터 셋팅
      var list = response.data.data.site_list;
      for (var item of list) {
        var temp = {
          id: item.site_id,
          name: item.name,
        };
        this.siteList.push(temp);
      }
      console.log(response);
    },
    reset() {
      this.dialog = false;
    },
    enterkey() {
      if (window.event.keyCode == 13) {
        this.searchBilling();
      }
    },
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column];
    },
    async getList(page) {
      // 검색 조건 유효성 체크

      var valid = this.checkSearchValid();
      if (!valid) {
        return;
      }

      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;
      const response = await this.$store.dispatch(
        "FETCH_GET_PLUG_RECORD_LIST_MONEY",
        this.pages
      );

      this.useList = [];
      var list = response.data.data.bill_plug_record_list;
      this.totalNum = response.data.data.total;
      for (var item of list) {
        var temp = {
          site_id: item.site_id,
          siteName: item.site_name,
          totalPaid: item.total,
          totalPaid10: Math.round(item.total / 1.1),
          billCreated: item.next_sett,
          billDone: item.created === "0" ? "처리 전" : "처리 완료",
          billFinishedDate: item.created === "0" ? "" : item.created,
          bill_detail_ret: item.used_plug_ret,
          missed_plug_ret: item.missed_plug_ret,
        };
        this.useList.push(temp);
      }
    },
    async searchBilling(page) {
      //시작일, 종료일 유효성 체크

      if (this.search.site === "") {
        this.search.site = "all";
      }
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;

      var data = {};
      data.dateFrom = this.search.sDate;
      data.dateTo = this.search.eDate;
      data.site = this.search.site;
      data.page = this.pages.page;
      data.lines = this.pages.pageSize;
      const response = await this.$store.dispatch("SEARCH_BILLING", data);
      console.log(response);
      this.searchUseList = [];
      var list = response.data.data.find_bill_list;
      this.totalNum = response.data.data.total;
      if (list.length === 0) {
        this.searchUseList = [];
        this.useList = [];
      } else {
        for (var item of list) {
          var temp = {
            site_id: item.site_id,
            siteName: item.site_name,
            totalPaid: item.total,
            totalPaid10: Math.round(item.total / 1.1),
            billCreated: item.next_sett,
            billDone: item.created === "0" ? "처리 전" : "처리 완료",
            billFinishedDate: item.created === "0" ? "" : item.created,
            bill_detail_ret: item.used_plug_ret,
            missed_plug_ret: item.missed_plug_ret,
          };
          this.searchUseList.push(temp);
        }
      }

      //데이터 셋팅
    },
    checkSearchValid() {
      //시작일, 종료일 유효성 체크
      if (this.search.sDate > this.search.eDate) {
        alert("날짜의 입력 범위를 올바르게 입력해주세요.");
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>
h1 {
  color: #454545;
  text-align: center;
}

.mijung {
  padding: 70px 0px 15px 25px;
  font-size: 20px;
}

/* .col-sm-2 {
  -ms-flex: 0 0 16.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 16.666667%;
  max-width: 60.666667%;
  margin: 0 0 0 100px;
} */
.div1 {
  width: 400px;
  position: absolute;
  margin-top: -5px;
}
.div2 {
  padding-top: 45px;
  width: 600px;
  float: left;
}
p {
  width: 100px;
  margin: 0 0 0 20px;
  font-weight: bold;
  position: absolute;
}
/* 사작일 */

/* 날짜 */
.datepic1 {
  height: 31px;
  margin: 7px 0 0 142px;
  position: absolute;
  border-radius: 4px;
  border: 1px solid #4388c1;
  padding-left: 10px;
}
.datepic2 {
  height: 31px;
  margin-top: 7px;
  margin-left: 325px;
  position: absolute;
  border-radius: 4px;
  border: 1px solid #4388c1;
  padding-left: 10px;
}
/* 사이트명 */
.sub {
  margin: 0 0 0 130px;
}

/* 사용상태 */
.sub1 {
  margin: -48px 0 0 590px;
}
.p1 {
  margin: 10px 0 0 15px;

  width: 107px;
}
/*검색  */
.sub2 {
  margin: -55px 0 0 370px;
}
.p2 {
  margin: -39px 0 0 324px;
}
.p3 {
  margin: 15px 0 0 20px;
}

/* 검색 버튼 */
.seabtn {
  width: 70px;
  font-weight: 400;
  border-radius: 4px;
  background-color: #73bce6;
  border: 1px solid #4388c1;
  color: white;
  height: 30px;
  font-size: 13px;
  margin: -45px 0 0 0;
  padding: 0;
}
.btn-info.btn-fill:hover,
.btn-info.btn-fill:focus,
.btn-info.btn-fill:active,
.btn-info.btn-fill.active,
.open > .btn-info.btn-fill.dropdown-toggle {
  background-color: #73bce6;
  color: #ffffff;
}
.emptyContent {
  padding-top: 200px;
}
/* 내역보기 버튼 */
.uselog_btn {
  width: 70px;
  font-weight: 400;
  border-radius: 4px;
  background-color: #73bce6;
  border: 1px solid #4388c1;
  color: white;
  height: 30px;
  font-size: 13px;
}
.scroll {
  overflow-y: scroll;
  height: 250px;
}
</style>
