<template>
  <div>
    <!-- 검색 영역 -->
    <div class="row searchRow">
      <div class="col-sm-2">
        <base-select-search
          type="text"
          class="text-center selectInput"
          :items="siteList"
          title="사이트명"
          v-model="search.site"
          :selectId="this.search.site"
          @change="getSectionList()"
        ></base-select-search>
      </div>
      <div class="col-sm-2">
        <base-select-search
          type="text"
          class="text-center selectInput"
          :items="sectionList"
          title="섹션명"
          v-model="search.section"
        ></base-select-search>
      </div>
      <div class="col-sm-2">
        <base-select-search
          type="text"
          class="text-center selectInput"
          :items="plugStatusList"
          title="플러그 상태"
          v-model="search.plugStatus"
        ></base-select-search>
      </div>
      <div class="col-sm-2">
        <base-select-search
          type="text"
          class="text-center selectInput"
          :items="useStatusList"
          title="사용 상태"
          v-model="search.useStatus"
        ></base-select-search>
      </div>
      <div class="col-sm-2">
        <base-input-search
          type="text"
          class="text-center selectInput"
          placeholder="플러그 ID"
          v-model="search.plugName"
        ></base-input-search>
      </div>
      <div class="col-sm-2">
        <button
          type="submit"
          id="searchBtn"
          class="btn btn-info btn-fill float-right col-12"
          @click="getDeviceList()"
        >
          검색
        </button>
      </div>
    </div>
    <!-- 검색 영역 END-->

    <div v-if="tableData.length > 0">
      <div class="pc-table">
        <div class="div-table">
          <div class="div-row">
            <div class="div-cell" v-for="column in tableColumns" :key="column">
              <span v-if="column.substr(0, 3) == 'btn'"></span>
              <span v-else>
                {{ $t(column) }}
              </span>
            </div>
          </div>
          <div class="div-row" v-for="(item, index) in tableData" :key="index">
            <div class="div-cell" v-for="column in tableColumns" :key="column">
              <div v-if="column == 'btn-modify'">
                <button
                  class="btn btn-info"
                  @click="deviceModify(item.plug_uid)"
                >
                  수정
                </button>
              </div>
              <!-- <div v-else-if="column == 'btn-delete'">
                            <button class="btn btn-info small" @click="deviceDelete(item.plug_uid)">삭제</button>
                            </div> -->
              <div v-else-if="column == 'status'">
                <span
                  v-if="itemValue(item, 'status') == 'Available'"
                  class="status_notuse"
                >
                  대기중</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'ACTIVE'"
                  class="status_use"
                >
                  사용중</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == '2'"
                  class="status_wait"
                >
                  대기중</span
                >
              </div>
              <div v-else-if="column == 'plug_status'">
                <span
                  v-if="itemValue(item, 'plug_status') == 'BROKEN'"
                  class="status_notuse"
                >
                  고장</span
                >
                <span
                  v-else-if="itemValue(item, 'plug_status') == 'ON'"
                  class="status_use"
                >
                  ON</span
                >
                <span v-else-if="itemValue(item, 'plug_status') == 'OFF'">
                  OFF</span
                >
              </div>
              <div v-else>
                {{ itemValue(item, column) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="mobile-table">
        <div class="card" v-for="(item, index) in tableData" :key="index">
          <base-dropdown class="div-more">
            <template slot="title">
              <img src="~@/assets/imgs/icon-more.png" />
            </template>
            <a class="dropdown-item" @click="deviceModify(item.plug_uid)"
              >수정</a
            >
            <a class="dropdown-item" @click="deviceDelete(item.plug_uid)"
              >삭제</a
            >
          </base-dropdown>
          <div class="card-body">
            <div class="card-title">{{ itemValue(item, "serialNumber") }}</div>
            <div>{{ itemValue(item, "section") }}</div>
            <div class="status">
              <span
                v-if="itemValue(item, 'status') == 'Available'"
                class="status_notuse"
              >
                대기중</span
              >
              <span
                v-else-if="itemValue(item, 'status') == 'ACTIVE'"
                class="status_use"
              >
                사용중</span
              >
              <span
                v-else-if="itemValue(item, 'status') == '2'"
                class="status_wait"
              >
                대기중</span
              >
            </div>
            <div>
              <span> {{ itemValue(item, "date") }}</span>
              <span> {{ itemValue(item, "startTime") }}</span>
            </div>
            <div>
              <span class="price"> {{ itemValue(item, "price") }}원 </span>
            </div>
          </div>
        </div>
      </div> -->

      <div class="btn-cover">
        <ul class="pagination pagination-no-border">
          <li class="page-item prev-page">
            <button
              aria-label="Previous"
              @click="getDeviceList(pageNum - 1)"
              v-bind:class="{ 'page-link': true, disabled: pageNum <= 1 }"
            >
              <span>&lt;&lt;</span>
            </button>
          </li>
          <li
            class="page-item"
            v-for="(item, index) in pageCount"
            :key="item"
            :class="{ active: pageNum == index + 1 }"
          >
            <a class="page-link" @click="getDeviceList(index + 1)">{{
              index + 1
            }}</a>
          </li>
          <li class="page-item page-pre next-page">
            <button
              @click="getDeviceList(pageNum + 1)"
              v-bind:class="{
                'page-link': true,
                disabled: pageNum >= pageCount,
              }"
            >
              <span>&gt;&gt;</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <div class="emptyContent">표시할 내역이 없습니다.</div>
    </div>
  </div>
</template>

<script>
const tableColumns = [
  "serialNumber",
  "plugName",
  "price",
  "plug_status",
  "date",
  "startTime",
  "status",
  "btn-modify",
  "btn-delete",
];

export default {
  name: "site-detail-list",
  data() {
    return {
      pageNum: 0,
      tableColumns: tableColumns,
      tableData: [],
      totalNum: 0,
      pages: {
        page: 1,
        pageSize: 15,
        status: this.filterStatus,
        site_id: this.siteId,
      },
      //검색에 쓰일 리스트
      siteList: [],
      sectionList: [],
      plugStatusList: [
        { id: "on", name: "ON" },
        { id: "off", name: "OFF" },
        { id: "breakdown", name: "고장" },
      ],
      useStatusList: [
        { id: "ACTIVE", name: "사용중" },
        { id: "Available", name: "대기중" },
      ],
      search: {
        site: "",
        section: "",
        plugStatus: "",
        useStatus: "",
        plugName: "",
      },
      section: {
        //섹션 리스트 불러올 때 사용
        page: 1,
        pageSize: 9999,
        siteId: "",
      },
    };
  },
  props: {
    listArray: {
      type: Array,
      required: false,
    },
    // data: {
    //     type: Array,
    //     required: false,
    //     default: []
    // },
    item: {
      type: Object,
    },
    column: {
      type: String,
    },
    filterStatus: {
      type: String,
    },
    siteId: {
      type: String,
    },
  },
  created() {
    if (!this.$route.params.siteId) {
      alert("페이지를 불러오는데 실패했습니다.");
      this.$router.go(-1);
    }

    //검색창 초기 데이터 셋팅
    this.getSiteList();

    this.search.site = this.$route.params.siteId;
    this.getDeviceList();
  },
  watch: {
    filterStatus: function () {
      this.getDeviceList();
    },
  },
  computed: {
    pageCount() {
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;

      return page;
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column];
    },
    deviceModify(deviceId) {
      alert("수정 페이지로 이동합니다.");
      this.$router.push("/admin/device/modify/" + deviceId);
    },
    // deviceDelete(deviceId) {
    //   const params = new URLSearchParams();
    //   params.append("request", "delete_plug");
    //   params.append("plug_uid", deviceId);

    //   this.$axios.post("", params).then((res) => {
    //     const data = res.data;
    //     if (data.result == false || (data.data && data.data.error)) {
    //       var error = data.data.error;
    //       var errorText = "";
    //       if (error == "SESSION_EXPIRED") {
    //         errorText = "세션 만료 되었습니다.";
    //         //todo 홈으로 이동
    //       } else if (error == "INVALID_CORP") {
    //         errorText = "유효하지 않은 사업자 입니다.";
    //       } else if (error == "DB_ERROR") {
    //         errorText = "DB에 오류가 발생했습니다.";
    //       } else {
    //         errorText = "작업을 처리하는데 실패하였습니다.";
    //       }
    //       alert(errorText);
    //       return false;
    //     }

    //     alert("삭제되었습니다.");
    //     this.getDeviceList(1);
    //   });
    // },
    async getDeviceList(page) {
      this.pageNum = page ? page : 1;
      this.pages.status = this.filterStatus;
      this.pages.page = this.pageNum;
      const response = await this.$store.dispatch(
        "FETCH_GET_PLUG_SITE_LIST",
        this.pages
      );
      console.log("response:", response.data.result);
      console.log(response.data);

      const data = response.data;
      // console.log("data :" + JSON.stringify(data));
      if (data.result == false || data.data.error) {
        var error = data.data.error;
        var errorText = "";
        if (error == "SESSION_EXPIRED") {
          errorText = "세션 만료 되었습니다.";
          //todo 홈으로 이동
        } else if (error == "INVALID_CORP") {
          errorText = "유효하지 않은 사업자 입니다.";
        } else if (error == "DB_ERROR") {
          errorText = "DB에 오류가 발생했습니다.";
        } else {
          errorText = "작업을 처리하는데 실패하였습니다.";
        }
        alert(errorText);
        return false;
      }

      this.tableData = [];
      this.totalNum = response.data.data.total;
      var list = response.data.data.plug_list;

      for (var item of list) {
        var temp = {
          plug_uid: item.plug_uid,
          serialNumber: item.plug_id,
          plugName: item.name,
          status: item.status,
          date: item.created.split(" ")[0], //todo 수정필요
          startTime: item.created.split(" ")[1],
          price: item.unit_price, //todo 수정필요
          plug_status: item.plug_status,
        };
        this.tableData.push(temp);
      }
    },
    async getSiteList() {
      //사이트 리스트 불러오기
      const response = await this.$store.dispatch(
        "FETCH_GET_SITE_LIST",
        this.pages
      );

      //데이터 셋팅
      var list = response.data.data.site_list;
      for (var item of list) {
        var temp = {
          id: item.site_id,
          name: item.name,
        };
        this.siteList.push(temp);
      }

      this.getSectionList(); //페이지 첫 로드 시 선택된 사이트의 섹션이 나와야하므로 여기서 함수 호출함.
    },
    async getSectionList() {
      //값 초기화
      this.sectionList = [];

      //섹션 리스트 불러오기
      this.section.siteId = this.search.site;
      const response = await this.$store.dispatch(
        "FETCH_GET_SECTION_LIST",
        this.section
      );

      //데이터 셋팅
      var list = response.data.data.sections;
      for (var item of list) {
        var temp = {
          id: item.section_id,
          name: item.name,
        };
        this.sectionList.push(temp);
      }
    },
  },
};
</script>

<style scoped>
h1 {
  color: #454545;
  text-align: center;
}

.oprdevice {
  padding-right: 35px;
}

.card-title {
  font-size: 25px;
  font-weight: bold;
}
</style>
