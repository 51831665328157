<template>
  <div class="content">
    <div class="container-fluid">
      <card>
        <div class="infopic myinfo_img1" @click="infoChange()">
          <img class="img1" src="~@/assets/imgs/myinfo/SET_BUTTON_1.svg" />
        </div>
        <div class="infopic myinfo_img2" @click="passChange()">
          <img class="img1" src="~@/assets/imgs/myinfo/SET_BUTTON_2.svg" />
        </div>
      </card>
    </div>
  </div>
</template>

<script>
// import Card from "src/components/Cards/Card.vue";

export default {
  // components: {
  //   Card
  // },
  // data() {},

  methods: {
    infoChange() {
      this.$router.push("/admin/user/infoChange/");
    },
    passChange() {
      this.$router.push("/admin/user/passChange/");
    }
  }
};
</script>
<style scoped>
.col-md-10 {
  padding: 0;
  max-width: 100%;
}
/* .infopic{
  position:relative;
  margin-top :7%;
  display: inline-block;
  margin-bottom: 7%;

  } */
.myinfo_img1 {
  margin-left: 80px;
  margin-top: 80px;
  margin-bottom: 80px;
}
.myinfo_img2 {
  margin-left: 80px;
  margin-bottom: 80px;
}
.img1 {
  width: 300px;
}
.img2 {
  width: 300px;
}
</style>
