<template>
  <div>
    <div class="pc-table">
      <div class="div-table">
        <div class="div-row">
          <div class="div-cell" v-for="column in tableColumns" :key="column">
            <span v-if="column.substr(0, 3) == 'adm'"></span>
            <span v-else>
              {{ $t(column) }}
            </span>
          </div>
        </div>
        <div
          class="div-row"
          v-for="(item, index) in paginatedDate"
          :key="index"
        >
          <div class="div-cell" v-for="column in tableColumns" :key="column">
            <span v-if="hasValue(item, column) && column == 'otpissue'">
              <button
                v-if="itemValue(item, 'time') !== 22200000000000"
                class="issueOTP"
                @click="otpIssue(index)"
              >
                {{ $t("otpissue") }}
              </button>
              <button
                v-if="itemValue(item, 'time') == 22200000000000"
                class="unlimitCancel"
                @click="unlimitCancel(index)"
              >
                {{ $t("unlimit-cancel") }}
              </button>
            </span>
            <div v-else-if="hasValue(item, column) && column == 'status'">
              <span
                v-if="itemValue(item, 'status') == 'Available'"
                class="status_notuse"
                >{{ $t("all_status.notuse") }}</span
              >
              <span
                v-else-if="itemValue(item, 'status') == 'OTP_ACTIVE'"
                class="status_use"
                >{{ $t("all_status.use") + " (" + arr2time[index] + ")" }}
              </span>
              <span
                v-else-if="itemValue(item, 'status') == 'UNLIMIT'"
                class="status_use"
                >{{ $t("all_status.use") + " (무제한)" }}</span
              >
              <span
                v-else-if="itemValue(item, 'status') == 'ACTIVE'"
                class="status_use"
                >{{ $t("all_status.use") + " (" + arr2time[index] + ")" }}</span
              >
              <span
                v-else-if="itemValue(item, 'status') == 'Wait'"
                class="status_wait"
                >{{
                  $t("all_status.wait") + " (" + arr2time[index] + ")"
                }}</span
              >
            </div>
            <div v-else-if="hasValue(item, column) && column == 'plug_id'">
              <a class="pointer link" @click="linkDeviceDetail(item.plug_uid)">{{
                itemValue(item, column)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }}</a>
            </div>
            <div v-else-if="hasValue(item, column) && column == 'charge'">
              {{ itemValue(item, column) | numCommaFilter }}
            </div>
            <div v-else>
              {{ itemValue(item, column) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="moreview btn btn-default btn-block"
      v-show="listSize > i"
      @click="moreview"
      style="border: 1px solid #ddd"
    >
      {{ $t("moreview") }}▼
    </div>

    <otp-check-modal
      v-if="showOtpCheckModal"
      @close="showOtpCheckModal = false"
      :serialNumber="serialNumber"
    ></otp-check-modal>
    <otp-issue-modal
      v-if="showOtpIssueModal"
      @close="showOtpIssueModal = false"
      :serialNumber="serialNumber"
      :seedNumber="seedNumber"
      :plug_name="plug_name"
      :lastMobile="lastMobile"
    ></otp-issue-modal>
  </div>
</template>

<script>
const tableColumns = [
  "siteName",
  "plug_id",
  // "plug_name",
  "status",
  "mobile_number",
  "usetime",
  "charge",
  "startTime",
  "endTime",
  "otpissue",
];

import OtpCheckModal from "src/components/Modals/OtpCheckModal.vue";
import OtpIssueModal from "src/components/Modals/OtpIssueModal.vue";

export default {
  components: {
    OtpCheckModal,
    OtpIssueModal,
  },
  name: "dashboard-device-table",
  data() {
    return {
      // 타이머
      Timer: null,

      // 타이머 카운터
      TimeCounter: 180,

      // 타이머 시작 시간
      TimerStr: "03:00",

      // 리스트 갯수
      i: 15,

      // 더보기 데이터
      moreLine: 15,

      // 리스트 갯수
      listSize: 15,

      // 테이블 컬럼
      tableColumns: tableColumns,

      // 사용 현황 데이터
      useList: [],

      // otp 모달에 나오는 플러그 명
      plug_name: "",

      // 모달창 여부
      showOtpCheckModal: false,
      showOtpIssueModal: false,

      // 시리얼 넘버
      serialNumber: "",

      // 시드 넘버
      seedNumber: "",

      // 마지막 사용자 번호
      lastMobile: "",

      // 전화번호
      phoneNum: "",

      // otp 시작 시간
      start: "",

      // 플러그 레코드 id
      plug_record_id: "",

      // 페이징데이터
      paginatedDate: [],
      // 페이징 갯수
      pages: { page: 1, pageSize: 1000, status: "" },
      // 타이머 시간
      arr2time: [],
      arrtime: [],

      any: "",

      // 시작시간 종료시간
      start_Time: "",
      end_time: "",
    };
  },
  props: {
    p_status: {
      type: String,
    },
  },

  created() {
    this.pages.status = this.p_status;
    //초기 데이터 셋팅
    this.updateList();
    this.getList();
    if (this.Timer != null) {
      this.timerStop(this.Timer);
      this.Timer = null;
    }
    this.Timer = this.timerStart();
  },
  methods: {
    // 사용상태에 나오는 시간
    timerStart: function () {
      // 1초에 한번씩 start 호출
      var linkcheck = this.$route.fullPath;
      var minuteFormat = "";
      var SecondFormat = "";
      this.TimeCounter = 180;
      var interval = setInterval(() => {
        if (!window.location.href.includes("overview")) {
          this.timerStop(this.Timer);
        } else {
          this.arr2time = [];
          for (var i = 0; i < this.arrtime.length; i++) {
            this.arrtime[i]--;

            // console.log(this.arrtime[i]);
            if (this.arrtime[i] > 0) {
              // if (this.arrtime[i] % 60 < 10) {
              //   console.log("0" + (this.arrtime[i] % 60));
              // }
              // if(this.arrtime[i]/60 <10) console.log("0"+)
              minuteFormat = Math.floor(this.arrtime[i] / 60);
              if (minuteFormat < 10) {
                minuteFormat = "0" + Math.floor(this.arrtime[i] / 60);
              } else {
                minuteFormat = Math.floor(this.arrtime[i] / 60);
              }

              SecondFormat = Math.round(this.arrtime[i] % 60);
              if (SecondFormat < 10) {
                SecondFormat = "0" + Math.round(this.arrtime[i] % 60);
              } else {
                SecondFormat = Math.round(this.arrtime[i] % 60);
              }

              this.arr2time.push(minuteFormat + ":" + SecondFormat);
            }
            if (this.arrtime[i] === 898) {
              window.location.reload();
              this.useList = [];
              // this.getList();
            }
            // 배열에 있는 값이 0이 되었을 때 사용 상태 Flag를 업데이트
            if (this.arrtime[i] === 0) {
              window.location.reload();
              this.useList = [];
              // this.getList();
              this.timerStop(interval);
            }
          }
        }

        if (this.TimeCounter <= 0) this.timerStop(interval);
      }, 1000);
      return interval;
    },

    timerStop: function (Timer) {
      clearInterval(Timer);
      this.TimeCounter = 0;
    },

    prettyTime: function () {
      // 시간 형식으로 변환 리턴
      let time = this.TimeCounter / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return (
        minutes.toString().padStart(2, "0") +
        ":" +
        secondes.toString().padStart(2, "0")
      );
    },

    hasValue(item, column) {
      return item[column] !== "undefined";
    },
    itemValue(item, column) {
      return item[column];
    },

    // 더보기
    moreview() {
      this.i = this.i + this.moreLine;
      this.paginatedDate = this.useList.slice(0, this.i);
    },

    // 플러그 디테일 정보로 들어감
    linkDeviceDetail(deviceId) {
      alert("상세 페이지로 이동합니다. ");
      this.$router.push("/admin/device/detail/" + deviceId);
    },

    // otp 발급
    otpIssue(index) {
      // console.log(this.useList);
      this.serialNumber = this.useList[index].plug_id;
      this.seedNumber = this.useList[index].seed_num;
      this.plug_name = this.useList[index].plug_name;
      this.phoneNum = this.useList[index].mobile_number;
      var thisnum = this.phoneNum.toString();
      this.lastMobile = [
        thisnum.slice(0, 3),
        "-",
        thisnum.slice(3, 7),
        "-",
        thisnum.slice(7),
      ].join("");
      console.log(this.phoneNum);
      console.log(this.useList[index].plug_status);
      if (this.useList[index].plug_status != "ON") {
        alert(
          "해당 플러그는 고장 또는 OFF상태의 플러그입니다. 다른 플러그를 사용하세요 "
        );
        return false;
      }
      if (this.useList[index].otpissue === 1) {
        this.showOtpIssueModal = true;
      } else {
        alert(
          '현재 사이트 상태가 "사용 안함" 이기 때문에 OTP 발급이 불가합니다."사용중"으로 변경하시거나,  "사용 안함 시에도  OTP 발급 가능" 옵션을 설정하시기 바랍니다'
        );
      }
    },

    // 무제한 해지
    async unlimitCancel(index) {
      if (
        confirm(
          "해당 플러그의 무제한 사용은 종료하는 것이 맞습니까?\n종료하게 되면 15분의 대기 후 일반 사용자들이 사용할 수 있게 변경됩니다. "
        ) == true
      ) {
        this.serialnumber = this.useList[index].plug_id;
        this.start = this.useList[index].start;
        this.plug_record_id = this.useList[index].plug_record_id;
        var unlimit_data = {
          plug_id: this.serialnumber,
          start_time: this.start,
          plug_rec_id: this.plug_record_id,
        };
        const response = await this.$store.dispatch(
          "UNLIMIT_CANCEL",
          unlimit_data
        );
        console.log("unlimit-cancel: ", response);
        window.location.reload();
      } else {
        return false;
      }
    },

    // 수정된 플러그 리스트
    async updateList() {
      const response = await this.$store.dispatch(
        "UPDATE_GET_PLUG_LIST",
        this.pages
      );
      console.log("update_list", response);
    },

    // 플러그 리스트
    async getList() {
      const response = await this.$store.dispatch(
        "FETCH_GET_PLUG_LIST",
        this.pages
      );
      console.log("response_jak", response);
      this.listSize = response.data.data.total;
      var list = response.data.data.plug_list;
      this.nowTime = response.data.data.nowTime;
      // this.listSize = list.length;
      for (var item of list) {
        if (item.pay_type === "sub") {
          item.charge = "정기권";
        } else {
          item.charge = item.charge < 0 ? "관리자" : item.charge;
        }

        var temp = {
          plug_uid: item.plug_uid,
          provider_name: item.provider_name,
          site_id: item.site_id,
          siteName: item.site_name,
          plug_id: item.plug_id,
          plug_name: item.name,
          mobile_number: item.mob_number,
          phone: item.phone,
          // charge: item.charge < 0 ? "관리자" : item.charge,
          charge: item.charge,
          price: item.charge,
          status: item.status,
          plug_status: item.plug_status,
          plug_record_id: item.plug_record_id,
          usetime: this.$getDuringTime(item.time_from, item.time_to),
          startTime: this.$getDateYYYYMMDD(item.time_from),
          time: item.time_to,
          start: item.time_from,
          endTime:
            this.$getDateYYYYMMDD(item.time_to) == "2220-00-00 00:00"
              ? "-"
              : this.$getDateYYYYMMDD(item.time_to),
          otpissue: item.otp == "otp_use" ? 1 : 0,
          seed_num: item.seed_num,
        };

        if (item.status !== "Available") {
          this.start_Time = response.data.data.nowTime
            .replace("-", "")
            .replace("-", "")
            .replace(" ", "")
            .replace(":", "")
            .replace(":", "");

          this.start_Time = this.start_Time;
          this.end_Time = item.time_to;
          this.any = this.$getDuringTimeSeconds(this.start_Time, this.end_Time);

          this.arrtime.push(this.any);
        }
        this.useList.push(temp);
      }

      /// not using this here but, maybe usefull for other things.
      // // this is for unlimit plug to stay always at the top
      // var j = 0;
      // // plug name and id is not working well in otp with this

      // for (var i = 0; i < this.useList.length; i++) {
      //   if (this.useList[i].price === -3) {
      //     this.otherlist[j] = this.useList[i];
      //     j = j + 1;
      //   }
      // }
      // for (var i = 0; i < this.useList.length; i++){
      //   if (this.useList[i].price !== -3) {
      //     this.otherlist[j] = this.useList[i];
      //     j = j+1;
      //   }
      // }
      this.paginatedDate = this.useList.slice(0, this.i);
    },
  },
};
</script>
<style scoped>
@media (max-width: 768px) {
  #tickets thead {
    display: none;
  }

  #tickets,
  #tickets tbody,
  #tickets tr,
  #tickets th,
  #tickets td {
    display: block;
  }

  #tickets tr {
    border-bottom: 1px solid #ddd;
  }

  #tickets th,
  #tickets td {
    border-top: none;
    border-bottom: none;
  }

  #tickets td:first-child {
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    padding: 12px 8px;
  }

  #tickets td:nth-child(2) {
    display: block;
  }

  #tickets td:nth-child(3) {
    width: 50%;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0px;
    right: 0px;
    text-align: center;
    padding: 68px 0;
  }

  #tickets td:nth-child(3) span {
    font-size: 20px;
    font-weight: bold;
    color: #4caf50;
  }

  #tickets td:nth-child(4),
  #tickets td:nth-child(5),
  #tickets td:nth-child(6) {
    display: table-cell;
  }

  .issueOTP,
  .unlimitCancel {
    width: 100%;
    height: 45px;
  }
}

/* 여기서부터 */
.link {
  color: #73bce6;

  text-decoration: underline;
}

.moreview {
  text-align: center;
  height: 45px;
  padding: 13px 0px;
}

/*mobile-table css */
.mobile-table .userNumber {
  font-size: 25px;
  font-weight: bold;
  float: left;
  width: 100%;
  padding-right: 115px;
}

.mobile-table .status {
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 18px;
  right: 60px;
  float: right;
}
</style>
