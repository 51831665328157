<template>
  <li
    class="dropdown nav-item"
    :is="tag"
    :class="{ show: isOpen, active: isOpen }"
    aria-haspopup="true"
    :aria-expanded="isOpen"
    @click="toggleDropDown"
    v-click-outside="closeDropDown"
    v-bind="$attrs"
  >
    <a class="nav-link dropdown-toggle" data-toggle="dropdown">
      <slot name="title">
        <i :class="icon"></i>
        <span class="no-icon">{{ title }}</span>
        <b class="caret"></b>
      </slot>
    </a>
    <div class="dropdown-menu show" v-show="isOpen">
      <slot>
        <router-link class="sidebar-dropdown-item" to="/admin/site/add"
          >Notification 1</router-link
        >
      </slot>
    </div>
  </li>
</template>
<script>
export default {
  name: "base-dropdown",
  props: {
    title: String,
    icon: String,
    tag: {
      type: String,
      default: "li",
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleDropDown() {
      this.isOpen = true;
    },
    closeDropDown() {
      this.isOpen = false;
    },
  },
};
</script>
<style scoped>
.dropdown .dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu.show {
  background: transparent;
  display: contents;
}

.sidebar-dropdown-item {
  margin: 5px 15px 0px 40px;
  border-radius: 4px;
  color: #ffffff;
  opacity: 0.86;
  padding: 8px 50px;
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 1rem;
}

.sidebar-dropdown-item:hover,
.sidebar-dropdown-item:focus,
.sidebar-dropdown-item.active {
  background-color: white;
  color: #333333;
  opacity: 1;
  text-decoration: none;
  transition: all 150ms ease-in;
}
</style>
