<template>
  <div class="content">
    <div class="container-fluid">
      <card>
        <div class="row">
          <div class="col-md-6">
            <label class="control-label"> 비밀번호</label>
            <base-input
              type="password"
              placeholder="비밀번호"
              v-model="user.pw"
            >
            </base-input>
            <p class="status col-12" :style="status.password.style">
              {{ isPasswordValid }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="control-label"> 비밀번호 재확인</label>
            <base-input
              type="password"
              placeholder="비밀번호 재확인"
              v-model="user.pw2"
            >
            </base-input>
            <p class="status col-12" :style="status.repass.style">
              {{ isCheckpasswordValid }}
            </p>
          </div>
        </div>

        <div class="text-center">
          <button
            type="submit"
            class="btn btn-info btn-fill"
            id="saveBtn"
            @click.prevent="changePass()"
          >
            저장하기
          </button>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import {
  validateEmail,
  validatePhone,
  validatePassword,
  validateTel,
  validateName,
} from "src/components/Utils/Validate.js";
export default {
  components: {
    Card,
    BaseInput,
  },
  data() {
    return {
      user: {
        pw: "",
        pw2: "",
      },
      status: {
        checkcode: { message: "", style: "", complete: false },
        email: { message: "", style: "", complete: false },
        password: { style: "", complete: false },
        repass: { style: "", complete: false },
        mobile: { style: "", complete: false },
        tel: { style: "", complete: false },
        name: { style: "", complete: false },
      },
      style: {
        warn: "color: red",
        success: "color: green",
      },
    };
  },
  computed: {
    isPasswordValid() {
      if (this.user.pw === "") {
        return;
      } else if (validatePassword(this.user.pw)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.password.style = this.style.success;
        this.status.password.complete = true;
        return "사용 가능한 비밀번호입니다.";
      } else {
        this.status.password.style = this.style.warn;
        this.status.password.complete = false;
        return "영소문자와 숫자를 혼용하여 8~20자 이내로 설정해주세요.";
      }
    },
    isCheckpasswordValid() {
      if (this.user.pw2 === "") {
        return "";
      } else if (this.user.pw === this.user.pw2) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.repass.style = this.style.success;
        this.status.repass.complete = true;
        return "비밀번호가 일치합니다.";
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.repass.style = this.style.warn;
        this.status.repass.complete = false;
        return "비밀번호가 일치하지 않습니다.";
      }
    },
  },
  methods: {
    async changePass() {
      if (this.user.pw != this.user.pw2) {
        alert("비밀번호가 일치하지 않습니다. 다시 입력하시기 바랍니다.");
      } else if (this.user.pw === this.user.pw2) {
        if (this.status.password.complete === false) {
          alert(
            "영소문자와 숫자를 혼용하여 8~20자 이내로 설정하고 저장해주세요."
          );
          return false;
        }
        if (this.status.repass.complete === false) {
          alert("비밀번호가 일치하지 않습니다 다시 입력해주세요.");
          return false;
        }
        var password = this.user.pw;
        const response = await this.$store.dispatch("RESET_PASSWORD", password);
        if (response.data.result === true) {
          alert("정상적으로 비밀번호가 변경했습니다");
          this.$router.push("/admin/overview");
        } else {
          alert("오류가 발생했습니다. 잠시 후 시도해보세요.");
        }
      } else {
        alert("error");
      }
    },
  },
};
</script>
<style scoped>
.find-address {
  width: 90px;
  height: 25px;
  padding: 0;
  margin: 11px 0px 11px 15px;
  background-color: #e9ecef;
}

.electric_type-group {
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.electric_type {
  margin-right: 4px;
}

.electric_type-img {
  width: 134px;
  height: 120px;
}

.input-group-text {
  width: 35px;
}

.input-group-text span {
  text-align: center;
  margin: 0 auto;
}

.section1 {
  padding-left: 15px !important;
  padding-right: 5px !important;
}

/***********joinform css */
* {
  font-family: Noto Sans KR;
  font-style: normal;
}
.joinForm-title {
  margin-bottom: 25px;
}

#accountAuth {
  height: 25px;
  padding: 2px 0;
  top: 603px;
  right: 38px;
  position: absolute;
  font-weight: 500;
  font-size: 12.5px;
  line-height: 21px;
  width: 90px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
}
#overlap {
  height: 25px;
  padding: 2px 0;
  top: 6.3rem;
  right: 2rem;
  position: absolute;
  font-weight: 500;
  font-size: 12.5px;
  line-height: 21px;
  width: 90px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
}

.auth {
  min-width: 60px;
  width: 100%;
  height: 40px;
  padding: 2px 0;
  font-weight: 500;
  font-size: 15px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
}

.twice-check {
  min-width: 60px;
  width: 100%;
  height: 40px;
  padding: 2px 0;
  font-weight: 500;
  font-size: 15px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
  margin: 0px;
}

.find-address {
  min-width: 60px;
  width: 100%;
  height: 40px;
  padding: 2px 0;
  font-weight: 500;
  font-size: 15px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
  margin: 0px;
}
.signup {
  height: 65px;
  margin: 15px 0px 15px 0px;
  background: #156ab2;
  border-radius: 3px;
}
.status {
  position: relative;
  top: -10px;
  left: 10px;
  font-size: 12.5px;
  margin: 0px 0px 10px 0px;
  height: 10px;
}

.backBtn {
  position: absolute;
  left: 15px;
  height: 35px;
  padding: 5px 10px;
}

.infoform-btn-div {
  margin: auto auto 16px auto;
}

.daum-address {
  margin-bottom: 25px;
}

.account_notice {
  padding: 5px 35px;
}

.account_notice span {
  display: block;
  font-weight: 400;
  font-size: 13px;
  color: #9a9a9a;
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

#tb_acoount_history {
  font-size: 12px;
}

#tb_acoount_history tbody {
  border-top: 1px solid #156ab2;
}

.accountImgName {
  font-size: 12px;
  color: #156ab2;
  margin: -10px 0px 0px 35px;
}
</style>

<style lang="scss">
@mixin can-toggle-branding(
  $can-toggle-off-color: #777,
  $can-toggle-on-color: #42d0ed,
  $can-toggle-inactive-text: rgba(white, 0.5),
  $can-toggle-transition: cubic-bezier(0, 1, 0.5, 1)
) {
  input[type="checkbox"] {
    &[disabled] ~ label {
      color: rgba($can-toggle-off-color, 0.5);
    }

    &:focus ~ label,
    &:hover ~ label {
      .can-toggle__switch {
        background-color: $can-toggle-off-color;
        &:after {
          color: darken($can-toggle-off-color, 10%);
        }
      }
    }
    &:hover ~ label {
      color: darken($can-toggle-off-color, 5%);
    }
    &:checked {
      ~ label {
        &:hover {
          color: darken($can-toggle-on-color, 3%);
        }

        .can-toggle__switch {
          background-color: lighten($can-toggle-on-color, 5%);
          &:after {
            color: darken($can-toggle-on-color, 5%);
          }
        }
      }

      &:focus,
      &:hover {
        ~ label {
          .can-toggle__switch {
            background-color: $can-toggle-on-color;
            &:after {
              color: darken($can-toggle-on-color, 10%);
            }
          }
        }
      }
    }
  }

  label {
    .can-toggle__switch {
      transition: background-color 0.3s $can-toggle-transition;
      background: lighten($can-toggle-off-color, 5%);
      &:before {
        color: $can-toggle-inactive-text;
      }
      &:after {
        // Autoprefixer choked here, so making the prefixes explicit
        -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
        transition: transform 0.3s $can-toggle-transition;
        color: $can-toggle-off-color;
      }
    }
  }
}

@mixin can-toggle-appearance(
  $can-toggle-width: 134px,
  $can-toggle-height: 36px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 14px,
  $can-toggle-switch-font-size: 12px,
  $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
) {
  $can-toggle-switch-width: $can-toggle-width/2;

  input[type="checkbox"] {
    &:focus ~ label,
    &:hover ~ label {
      .can-toggle__switch {
        &:after {
          box-shadow: $can-toggle-shadow;
        }
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:after {
            transform: translate3d(
              $can-toggle-width -
                ($can-toggle-switch-width + $can-toggle-offset),
              0,
              0
            );
          }
        }
      }
      &:focus,
      &:hover {
        ~ label {
          .can-toggle__switch {
            &:after {
              box-shadow: $can-toggle-shadow;
            }
          }
        }
      }
    }
  }

  label {
    font-size: $can-toggle-label-font-size;

    .can-toggle__switch {
      height: $can-toggle-height;
      flex: 0 0 $can-toggle-width;
      border-radius: $can-toggle-border-radius;

      &:before {
        left: $can-toggle-width/2;
        font-size: $can-toggle-switch-font-size;
        line-height: $can-toggle-height;
        width: $can-toggle-width/2;
        padding: 0 12px;
      }

      &:after {
        top: $can-toggle-offset;
        left: $can-toggle-offset;
        border-radius: $can-toggle-border-radius/2;
        width: $can-toggle-switch-width - $can-toggle-offset;
        line-height: $can-toggle-height - ($can-toggle-offset * 2);
        font-size: $can-toggle-switch-font-size;
      }

      &:hover {
        &:after {
          box-shadow: $can-toggle-shadow;
        }
      }
    }
  }
}

.can-toggle {
  position: relative;
  margin-bottom: 15px;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  //overflow: hidden;

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &[disabled] ~ label {
      pointer-events: none;
      .can-toggle__switch {
        opacity: 0.4;
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:before {
            content: attr(data-unchecked);
            left: 0;
          }

          &:after {
            content: attr(data-checked);
          }
        }
      }

      &:focus,
      &:hover {
        ~ label {
        }
      }
    }
  }

  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;

    .can-toggle__switch {
      position: relative;

      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
      }

      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: white;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  // Default values for .can-toggle class
  @include can-toggle-branding;
  @include can-toggle-appearance;

  &.can-toggle--size-large {
    @include can-toggle-appearance(
      160px,
      // Toggle width
      50px,
      // Toggle height
      4px,
      // Toggle border radius
      2px,
      // Offset (distance btw switch and box)
      14px,
      // Label font size
      14px,
      // Switch font size
      0 4px 4px rgba(black, 0.4) / / Switch shadow on hover/focus
    );
  }
}
</style>
