<template>
  <div class="content">
    <div class="container-fluid">
      <card class="card-wrapper">
        <h4 slot="header" v-if="form_type == 'add'" class="card-title">
          {{ $t("addDevice.addPlugTitle") }}
        </h4>
        <!-- <h4 slot="header"  v-else class="card-title">플러그 수정</h4> -->
        <h4 slot="header" v-else class="card-title">
          {{ $t("addDevice.modifyPlugTitle") }}
        </h4>
        <form>
          <label class="control-label"
            >{{ $t("addDevice.serialNumber") }}
            <a v-if="form_type == 'add'" style="color: red; font-size: 12px"
              >&nbsp;*필수</a
            >
          </label>
          <div class="row">
            <div class="col-7">
              <base-input
                v-if="form_type == 'add'"
                type="text"
                :maxlength="9"
                v-model="plug.serial_num"
                oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
              >
              </base-input>
              <base-input
                v-if="form_type == 'modify'"
                style="display: none"
                type="text"
                :maxlength="8"
                v-model="plug.serial_num"
                oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
              >
              </base-input>
              <base-input
                v-if="form_type == 'modify'"
                type="text"
                :maxlength="8"
                v-model="plug.serial_num"
                oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                disabled
              >
              </base-input>
            </div>
            <div class="col-1" v-if="this.form_type != 'modify'">
              <button
                @click.prevent="checkPlug()"
                type="submit"
                class="btn btn-info btn-fill duplicate"
              >
                중복 확인
              </button>
            </div>
          </div>
          <p
            class="status col-12"
            :style="status.plug.style"
            v-if="status.plug.message != ''"
          >
            {{ status.plug.message }}
          </p>

          <label class="control-label">{{ $t("addDevice.setSite") }} </label>
          <a style="color: red; font-size: 12px">&nbsp;* 필수</a>

          <div class="row" v-if="form_type == 'add'">
            <div class="col-7">
              <select
                class="siteSelect"
                v-model="selectSite"
                @change="getSectionList()"
              >
                <option
                  v-for="(item, index) in siteList"
                  :key="index"
                  v-bind:value="item"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row" v-else>
            <div class="col-7">
              <select
                class="siteSelect"
                v-model="selectSite"
                @change="getSectionList(), setSectionName()"
              >
                <option
                  v-for="(item, index) in siteList"
                  :key="index"
                  :value="item.site_id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <label class="control-label">{{ $t("addDevice.setSection") }}</label>
          <a style="color: red; font-size: 12px">&nbsp;* 필수</a>

          <div class="row">
            <div class="col-7">
              <select v-model="plug.selectSection">
                <option
                  v-for="(item, index) in sectionList"
                  :key="index"
                  :value="item.section_id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <!-- <label class="control-label"
            >{{ $t("addDevice.plugName") }}( 20자 이내 )</label
          >
          <a style="color: red; font-size: 12px">&nbsp;* 필수</a>
          <div class="row">
            <div class="col-7">
              <base-input type="text" v-model="plug.device_name"></base-input>
            </div>
          </div> -->

          <label class="control-label">{{ $t("addDevice.plug_Type") }}</label>
          <a style="color: red; font-size: 12px">&nbsp;* 필수</a>

          <div class="row">
            <div class="col-md-12 device_type-div">
              <div class="device_type-group">
                <div class="device_type" @click="plug.device_type = 5">
                  <img
                    class="device_type-img"
                    v-show="plug.device_type == 5"
                    src="~@/assets/imgs/device/P_pillar-01_on.png"
                  />
                  <img
                    class="device_type-img"
                    v-show="plug.device_type != 5"
                    src="~@/assets/imgs/device/P_pillar-01_off.png"
                  />
                </div>
                <div class="device_type" @click="plug.device_type = 6">
                  <img
                    class="device_type-img"
                    v-show="plug.device_type == 6"
                    src="~@/assets/imgs/device/P_wall-01_on.png"
                  />
                  <img
                    class="device_type-img"
                    v-show="plug.device_type != 6"
                    src="~@/assets/imgs/device/P_wall-01_off.png"
                  />
                </div>
                <div class="device_type" @click="plug.device_type = 4">
                  <img
                    class="device_type-img"
                    v-show="plug.device_type == 4"
                    src="~@/assets/imgs/device/hallway-01_on.png"
                  />
                  <img
                    class="device_type-img"
                    v-show="plug.device_type != 4"
                    src="~@/assets/imgs/device/hallway-01_off.png"
                  />
                </div>
                <div class="device_type" @click="plug.device_type = 3">
                  <img
                    class="device_type-img"
                    v-show="plug.device_type == 3"
                    src="~@/assets/imgs/device/Craftsman Landscape Architecture-01_on.png"
                  />
                  <img
                    class="device_type-img"
                    v-show="plug.device_type != 3"
                    src="~@/assets/imgs/device/Craftsman Landscape Architecture-01_off.png"
                  />
                </div>
              </div>

              <div class="device_type-group">
                <div class="device_type" @click="plug.device_type = 1">
                  <img
                    class="device_type-img"
                    v-show="plug.device_type == 1"
                    src="~@/assets/imgs/device/community-01_on.png"
                  />
                  <img
                    class="device_type-img"
                    v-show="plug.device_type != 1"
                    src="~@/assets/imgs/device/community-01_off.png"
                  />
                </div>
                <div class="device_type" @click="plug.device_type = 2">
                  <img
                    class="device_type-img"
                    v-show="plug.device_type == 2"
                    src="~@/assets/imgs/device/cone-01_on.png"
                  />
                  <img
                    class="device_type-img"
                    v-show="plug.device_type != 2"
                    src="~@/assets/imgs/device/cone-01_off.png"
                  />
                </div>
                <div class="device_type" @click="plug.device_type = 7">
                  <img
                    class="device_type-img"
                    v-show="plug.device_type == 7"
                    src="~@/assets/imgs/device/pole-01_on.png"
                  />
                  <img
                    class="device_type-img"
                    v-show="plug.device_type != 7"
                    src="~@/assets/imgs/device/pole-01_off.png"
                  />
                </div>
                <div class="device_type" @click="plug.device_type = 8">
                  <img
                    class="device_type-img"
                    v-show="plug.device_type == 8"
                    src="~@/assets/imgs/device/table_built_in-01_on.png"
                  />
                  <img
                    class="device_type-img"
                    v-show="plug.device_type != 8"
                    src="~@/assets/imgs/device/table_built_in-01_off.png"
                  />
                </div>
              </div>
            </div>
          </div>

          <label class="control-label"
            >{{ $t("addDevice.setPlace") }}( 20자 이내 )</label
          >
          <div class="row">
            <div class="col-7">
              <base-input
                type="text"
                v-model="plug.device_location"
              ></base-input>
            </div>
          </div>

          <label class="control-label">사용 요금</label>
          <a style="color: red; font-size: 12px">&nbsp;* 필수</a>

          <div class="row timetable-div">
            <div class="col-10">
              <div style="padding-bottom: 10px">
                <span class="priceText"
                  >- 선택하신 사이트의 전력 공급 방식은 [<span
                    class="redText"
                    >{{
                      this.site_type == 1
                        ? "주택용"
                        : this.site_type == 2
                        ? "산업용"
                        : this.site_type == 3
                        ? "농사용"
                        : this.site_type == 4
                        ? "교육용"
                        : this.site_type == 5
                        ? "일반용"
                        : "OOO"
                    }}</span
                  >] 입니다.
                </span>
                <span class="priceText"
                  >- [<span class="redText">{{
                    this.site_type == 1
                      ? "주택용"
                      : this.site_type == 2
                      ? "산업용"
                      : this.site_type == 3
                      ? "농사용"
                      : this.site_type == 4
                      ? "교육용"
                      : this.site_type == 5
                      ? "일반용"
                      : "OOO"
                  }}</span
                  >]의 한국 전력 청구 요금은 최대 [<span class="redText"
                    >{{ this.site_price }}/1kWh</span
                  >]이며, 쉐어플러그는 1시간에 3kWh 출력을 기준으로 가격 책정을
                  합니다.
                </span>
                <span><br /></span>
                <span class="priceText"
                  >- (<span class="redText">중요</span>) 시간당 사용 금액은
                  "<span class="boldText">전기 원가</span>"와 "<span
                    class="boldText"
                    >부가세</span
                  >"를 포함하여 입력하시기 바랍니다.
                </span>
                <span class="priceText"
                  >- 시간당 사용 금액은 한국 전력의 예상 청구 요금과 부가세를
                  포함하여 최대 150%까지만 책정하여 입력할 수 있습니다. (원
                  단위는 절삭하였습니다)
                </span>
                <span class="priceText"
                  >- 사용 고객에게는 입력하신 "시간당 사용 금액"의 수수료 10%를
                  더한 금액으로 최종 청구됩니다. (ex 550원 입력 → 605원 청구 →
                  550원 정산)
                </span>
              </div>
              <table class="table selectTimeTable">
                <tr>
                  <th>
                    {{ $t("addDevice.allowUse") }}
                  </th>
                  <th>{{ $t("addDevice.useTime") }}</th>
                  <th>한전 청구 예상 요금</th>
                  <th>
                    {{ $t("addDevice.usePrice") }}
                    (<span class="redText"> VAT포함하여 입력 </span>)
                  </th>
                  <th>최대 입력 가능 요금 ( VAT포함 )</th>
                </tr>
                <!-- plug 사용을 중지할 수 있게 만들었던 체크벅스 -->
                <!-- <tr>
                  <td><base-checkbox name="chk_usetime" id="chk_usetime_off" v-model="plug.isUseTimeOffCheck"></base-checkbox></td>
                  <td>사용중지</td>
                </tr> -->
                <tr v-for="(item, index) in plug.useTime" :key="index">
                  <td v-if="index !== 0">
                    <input
                      type="checkbox"
                      v-if="plug.isBroken !== true"
                      v-model="item.check"
                      @change="updateCheckAll()"
                    />
                  </td>
                  <td v-else>
                    <input
                      type="checkbox"
                      class="form-check-box"
                      @click="checkAll()"
                      v-model="plug.isUseTimeOffCheck"
                    />
                  </td>
                  <td>{{ item.time }}</td>
                  <td v-if="index !== 0">{{ item.price | numCommaFilter }}</td>
                  <td v-else></td>
                  <td v-if="index !== 0">
                    <input
                      type="text"
                      v-model="item.value"
                      :disabled="item.check == false || plug.isBroken == true"
                      class="form-control"
                      oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    />
                  </td>
                  <td v-else class="noticeText">
                    ※ 원단위 금액은 절삭되어 등록됩니다. ex) 999 → 990
                  </td>

                  <td>{{ item.maxPrice | numCommaFilter }}</td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="chk_broken"
                      id="chk_broken_off"
                      v-model="plug.isBroken"
                    />
                  </td>
                  <td>{{ $t("addDevice.broken") }}</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="text-right row">
            <div class="col-12" style="margin-left: 20%">
              <button
                type="submit"
                class="btn btn-info btn-fill"
                @click.prevent="saveBtn"
              >
                {{ $t("addDevice.save") }}
              </button>
            </div>
          </div>
          <div class="clearfix"></div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";

export default {
  components: {
    Card,
    BaseInput,
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  data() {
    return {
      site_price_data: [],
      site_type: "",
      site_price: 0,
      form_type: "add", //등록인지 수정인지 구분
      showAddSiteModal: false, //사이트 추가 모달 보여줄지 여부
      showAddSectionModal: false, //  섹션 추가/수정 모달 보여줄지 여부
      selectSite: "",
      section_num: 0,
      deviceId: "",
      status: {
        plug: { message: "", style: "", complete: false },
      },
      style: {
        warn: "color: red;  font-size:12px",
        success: "color: green; font-size:12px ",
      },
      plug: {
        selectSection: "",
        device_id: "",
        siteId: "",
        site_id: "",
        serial_num: "",
        device_location: "",
        // device_name: "",
        device_type: "",
        isUseTimeOffCheck: false,
        isBroken: false,
        useTime: [
          {
            time: "",
            price: 0,
            maxPrice: "",
            realTime: "",
            check: false,
            value: "",
          },
          {
            time: "30분",
            price: 0,
            maxPrice: "~ " + 0,
            realTime: 30,
            check: false,
            value: "",
          },
          {
            time: "1시간",
            price: 0,
            maxPrice: "~ " + 0,
            realTime: 60,
            check: false,
            value: "",
          },
          {
            time: "2시간",
            price: 0,
            maxPrice: "~ " + 0,
            realTime: 120,
            check: false,
            value: "",
          },
          {
            time: "4시간",
            price: 0,
            maxPrice: "~ " + 0,
            realTime: 240,
            check: false,
            value: "",
          },
          {
            time: "6시간",
            price: 0,
            maxPrice: "~ " + 0,
            realTime: 360,
            check: false,
            value: "",
          },
          {
            time: "8시간",
            price: 0,
            maxPrice: "~ " + 0,
            realTime: 480,
            check: false,
            value: "",
          },
          {
            time: "12시간",
            price: 0,
            maxPrice: "~ " + 0,
            realTime: 720,
            check: false,
            value: "",
          },
          {
            time: "24시간",
            price: 0,
            maxPrice: "~ " + 0,
            realTime: 1440,
            check: false,
            value: "",
          },
        ],
        useTimeTax: [
          {
            value: "",
          },
          {
            value: "",
          },
          {
            value: "",
          },
          {
            value: "",
          },
          {
            value: "",
          },
          {
            value: "",
          },
          {
            value: "",
          },
          {
            value: "",
          },
          {
            value: "",
          },
        ],
      },
      pages: { page: 1, pageSize: 999 },
      section: {
        page: 1,
        pageSize: 9999,
        siteId: "",
      },
      sections: [
        {
          name: "A-0",
        },
        {
          name: "A-1",
        },
      ],
      info: {
        address: "",
        address_detail: "",
      },
      layer_pop_address: false,
      siteList: [],
      sectionList: [],
      isUseTimeOffCheck: false,
    };
  },
  watch: {
    $route(to, from) {
      Object.assign(this.$data, this.$options.data()); //데이터 리셋

      if (this.$route.params.deviceId) {
        //수정일경우
        this.form_type = "modify";
        this.getDeviceDetail(this.$route.params.deviceId);
      }
    },
    // "plug.device_name": function() {
    //   this.plug.device_name = this.plug.device_name
    //     .replace(/[^0-9|^a-z|^A-Z|^ㄱ-ㅎ|^ㅏ-ㅣ|^가-힣| ]/, "")
    //     .substring(0, 20);
    // },
    "plug.device_location": function () {
      this.plug.device_location = this.plug.device_location
        .replace(/[^0-9|^a-z|^A-Z|^ㄱ-ㅎ|^ㅏ-ㅣ|^가-힣| ]/, "")
        .substring(0, 20);
    },

    // plug.useTime: {
    //   handler: function(val, oldVal) {
    //     val.filter(function(v, i) {
    //       if(v && v.value) {
    //         v.value = v.value.replace(/[^0-9]/g, '').substring(0,10);
    //       }
    //     });
    //   },
    //   deep: true
    // },
  },

  created() {
    //초기 데이터 셋팅
    this.getSiteList();

    if (this.$route.params.deviceId) {
      //수정일경우
      this.form_type = "modify";
      this.getDeviceDetail(this.$route.params.deviceId);
    }
  },
  methods: {
    checkAll: function () {
      this.plug.isUseTimeOffCheck = !this.plug.isUseTimeOffCheck;
      if (this.plug.isUseTimeOffCheck === true) {
        console.log("asdas");
        for (var i = 1; i < this.plug.useTime.length; i++) {
          this.plug.useTime[i].check = true;
        }
      } else {
        for (var i = 1; i < this.plug.useTime.length; i++) {
          this.plug.useTime[i].check = false;
        }
      }
    },
    updateCheckAll() {
      if (
        (this.plug.useTime[1].check &&
          this.plug.useTime[2].check &&
          this.plug.useTime[3].check &&
          this.plug.useTime[4].check &&
          this.plug.useTime[5].check &&
          this.plug.useTime[6].check &&
          this.plug.useTime[7].check &&
          this.plug.useTime[8].check) === true
      ) {
        this.plug.isUseTimeOffCheck = true;
      } else {
        this.plug.isUseTimeOffCheck = false;
      }
    },
    maxLength(id, maxLen) {
      //input 텍스트박스 글자수 제한
      var inputValue = document.getElementById(id).value;

      if (maxLen == null) {
        maxLen =
          $("#" + id).attr("maxLength") != null
            ? $("#" + id).attr("maxLength")
            : 1000;
      }

      if (inputValue.length > maxLen) {
        document.getElementById(id).value = inputValue.substring(0, maxLen);
      }
    },
    async getDeviceDetail(deviceId) {
      console.log("getDeviceDetail");

      const response = await this.$store.dispatch(
        "FETCH_GET_PLUG_DETAIL",
        deviceId
      );
      console.log("response plug_detail:", response.data);

      this.plug.device_id = response.data.data.plug_uid;
      this.plug.site_id = response.data.data.site_id;
      this.plug.selectSection = response.data.data.section_id;
      this.plug.serial_num = response.data.data.plug_id;
      // this.plug.device_name = response.data.data.plug_name;
      this.plug.device_location = response.data.data.plug_location;
      this.plug.device_type = response.data.data.plug_type;
      this.site_type = response.data.data.site_type;
      this.plug.isBroken =
        response.data.data.plug_status == "BROKEN" ? true : false;
      this.plug.isUseTimeOffCheck =
        response.data.data.status == "OFF" ? true : false;
      //plug time and prices checking when it is updated
      this.plug.useTime[1].value = response.data.data.unit_price_1;
      if (response.data.data.unit_check_1 === "1")
        this.plug.useTime[1].check = true;
      if (response.data.data.unit_price_1 === -1)
        this.plug.useTime[1].value = "";

      this.plug.useTime[2].value = response.data.data.unit_price_2;
      if (response.data.data.unit_check_2 === "1")
        this.plug.useTime[2].check = true;
      if (response.data.data.unit_price_2 === -1)
        this.plug.useTime[2].value = "";

      this.plug.useTime[3].value = response.data.data.unit_price_3;
      if (response.data.data.unit_check_3 === "1")
        this.plug.useTime[3].check = true;
      if (response.data.data.unit_price_3 === -1)
        this.plug.useTime[3].value = "";

      this.plug.useTime[4].value = response.data.data.unit_price_4;
      if (response.data.data.unit_check_4 === "1")
        this.plug.useTime[4].check = true;
      if (response.data.data.unit_price_4 === -1)
        this.plug.useTime[4].value = "";

      this.plug.useTime[5].value = response.data.data.unit_price_5;
      if (response.data.data.unit_check_5 === "1")
        this.plug.useTime[5].check = true;
      if (response.data.data.unit_price_5 === -1)
        this.plug.useTime[5].value = "";

      this.plug.useTime[6].value = response.data.data.unit_price_6;
      if (response.data.data.unit_check_6 === "1")
        this.plug.useTime[6].check = true;
      if (response.data.data.unit_price_6 === -1)
        this.plug.useTime[6].value = "";

      this.plug.useTime[7].value = response.data.data.unit_price_7;
      if (response.data.data.unit_check_7 === "1")
        this.plug.useTime[7].check = true;
      if (response.data.data.unit_price_7 === -1)
        this.plug.useTime[7].value = "";

      this.plug.useTime[8].value = response.data.data.unit_price_8;
      if (response.data.data.unit_check_8 === "1")
        this.plug.useTime[8].check = true;
      if (response.data.data.unit_price_8 === -1)
        this.plug.useTime[8].value = "";

      this.selectSite = response.data.data.site_id;
      this.getSectionList();
      await this.getSitePrice();

      //사용 요금 시간의 한전 청구 예상 요금 가격
      this.plug.useTime[1].price = Math.round((this.site_price * 3) / 2);
      this.plug.useTime[2].price = Math.round(this.site_price * 3);
      this.plug.useTime[3].price = Math.round(this.site_price * 3 * 2);
      this.plug.useTime[4].price = Math.round(this.site_price * 3 * 4);
      this.plug.useTime[5].price = Math.round(this.site_price * 3 * 6);
      this.plug.useTime[6].price = Math.round(this.site_price * 3 * 8);
      this.plug.useTime[7].price = Math.round(this.site_price * 3 * 12);
      this.plug.useTime[8].price = Math.round(this.site_price * 3 * 24);
      //사용 요금 시간의 최대 입력 가능 요금
      this.plug.useTime[1].maxPrice =
        Math.floor((this.plug.useTime[1].price * 1.5) / 10) * 10;
      this.plug.useTime[2].maxPrice =
        Math.floor((this.plug.useTime[2].price * 1.5) / 10) * 10;
      this.plug.useTime[3].maxPrice =
        Math.floor((this.plug.useTime[3].price * 1.5) / 10) * 10;
      this.plug.useTime[4].maxPrice =
        Math.floor((this.plug.useTime[4].price * 1.5) / 10) * 10;
      this.plug.useTime[5].maxPrice =
        Math.floor((this.plug.useTime[5].price * 1.5) / 10) * 10;
      this.plug.useTime[6].maxPrice =
        Math.floor((this.plug.useTime[6].price * 1.5) / 10) * 10;
      this.plug.useTime[7].maxPrice =
        Math.floor((this.plug.useTime[7].price * 1.5) / 10) * 10;
      this.plug.useTime[8].maxPrice =
        Math.floor((this.plug.useTime[8].price * 1.5) / 10) * 10;
    },
    async getSiteList() {
      const response = await this.$store.dispatch(
        "FETCH_GET_SITE_LIST",
        this.pages
      );
      //사이트 리스트 불러오기
      this.siteList = response.data.data.site_list;
    },
    async getSiteType() {
      const params = new URLSearchParams();
      params.append("request", "get_site_type");
      params.append("site_id", this.selectSite);

      await this.$axios.post("", params).then((res) => {
        this.site_type = res.data.data.site_type;
      });
    },
    setSectionName() {
      this.plug.selectSection = "";
    },
    async getSectionList() {
      if (this.form_type == "add") {
        this.section.siteId = this.selectSite.site_id;
        this.site_type = this.selectSite.site_type;
      } else {
        this.section.siteId = this.selectSite;
        // this.site_type = this.selectSite.site_type;

        await this.getSiteType();
      }
      await this.getSitePrice();
      console.log(this.site_price);
      //사용 요금 시간의 한전 청구 예상 요금 가격
      this.plug.useTime[1].price = Math.round((this.site_price * 3) / 2);
      this.plug.useTime[2].price = Math.round(this.site_price * 3);
      this.plug.useTime[3].price = Math.round(this.site_price * 3 * 2);
      this.plug.useTime[4].price = Math.round(this.site_price * 3 * 4);
      this.plug.useTime[5].price = Math.round(this.site_price * 3 * 6);
      this.plug.useTime[6].price = Math.round(this.site_price * 3 * 8);
      this.plug.useTime[7].price = Math.round(this.site_price * 3 * 12);
      this.plug.useTime[8].price = Math.round(this.site_price * 3 * 24);

      //부가세 계산 추가 20230704 kimjak
      this.plug.useTimeTax[0].value = Math.floor(
        (this.plug.useTime[1].price * 1.5) / 10
      );
      this.plug.useTimeTax[1].value = Math.floor(
        (this.plug.useTime[2].price * 1.5) / 10
      );
      this.plug.useTimeTax[2].value = Math.floor(
        (this.plug.useTime[3].price * 1.5) / 10
      );
      this.plug.useTimeTax[3].value = Math.floor(
        (this.plug.useTime[4].price * 1.5) / 10
      );
      this.plug.useTimeTax[4].value = Math.floor(
        (this.plug.useTime[5].price * 1.5) / 10
      );
      this.plug.useTimeTax[5].value = Math.floor(
        (this.plug.useTime[6].price * 1.5) / 10
      );
      this.plug.useTimeTax[6].value = Math.floor(
        (this.plug.useTime[7].price * 1.5) / 10
      );
      this.plug.useTimeTax[7].value = Math.floor(
        (this.plug.useTime[8].price * 1.5) / 10
      );

      //사용 요금 시간의 최대 입력 가능 요금
      this.plug.useTime[1].maxPrice =
        Math.floor(
          (this.plug.useTime[1].price * 1.5 + this.plug.useTimeTax[0].value) /
            10
        ) * 10;
      this.plug.useTime[2].maxPrice =
        Math.floor(
          (this.plug.useTime[2].price * 1.5 + this.plug.useTimeTax[1].value) /
            10
        ) * 10;
      this.plug.useTime[3].maxPrice =
        Math.floor(
          (this.plug.useTime[3].price * 1.5 + this.plug.useTimeTax[2].value) /
            10
        ) * 10;
      this.plug.useTime[4].maxPrice =
        Math.floor(
          (this.plug.useTime[4].price * 1.5 + this.plug.useTimeTax[3].value) /
            10
        ) * 10;
      this.plug.useTime[5].maxPrice =
        Math.floor(
          (this.plug.useTime[5].price * 1.5 + this.plug.useTimeTax[4].value) /
            10
        ) * 10;
      this.plug.useTime[6].maxPrice =
        Math.floor(
          (this.plug.useTime[6].price * 1.5 + this.plug.useTimeTax[5].value) /
            10
        ) * 10;
      this.plug.useTime[7].maxPrice =
        Math.floor(
          (this.plug.useTime[7].price * 1.5 + this.plug.useTimeTax[6].value) /
            10
        ) * 10;
      this.plug.useTime[8].maxPrice =
        Math.floor(
          (this.plug.useTime[8].price * 1.5 + this.plug.useTimeTax[7].value) /
            10
        ) * 10;

      const response = await this.$store.dispatch(
        "FETCH_GET_SECTION_LIST",
        this.section
      );
      console.log("response section_list:", response.data);
      this.sectionList = response.data.data.sections;
    },
    async checkPlug() {
      if (this.plug.serial_num === "") {
        alert("플러그 ID를 입력하시고");
      } else {
        const response = await this.$store.dispatch(
          "CHECK_PLUG_ID",
          this.plug.serial_num
        );
        console.log(response);
        if (response.data.result === true) {
          this.status.plug.message = "등록이 가능한 플러그 ID입니다.";
          this.status.plug.style = this.style.success;
          this.status.plug.complete = true;
        } else if (
          response.data.result === false &&
          response.data.data.error === "PLUG_EXISTS"
        ) {
          this.status.plug.message =
            "이미 등록된 플러그 ID입니다. 플러그 ID를 확인하시기 바랍니다.";
          this.status.plug.style = this.style.warn;
          this.status.plug.complete = false;
        } else if (
          response.data.result === false &&
          response.data.data.error === "PLUG_DOESNOT_EXISTS"
        ) {
          this.status.plug.message =
            " 등록할 수 없는 플러그 ID입니다. 플러그 ID를 확인하시기 바랍니다.";
          this.status.plug.style = this.style.warn;
          this.status.plug.complete = false;
        } else if (
          response.data.result === false &&
          response.data.data.error === "INVALID_LENGTH"
        ) {
          this.status.plug.message = "시리얼 넘버 8자리를 입력하세요.";
          this.status.plug.style = this.style.warn;
          this.status.plug.complete = false;
        }
      }
    },
    checkValidate: function () {
      if (this.selectSite === "") {
        alert("설치 사이트를 선택하세요");
        return false;
      } else if (this.plug.selectSection === "") {
        alert("설치섹션을 선택하세요");
        return false;
        // } else if (this.plug.device_name === "") {
        //   alert("플러그 이름을 입력하세요");
        //   return false;
      } else if (this.plug.device_type === "") {
        alert("설치하신 플러그 위치(ex. 주차장 기둥)을 선택하세요");
        return false;
      }
      let Data = this.plug.useTime;

      for (var i = 0; i < Data.length; i++) {
        if (Data[i]["check"] === true) {
          if (Data[i]["value"] === "") {
            alert("사용요금을 입력해주세요.");
            return false;
          }
        }
      }
      return true;
      // return true;
    },
    saveBtn() {
      // alert(this.);
      let checkData = this.checkValidate();
      // validation check
      if (!checkData) {
        return false;
      }

      if (this.form_type == "modify") {
        this.updateDevice();
      } else {
        if (!this.status.plug.complete) {
          alert("중복확인을 해주세요.");
          return;
        } else {
          this.addDevice();
        }
      }
    },
    async addDevice() {
      var max_price_error = [];
      for (var i = 0; i < this.plug.useTime.length; i++) {
        if (this.plug.useTime[i].value !== "") {
          this.plug.useTime[i].value =
            Math.floor(parseInt(this.plug.useTime[i].value) / 10) * 10;
        }
        if (
          parseInt(this.plug.useTime[i].value) > this.plug.useTime[i].maxPrice
        ) {
          max_price_error.push(i);
        }
      }
      if (max_price_error.length > 0) {
        alert(
          "입력하신 사용 요금이 최대 입력 가능 요금을 넘어설 경우 저장이 되지 않습니다. 확인하여 수정하시기 바랍니다."
        );
      } else {
        //declaring site_id to add the plug
        this.plug.siteId = this.selectSite.site_id;

        const response = await this.$store.dispatch(
          "FETCH_CREATE_PLUG",
          this.plug
        );
        console.log("device response:", response.data);

        if (response.data.result === true) {
          alert("정상적으로 플러그가 등록되었습니다.");
          this.$router.push("/admin/device/list");
        } else {
          alert("error");
        }
      }
    },
    async updateDevice() {
      var max_price_error = [];
      // console.log("param data : ", this.plug);
      for (var i = 0; i < this.plug.useTime.length; i++) {
        if (this.plug.useTime[i].value !== "") {
          this.plug.useTime[i].value =
            Math.floor(parseInt(this.plug.useTime[i].value) / 10) * 10;
        }
        if (
          parseInt(this.plug.useTime[i].value) > this.plug.useTime[i].maxPrice
        ) {
          max_price_error.push(i);
        }
      }
      console.log(max_price_error);
      console.log(this.plug.useTime);

      if (max_price_error.length > 0) {
        alert(
          "입력하신 사용 요금이 최대 입력 가능 요금을 넘어설 경우 저장이 되지 않습니다. 확인하여 수정하시기 바랍니다."
        );
      } else {
        const params = new URLSearchParams();
        params.append("request", "update_plug");
        params.append("plug_uid", this.plug.device_id);
        params.append("plug_id", this.plug.serial_num);
        params.append("site_id", this.selectSite);
        params.append("section_id", this.plug.selectSection);
        // params.append("name", this.plug.device_name);
        params.append("location", this.plug.device_location);
        params.append("type", this.plug.device_type);
        params.append("unit", JSON.stringify(this.plug.useTime));
        params.append("plug_status", this.plug.isBroken);

        await this.$axios.post("", params).then((res) => {
          const data = res.data;
          console.log("data: " + JSON.stringify(data));

          alert("정상적으로 플러그가 수정되었습니다.");
          this.$router.push("/admin/device/list");
        });
      }
    },
    async getSitePrice() {
      const params = new URLSearchParams();
      params.append("request", "get_site_price");

      await this.$axios.post("", params).then((res) => {
        this.site_price_data = res.data.data[0];
      });
      console.log(this.site_price_data);
      if (this.site_type == 1) {
        this.site_price = parseFloat(this.site_price_data.house);
      } else if (this.site_type == 2) {
        this.site_price = parseFloat(this.site_price_data.industry);
        console.log(this.site_price);
      } else if (this.site_type == 3) {
        this.site_price = parseFloat(this.site_price_data.farm);
        console.log(this.site_price);
      } else if (this.site_type == 4) {
        this.site_price = parseFloat(this.site_price_data.education);
        console.log(this.site_price);
      } else if (this.site_type == 5) {
        this.site_price = parseFloat(this.site_price_data.general);
        console.log(this.site_price);
      }
    },
  },
};
</script>
<style scoped>
.noticeText {
  font-size: 13px;
  color: red;
  text-align: left;
}
.card-wrapper {
  min-width: 800px;
}

.card-wrapper form {
  width: 800px;
}

.card label {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  color: #9a9a9a;
  margin: 10px 0 5px 0;
}
.row {
  margin-bottom: 10px;
}
.form-check-box .form-check-sign::after {
  font-family: FontAwesome;
  content: "\f046";
  text-align: center;
  opacity: 0;
  color: #23ccef;
  border: 0;
  background-color: inherit;
  left: 5px;
  top: 0px;
}
.device_type-div {
  margin-top: 5px;
}
.device_type-div .device_type-group {
  margin-bottom: 4px;
  display: flex;
  width: 100%;
  overflow: hidden;
}

.device_type {
  margin-right: 4px;
}

.device_type-img {
  width: 134px;
  height: 120px;
}

.input-group-text {
  width: 35px;
}

.input-group-text span {
  text-align: center;
  margin: 0 auto;
}

.section1 {
  padding-left: 15px !important;
  padding-right: 5px !important;
}

.table {
  border: 1px solid #dee2e6;
}

.table td {
  border-top: none;
}
.form-check-box {
  margin-top: 35px;
}

.timetable-div {
  margin-top: -10px !important;
  width: 150%;
  text-align: center;
}

@media (max-width: 768px) {
  .device_type-img {
    width: 100%;
    margin-bottom: 2px;
  }
}
/* 플러그 추가 페이지  css */
.card-title {
  font-weight: 400;
  padding-top: 5px;
}
.form-group {
  margin-bottom: 0rem !important;
}
.duplicate {
  width: 70px;
  margin: 0px;
  display: block;
  font-weight: 400;
  height: 35px;
  border-radius: 4px;
  background-color: #73bce6;
  border: 1px solid #4388c1;
  color: white;
  font-size: 15px;
  padding: 0;
}
select {
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  display: block;
  width: 100%;
  height: 35px;
  font-size: 14px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background: url("~@/assets/imgs/arrow-down.png") no-repeat 95% 50%;
}

/* IE 10, 11의 네이티브 화살표 숨플러그 */
select::-ms-expand {
  display: none;
}

.button-div {
  margin: auto 0px;
}

.div0 {
  max-width: 80%;
}

.modal {
  width: 600px;
  height: 691px;
  overflow-y: auto;
  border: 1px solid;
}

.nc-icon {
  line-height: 27px;
}

/* 사용 시간 선택 테이블 */
.selectTimeTable input {
  width: 90%;
  margin-right: 10px;
}

/* .selectTimeTable th:first-child {
  width: 20%;
}
.selectTimeTable th:nth-child(2) {
  width: 30%;
}
.selectTimeTable th:nth-child(3) {
  width: 40%;
} */

.device_location {
  margin-bottom: 10px;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding: 0;
  margin-right: auto;
  margin-left: auto;
}
</style>

<style lang="scss" scoped>
.redText {
  color: red;
  font-weight: 600;
}
.boldText {
  font-weight: 600;
}
.priceText {
  font-size: 14px;
  display: block;
  text-align: left;
}
@mixin can-toggle-branding(
  $can-toggle-off-color: #777,
  $can-toggle-on-color: #42d0ed,
  $can-toggle-inactive-text: rgba(white, 0.5),
  $can-toggle-transition: cubic-bezier(0, 1, 0.5, 1)
) {
  input[type="checkbox"] {
    &[disabled] ~ label {
      color: rgba($can-toggle-off-color, 0.5);
    }

    &:focus ~ label,
    &:hover ~ label {
      .can-toggle__switch {
        background-color: $can-toggle-off-color;
        &:after {
          color: darken($can-toggle-off-color, 10%);
        }
      }
    }
    &:hover ~ label {
      color: darken($can-toggle-off-color, 5%);
    }
    &:checked {
      ~ label {
        &:hover {
          color: darken($can-toggle-on-color, 3%);
        }

        .can-toggle__switch {
          background-color: lighten($can-toggle-on-color, 5%);
          &:after {
            color: darken($can-toggle-on-color, 5%);
          }
        }
      }

      &:focus,
      &:hover {
        ~ label {
          .can-toggle__switch {
            background-color: $can-toggle-on-color;
            &:after {
              color: darken($can-toggle-on-color, 10%);
            }
          }
        }
      }
    }
  }

  label {
    .can-toggle__switch {
      transition: background-color 0.3s $can-toggle-transition;
      background: lighten($can-toggle-off-color, 5%);
      &:before {
        color: $can-toggle-inactive-text;
      }
      &:after {
        // Autoprefixer choked here, so making the prefixes explicit
        -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
        transition: transform 0.3s $can-toggle-transition;
        color: $can-toggle-off-color;
      }
    }
  }
}

@mixin can-toggle-appearance(
  $can-toggle-width: 134px,
  $can-toggle-height: 36px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 14px,
  $can-toggle-switch-font-size: 12px,
  $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
) {
  $can-toggle-switch-width: $can-toggle-width/2;

  input[type="checkbox"] {
    &:focus ~ label,
    &:hover ~ label {
      .can-toggle__switch {
        &:after {
          box-shadow: $can-toggle-shadow;
        }
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:after {
            transform: translate3d(
              $can-toggle-width -
                ($can-toggle-switch-width + $can-toggle-offset),
              0,
              0
            );
          }
        }
      }
      &:focus,
      &:hover {
        ~ label {
          .can-toggle__switch {
            &:after {
              box-shadow: $can-toggle-shadow;
            }
          }
        }
      }
    }
  }

  label {
    font-size: $can-toggle-label-font-size;

    .can-toggle__switch {
      height: $can-toggle-height;
      flex: 0 0 $can-toggle-width;
      border-radius: $can-toggle-border-radius;

      &:before {
        left: $can-toggle-width/2;
        font-size: $can-toggle-switch-font-size;
        line-height: $can-toggle-height;
        width: $can-toggle-width/2;
        padding: 0 12px;
      }

      &:after {
        top: $can-toggle-offset;
        left: $can-toggle-offset;
        border-radius: $can-toggle-border-radius/2;
        width: $can-toggle-switch-width - $can-toggle-offset;
        line-height: $can-toggle-height - ($can-toggle-offset * 2);
        font-size: $can-toggle-switch-font-size;
      }

      &:hover {
        &:after {
          box-shadow: $can-toggle-shadow;
        }
      }
    }
  }
}

.can-toggle {
  position: relative;
  margin-bottom: 15px;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  //overflow: hidden;

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &[disabled] ~ label {
      pointer-events: none;
      .can-toggle__switch {
        opacity: 0.4;
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:before {
            content: attr(data-unchecked);
            left: 0;
          }

          &:after {
            content: attr(data-checked);
          }
        }
      }

      &:focus,
      &:hover {
        ~ label {
        }
      }
    }
  }

  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;

    .can-toggle__switch {
      position: relative;

      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
      }

      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: white;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  // Default values for .can-toggle class
  @include can-toggle-branding;
  @include can-toggle-appearance;

  // &.can-toggle--size-large {
  //   @include can-toggle-appearance(
  //     160px,
  //     0px, // Toggle
  //     50px,
  //     px,  // Toggle h
  //     4px,
  //     x,   // Toggle border r
  //     2px,
  //     x,   // Offset (distance btw switch and
  //     14px,
  //     px,  // Label font
  //     14px,
  //     px,  // Switch font
  //     0 4px 4px rgba(black, 0.4) 0.4) // Switch shadow on hover/
  //   );
  // }
}
</style>
