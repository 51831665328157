<template>
  <div class="content">
    <div class="container-fluid" style="padding-top: 12vh;">
      <div class="row d-flex justify-content-center align-items-center">
        <card class="signUpDiv">
          <!-- <h4 slot="header" class="card-title text-center">아이디 찾기</h4> -->
          <div slot="header" class="joinForm-title">
            <img
              class="btn btn-default backBtn col-xs-2"
              @click="$router.go(-1)"
              src="~@/assets/imgs/return.png"
            />
            <h4 class="card-title text-center col-xs-10">비밀번호 찾기</h4>
          </div>
          <form>
            <div class="row">
              <div class="col-md-12">
                <label class="control-label"> ID</label>
                <base-input
                  type="text"
                  v-model="user.id"
                  placeholder="가입 시 입력한 ID를 입력하세요."
                >
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <label class="control-label"> 이름</label>
                <base-input
                  type="text"
                  v-model="user.name"
                  placeholder="가입 시 입력한 이름을 입력하세요."
                >
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <label class="control-label"> 핸드폰 번호</label>
                <base-input
                  type="text"
                  placeholder="가입 시 입력한 핸드폰 번호를 입력하세요."
                  v-model="user.phone"
                  maxlength="11"
                  oninput="this.value = this.value.replace(/[^0-9.]/g,
                '').replace(/(\..*)\./g, '$1');"
                >
                </base-input>
              </div>
            </div>

          <div class="row">
            <div class="col-md-12" style="font-size: 14px; padding-left: 40px; margin-bottom: 40px; color: grey;">
              <span> - 올바른 정보(ID, 이름, 핸드폰 번호)가 입력되어야만 임시 PW가 발송됩니다.</br></span>
              <span> - 본인의 ID(메일)로 임시 PW가 메일 발송됩니다. </br></span>
              <span> - 발송 이후에는 임시 PW로만 로그인이 됩니다. 메일 계정에 접근이 가능한지 먼저 확인하시기 바랍니다. </br></span>
              <span> - 임시 PW로 로그인한 후 ‘설정’에서 새로운 PW로 변경하시기 바랍니다.</span>
            </div>
          </div>

        <div class="row">
          <div class="col-md-12 text-center">
            <span class="idFindText">{{this.noticeText}}</span>
            <button type="button" class="btn btn-info btn-fill loginBtn" @keyup="enterkey()" @click="findPswd()">
              임시 PW 받기
            </button>
          </div>
        </div>

            <div class="clearfix"></div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";

export default {
  components: {
    Card,
    BaseInput
  },
  data() {
    return {
      user: {
        id: "",
        phone: "",
        name: ""
      },
      noticeText: "",
      isautoLogin: false //자동로그인 여부
    };
  },
  created() {
    if (localStorage.getItem("token") || !!this.$store.getters.isLoggedIn) {
      this.$router.push("/admin/overview");
    }
  },
  methods: {
    enterkey() {
      if (window.event.keyCode == 13) {
        this.findPswd();
      }
    },
    async findPswd() {     
      if(!this.user.id || !this.user.name || !this.user.phone) {
        this.noticeText = "정보를 정확하게 입력하시기 바랍니다.";
        return;
      }
      if(confirm(this.user.id + "으로 임시 PW를 발송하시겠습니까? \n  \n발송을 진행하면 발송한 임시 PW로 바로 변경이 되며, 발송된 임시 PW로만 로그인이 가능합니다.")){

        const response = await this.$store.dispatch("REQUEST_PASSWORD_CHANGE", this.user);
        console.log(response);

        if(response.data.result === true) {
          alert("해당 ID 메일 주소로 임시 PW가 발송되었습니다. \n \n 메일에서 임시 PW를 확인하시고, 로그인 후 새로운 PW로 변경하시기 바랍니다.");
          this.$router.push("/login");
        }else if(response.data.result === false){
          //아이디가 틀린 경우
          this.noticeText = '해당 정보로 가입된 아이디는 없습니다.';
        }else {
          //아이디는 맞는데 다른 정보를 잘못 입력한 경우
          this.noticeText = "정확하게 정보를 입력하시기 바랍니다.";
        }
      }
    }
  }
};
</script>
<style scoped>
  .idFindText {
    color: red;
    font-size: 17px;
  }
  .card-title {
    margin: 40px 0;
  }
  .signUpDiv {
    width: 700px;
  }

.loginBtn {
  width: 100%;
  margin-top: 10px;
}

.form-check .form-check-sign::before,
.form-check .form-check-sign::after {
  margin-top: 0px;
}

.custom-control-label {
  font-size: 16px;
}

.loginNoticeText {
  color: red;
}

@media (max-width: 768px) {
  .signUpDiv {
    padding: 0;
  }
}

.joinText {
  margin-right: 20px;
}

.idpwRow {
  color: #c2bbbb;
  margin: 10px 0 25px 0;
}

.idpwRow .idpwRowHipen {
  margin: 0 30px;
}

/* 여기서부터 */
.backBtn {
  position: absolute;
  left: 15px;
  height: 35px;
  padding: 5px 10px;
}
</style>
