<template>
  <div>
    <!-- 검색 영역 -->
    <div class="div1">
      <p class="p1">시작일</p>
      <base-select-search
        type="text"
        class="text-center selectInput startday"
        :items="searchDatePropList"
        v-model="search.searchDateProp"
      ></base-select-search>
      <!-- <date-picker
        placeholder="날짜"
        v-model="search.sDate"
        class="datepic1"
      ></date-picker>
      <date-picker
        placeholder="날짜"
        v-model="search.eDate"
        class="datepic2"
      ></date-picker> -->
      <div>
        <input
          type="date"
          id="datefield"
          v-model="search.sDate"
          min="2021-01-01"
          max="2050-13-13"
          class="datepic1"
        />
      </div>
      <div>
        <input
          type="date"
          id="datefield"
          v-model="search.eDate"
          min="2021-01-01"
          max="2050-13-13"
          class="datepic2"
        />
      </div>
    </div>

    <div class="div2">
      <p class="p3">사이트명</p>
      <div class="col-sm-2">
        <base-select-search
          type="text"
          class="text-center selectInput sub"
          :items="siteList"
          title="전체"
          v-model="search.site"
        ></base-select-search>
      </div>

      <p class="p2">검색</p>
      <div class="col-sm-2">
        <base-input-search
          type="text"
          class="text-center selectInput sub2"
          placeholder="플러그 ID, 휴대폰번호"
          v-model="search.searchText"
          @keypress="enterkey()"
        ></base-input-search>
      </div>
      <button
        type="submit"
        id="searchBtn"
        class="btn btn-info btn-fill float-right col-4 seabtn"
        @click="searchUsing()"
      >
        {{ $t("search") }}
      </button>
    </div>
    <!-- 검색 영역 END-->

    <div v-if="searchUseList.length > 0">
      <div class="pc-table">
        <div class="div-table">
          <div class="div-row">
            <div class="div-cell" v-for="column in tableColumns" :key="column">
              <span
                v-if="
                  column.substr(0, 3) == 'btn' || column.substr(0, 3) == 'adm'
                "
              ></span>
              <span v-else>
                {{ $t(column) }}
              </span>
            </div>
          </div>
          <div
            class="div-row"
            v-for="(item, index) in searchUseList"
            :key="index"
          >
            <div class="div-cell" v-for="column in tableColumns" :key="column">
              <span v-if="hasValue(item, column) && column == 'otpissue'">
                <button
                  v-if="itemValue(item, 'time') !== 22200000000000"
                  class="issueOTP"
                  @click="otpIssue(index)"
                >
                  {{ $t("otpissue") }}
                </button>
                <button
                  v-if="itemValue(item, 'time') == 22200000000000"
                  class="unlimitCancel"
                  @click="unlimitCancel(index)"
                >
                  {{ $t("unlimit-cancel") }}
                </button>
              </span>
              <div v-else-if="column == 'status'">
                <span
                  v-if="itemValue(item, 'status') == 'Available'"
                  class="status_notuse"
                  >{{ $t("all_status.notuse") }}</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'ACTIVE'"
                  class="status_use"
                  >{{ $t("all_status.use") }}</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'Wait'"
                  class="status_wait"
                  >{{ $t("all_status.wait") }}</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'OTP_ACTIVE'"
                  class="status_use"
                  >{{ $t("all_status.use") }}</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'UNLIMIT'"
                  class="status_use"
                  >{{ $t("all_status.use") }}</span
                >
              </div>
              <div v-else-if="hasValue(item, column) && column == 'plug_id'">
                <!-- <a
                  class="pointer link"
                  @click="linkDeviceDetail(item.plug_record_id)"
                  >{{ itemValue(item, column).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ") }}</a
                > -->
                <span
                  class="status_use"
                  v-if="itemValue(item, column) !== null"
                  >{{
                    itemValue(item, column)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                  }}</span
                >
                <span
                  class="status_use"
                  v-else-if="itemValue(item, column) === null"
                  >정기권
                </span>
              </div>
              <div v-else-if="hasValue(item, column) && column == 'charge'">
                {{ itemValue(item, column) | numCommaFilter }}
              </div>
              <div v-else>
                {{ itemValue(item, column) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-cover">
        <ul class="pagination pagination-no-border">
          <li class="page-item prev-page">
            <button
              aria-label="Previous"
              @click="searchUsing(pageNum - 1)"
              v-bind:class="{ 'page-link': true, disabled: pageNum <= 1 }"
            >
              <span>&lt;&lt;</span>
            </button>
          </li>
          <li
            class="page-item"
            v-for="(item, index) in findPageCount"
            :key="item"
            :class="{ active: pageNum == index + 1 }"
          >
            <a class="page-link" @click="searchUsing(index + 1)">{{
              index + 1
            }}</a>
          </li>
          <li class="page-item page-pre next-page">
            <button
              @click="searchUsing(pageNum + 1)"
              v-bind:class="{
                'page-link': true,
                disabled: pageNum >= findPageCount,
              }"
            >
              <span>&gt;&gt;</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div v-else-if="useList.length > 0">
      <div class="pc-table">
        <div class="div-table">
          <div class="div-row">
            <div class="div-cell" v-for="column in tableColumns" :key="column">
              <span
                v-if="
                  column.substr(0, 3) == 'btn' || column.substr(0, 3) == 'adm'
                "
              ></span>
              <span v-else>
                {{ $t(column) }}
              </span>
            </div>
          </div>
          <div class="div-row" v-for="(item, index) in useList" :key="index">
            <div class="div-cell" v-for="column in tableColumns" :key="column">
              <span v-if="hasValue(item, column) && column == 'otpissue'">
                <button
                  v-if="itemValue(item, 'time') !== 22200000000000"
                  class="issueOTP"
                  @click="otpIssue(index)"
                >
                  {{ $t("otpissue") }}
                </button>
                <button
                  v-if="itemValue(item, 'time') == 22200000000000"
                  class="unlimitCancel"
                  @click="unlimitCancel(index)"
                >
                  {{ $t("unlimit-cancel") }}
                </button>
              </span>
              <div v-else-if="column == 'status'">
                <span
                  v-if="itemValue(item, 'status') == 'Available'"
                  class="status_notuse"
                  >{{ $t("all_status.notuse") }}</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'ACTIVE'"
                  class="status_use"
                  >{{ $t("all_status.use") }}</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'Wait'"
                  class="status_wait"
                  >{{ $t("all_status.wait") }}</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'OTP_ACTIVE'"
                  class="status_use"
                  >{{ $t("all_status.use") }}</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'UNLIMIT'"
                  class="status_use"
                  >{{ $t("all_status.use") }}</span
                >
              </div>
              <div v-else-if="hasValue(item, column) && column == 'plug_id'">
                <!-- <a
                  class="pointer link"
                  @click="linkDeviceDetail(item.plug_record_id)"
                  >{{ itemValue(item, column).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ") }}</a
                > -->
                <span
                  class="status_use"
                  v-if="itemValue(item, column) !== null"
                  >{{
                    itemValue(item, column)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                  }}</span
                >
                <span
                  class="status_use"
                  v-else-if="itemValue(item, column) === null"
                  >정기권
                </span>
              </div>
              <div v-else-if="hasValue(item, column) && column == 'charge'">
                {{ itemValue(item, column) | numCommaFilter }}
              </div>
              <div v-else>
                {{ itemValue(item, column) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-table">
        <div class="card" v-for="(item, index) in useList" :key="index">
          <!-- <div class="div-more"><img src="~@/assets/imgs/icon-more.png"/></div> -->
          <base-dropdown class="div-more">
            <template slot="title">
              <img src="~@/assets/imgs/icon-more.png" />
            </template>
            <a class="dropdown-item" @click="otpIssue">{{ $t("otpissue") }}</a>
          </base-dropdown>
          <div class="card-body">
            <div class="row-title">{{ itemValue(item, "site_id") }}</div>
            <div class="row-title">{{ itemValue(item, "plug_id") }}</div>
            <div class="">{{ itemValue(item, "mobile_number") }}</div>
            <div class="userNumber">{{ itemValue(item, "userNumber") }}</div>
            <div class="status">
              <span
                v-if="itemValue(item, 'status') == 'Available'"
                class="status_notuse"
              >
                대기중</span
              >
              <span
                v-else-if="itemValue(item, 'status') == 'ACTIVE'"
                class="status_use"
              >
                사용중</span
              >
              <span
                v-else-if="itemValue(item, 'status') == 'NORMAL'"
                class="status_wait"
              >
                대기중</span
              >
            </div>
            <div class="clear">{{ itemValue(item, "date") }}</div>
            <div>{{ itemValue(item, "price") }} 원</div>
            <!-- <div>  {{ itemValue(item, 'startTime') }}</div>                        
                      <div>  {{ itemValue(item, 'endTime') }}</div>                        
                      <div>  {{ itemValue(item, 'time') }}</div>     -->
            <div>
              {{ itemValue(item, "startTime") }} ~
              {{ itemValue(item, "endTime") }} ({{ itemValue(item, "time") }})
            </div>
          </div>
        </div>
      </div>
      <div class="btn-cover">
        <ul class="pagination pagination-no-border">
          <li class="page-item prev-page">
            <button
              aria-label="Previous"
              @click="getList(pageNum - 1)"
              v-bind:class="{ 'page-link': true, disabled: pageNum <= 1 }"
            >
              <span>&lt;&lt;</span>
            </button>
          </li>
          <li
            class="page-item"
            v-for="(item, index) in pageCount"
            :key="item"
            :class="{ active: pageNum == index + 1 }"
          >
            <a class="page-link" @click="getList(index + 1)">{{ index + 1 }}</a>
          </li>
          <li class="page-item page-pre next-page">
            <button
              @click="getList(pageNum + 1)"
              v-bind:class="{
                'page-link': true,
                disabled: pageNum >= pageCount,
              }"
            >
              <span>&gt;&gt;</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <div class="emptyContent">표시할 내역이 없습니다.</div>
    </div>
    <otp-check-modal
      v-if="showOtpCheckModal"
      @close="showOtpCheckModal = false"
      :serialNumber="serialNumber"
    ></otp-check-modal>
    <otp-issue-modal
      v-if="showOtpIssueModal"
      @close="showOtpIssueModal = false"
      :serialNumber="serialNumber"
      :plug_name="plug_name"
      :lastMobile="lastMobile"
    ></otp-issue-modal>
  </div>
</template>

<script>
const tableColumns = [
  "siteName",
  "plug_id",
  "mobile_number",
  "status",
  "charge",
  "startTime",
  "endTime",
  "usetime",
  "otpissue",
];
import OtpCheckModal from "src/components/Modals/OtpCheckModal.vue";
import OtpIssueModal from "src/components/Modals/OtpIssueModal.vue";
import "vue2-datepicker/index.css";
// npm install dayjs --save
import dayjs from "dayjs";

export default {
  name: "using-table",
  components: {
    OtpCheckModal,
    OtpIssueModal,
  },
  data() {
    return {
      dateFrom: dayjs().subtract(1, "year").add(1, "day").format("YYYY-MM-DD"),
      dateTo: dayjs().format("YYYY-MM-DD"),
      i: 15,
      pages: { page: 1, pageSize: 15 },
      paginatedDate: [],
      maxLine: 30,
      moreLine: 5,
      listSize: 15,
      useList: [],
      searchUseList: [],
      pageArray: [],
      pageNum: 0,
      pageSize: 15,
      tableColumns: tableColumns,
      totalNum: 0,
      showOtpCheckModal: false,
      showOtpIssueModal: false,
      serialNumber: "",
      lastMobile: "",
      phoneNum: "",
      start: "",
      plug_record_id: "",
      usedTime: "",
      //검색에 쓰일 리스트
      searchDatePropList: [
        { id: "startDate", name: "시작일" },
        { id: "endDate", name: "종료일" },
      ],
      siteList: [],
      search: {
        searchDateProp: "",
        sDate: dayjs().subtract(1, "year").add(1, "day").format("YYYY-MM-DD"),
        eDate: dayjs().format("YYYY-MM-DD"),
        site: "",
        searchText: "",
      },
    };
  },
  props: {
    listArray: {
      type: Array,
      required: false,
    },
    item: {
      type: Object,
    },
    column: {
      type: String,
    },
    filterStatus: {
      type: String,
    },
  },
  created() {
    //검색창 초기 데이터 셋팅
    this.getSiteList();

    //초기 데이터 셋팅
    this.getList();
  },
  watch: {
    filterStatus: function () {
      this.getList(1);
    },
  },
  computed: {
    pageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
    findPageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
  },
  methods: {
    enterkey() {
      if (window.event.keyCode == 13) {
        this.searchUsing();
      }
    },
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column];
    },
    otpIssue(index) {
      console.log("jak", this.useList);
      this.serialNumber = this.useList[index].plug_id;
      this.plug_name = this.useList[index].plug_name;
      this.phoneNum = this.useList[index].mobile_number;
      var thisnum = this.phoneNum.toString();
      this.lastMobile = [
        thisnum.slice(0, 3),
        "-",
        thisnum.slice(3, 7),
        "-",
        thisnum.slice(7),
      ].join("");
      if (this.useList[index].otpissue === 1) {
        this.showOtpIssueModal = true;
      } else {
        alert(
          '현재 사이트 상태가 "사용 안함" 이기 때문에 OTP 발급이 불가합니다. "사용중"으로 변경하시거나,  "사용 안함 시에도  OTP 발급 가능" 옵션을 설정하시기 바랍니다'
        );
      }
    },
    async unlimitCancel(index) {
      this.serialnumber = this.useList[index].plug_id;
      this.start = this.useList[index].start;
      this.plug_record_id = this.useList[index].plug_record_id;
      var unlimit_data = {
        plug_id: this.serialnumber,
        start_time: this.start,
        plug_rec_id: this.plug_record_id,
      };
      const response = await this.$store.dispatch(
        "UNLIMIT_CANCEL",
        unlimit_data
      );
      console.log("unlimit-cancel: ", response);
      alert(this.$i18n.t("msg.success.undo"));
    },
    async getList(page) {
      // 검색 조건 유효성 체크
      var valid = this.checkSearchValid();
      if (!valid) {
        return;
      }

      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;
      const response = await this.$store.dispatch(
        "FETCH_GET_PLUG_RECORD_LIST",
        this.pages
      );
      console.log("response plug_record_list: ", response.data);

      this.useList = [];
      var list = response.data.data.plug_record_list;
      this.totalNum = response.data.data.total;
      for (var item of list) {
        var temp = {
          plug_record_id: item.plug_record_id,
          mobile_number: item.mobile_number,
          provider_id: item.provider_id,
          provider_name: item.provider_name,
          site_id: item.site_id,
          siteName: item.site_name,
          section_id: item.section_id,
          section_name: item.section_name,
          plug_id: item.plug_id,
          plug_name: item.plug_name,
          unit_price: item.unit_price,
          charge:
            item.pay_type === "otp"
              ? "관리자"
              : item.pay_type === "sub"
              ? "정기권"
              : item.charge,
          // charge: item.charge == -2 ? 'Provider': item.charge == -1 ? "Season Ticket" : item.charge + '$',
          status: item.status,
          startTime: this.$getDateYYYYMMDD(item.time_from),
          time: item.time_to,
          endTime:
            this.$getDateYYYYMMDD(item.time_to) == "2220-00-00 00:00"
              ? "-"
              : this.$getDateYYYYMMDD(item.time_to),
          date: this.$getDateYYYYMMDD(item.time_from),
          usetime: this.$getDuringTime(item.time_from, item.time_to),
          otpissue: item.otp == "otp_use" ? 1 : 0,
        };
        this.useList.push(temp);
      }
    },
    async getSiteList() {
      //사이트 리스트 불러오기
      const response = await this.$store.dispatch(
        "FETCH_GET_SITE_LIST",
        this.pages
      );

      //데이터 셋팅
      var list = response.data.data.site_list;
      for (var item of list) {
        var temp = {
          id: item.site_id,
          name: item.name,
        };
        this.siteList.push(temp);
      }
    },

    async searchUsing(page) {
      //시작일, 종료일 유효성 체크

      if (this.search.searchDateProp === "") {
        this.search.searchDateProp = "startDate";
      }

      if (this.search.site === "") {
        this.search.site = "all";
      }
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;

      var data = {};
      data.id = this.search.searchDateProp;
      data.dateFrom = this.search.sDate;
      data.dateTo = this.search.eDate;
      data.site = this.search.site;
      data.searchValue = this.search.searchText;
      data.page = this.pages.page;
      data.lines = this.pages.pageSize;

      const response = await this.$store.dispatch("SEARCH_USING", data);
      console.log(response);
      this.searchUseList = [];
      var list = response.data.data.find_record_list;
      this.totalNum = response.data.data.total;
      if (list.length === 0) {
        this.searchUseList = [];
        this.useList = [];
      } else {
        for (var item of list) {
          var temp = {
            plug_record_id: item.plug_record_id,
            mobile_number: item.mobile_number,
            provider_id: item.provider_id,
            provider_name: item.provider_name,
            site_id: item.site_id,
            siteName: item.site_name,
            section_id: item.section_id,
            section_name: item.section_name,
            plug_id: item.plug_id,
            plug_name: item.plug_name,
            unit_price: item.unit_price,
            charge:
              item.pay_type === "otp"
                ? "관리자"
                : item.pay_type === "sub"
                ? "정기권"
                : item.charge,
            // charge: item.charge == -2 ? 'Provider': item.charge == -1 ? "Season Ticket" : item.charge + '$',
            status: item.status,
            startTime: this.$getDateYYYYMMDD(item.time_from),
            time: item.time_to,
            endTime:
              this.$getDateYYYYMMDD(item.time_to) == "2220-00-00 00:00"
                ? "-"
                : this.$getDateYYYYMMDD(item.time_to),
            date: this.$getDateYYYYMMDD(item.time_from),
            usetime: this.$getDuringTime(item.time_from, item.time_to),
            otpissue: item.otp == "otp_use" ? 1 : 0,
          };
          this.searchUseList.push(temp);
        }
      }

      //데이터 셋팅
    },
    checkSearchValid() {
      //시작일, 종료일 유효성 체크
      if (this.search.sDate > this.search.eDate) {
        alert("날짜의 입력 범위를 올바르게 입력해주세요.");
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>
h1 {
  color: #454545;
  text-align: center;
}

/* 버튼 */
.adminIssue {
  background: red;
  color: white;
  word-break: keep-all;
}
/*mobile-table css */
.mobile-table .row-title {
  font-size: 25px;
  font-weight: bold;
  width: 70%;
}

.mobile-table .status {
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 18px;
  right: 60px;
  float: right;
}

.col-sm-2 {
  margin: 0 0 0 0;
}
.div1 {
  width: 400px;
  position: absolute;
  margin-top: 0px;
}
.div2 {
  padding-top: 60px;
  width: 600px;
  float: left;
}
p {
  width: 100px;
  margin: 10px 0 0 20px;
  font-weight: bold;
  position: absolute;
}
/* 사작일 */
.startday {
  margin-left: 135px;
}

/* 날짜 */
.datepic1 {
  height: 31px;
  margin-top: -47px;
  margin-left: 310px;
  position: absolute;
  border-radius: 4px;
  border: 1px solid #4388c1;
  padding-left: 10px;
}
.datepic2 {
  height: 31px;
  margin-top: -47px;
  margin-left: 495px;
  position: absolute;
  border-radius: 4px;
  border: 1px solid #4388c1;
  padding-left: 10px;
}
/* 사이트명 */
.sub {
  margin: 0 0 0 120px;
}

/* 사용상태 */
.sub1 {
  margin: -48px 0 0 590px;
}
.p1 {
  margin: 5px 0 0 19px;
}

/*검색  */
.sub2 {
  margin: -55px 0 0 350px;
}
.p2 {
  margin: -38px 0 0 313px;
}
.p3 {
  margin: 16px 0 0 20px;
}

/* 검색 버튼 */
.seabtn {
  width: 70px;
  font-weight: 400;
  border-radius: 4px;
  background-color: #73bce6;
  border: 1px solid #4388c1;
  color: white;
  height: 30px;
  font-size: 13px;
  padding: 0;
  margin: -67px -70px 20px 0;
}

.emptyContent {
  padding-top: 200px;
}
</style>
