<template>
  <table class="table" id="tickets">
    <thead>
      <slot name="columns">
        <tr>
          <th v-for="column in columns" :key="column">{{ $t(column) }}</th>
          <!-- <th>{{$t("hello")}}</th> -->
        </tr>
      </slot>
    </thead>
    <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item">
          <td
            v-for="column in columns"
            :key="column"
            v-if="hasValue(item, column)">
            {{ itemValue(item, column) }}
          </td>
        </slot>
      </tr>
    </tbody>
  </table>

   <!-- <table id="tickets" class="table">
	<tr>
		<th nowrap>입장권</th>
		<td>테마파크만 입장하고 놀이기구를 이용하지 않을 경우 최적입니다.</td>
		<td nowrap>20,000원</td>
	</tr>
	<tr>
		<th nowrap>자유이용권</th>
		<td>폐장시간까지 모든 기구를 자유롭게 이용합니다.</td>
		<td nowrap>40,000원</td>
	</tr>
	<tr>
		<th nowrap>야간 자유이용권</th>
		<td>오후 5시 이후 입장해 폐장시간까지 모든 기구를 자유롭게 이용합니다.</td>
		<td nowrap>30,000원</td>
	</tr>
</table> -->
</template>
<script>
export default {
  name: "l-table",
  props: {
    columns: Array,
    data: Array
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    }
  }
};
</script>
<style>
@media( max-width: 768px ) {
  
	#tickets thead {
    display: none;
  }

	#tickets,
	#tickets tbody,
	#tickets tr,
	#tickets th,
	#tickets td {
		display: block;
	}

	#tickets tr {
		border-bottom: 1px solid #ddd;
	}

	#tickets th,
	#tickets td {
		border-top: none;
		border-bottom: none;
	}
}
</style>
