import { render, staticRenderFns } from "./BillingList.vue?vue&type=template&id=38572f45&scoped=true&"
import script from "./BillingList.vue?vue&type=script&lang=js&"
export * from "./BillingList.vue?vue&type=script&lang=js&"
import style0 from "./BillingList.vue?vue&type=style&index=0&id=38572f45&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38572f45",
  null
  
)

export default component.exports