<template>
  <div class="content">
    <div class="container-fluid" style="padding-top: 12vh;">
      <div class="row d-flex justify-content-center align-items-center">
        <card class="signUpDiv">
          <h4 slot="header" class="card-title text-center">LOGIN</h4>
          <form>
            <div class="row">
              <div class="col-md-12">
                <label class="control-label"> 아이디</label>
                <base-input
                  type="text"
                  v-model="login.email"
                  @keyup="enterkey()"
                  placeholder="메일 형식의 ID를 입력해 주세요."
                >
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <label class="control-label"> 비밀번호</label>
                <base-input
                  type="password"
                  @keyup="enterkey()"
                  placeholder="비밀번호를 입력해주세요."
                  v-model="login.password"
                >
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 text-center" style="margin-bottom: 30px;">
                <!-- <label class="control-label"> 자동 로그인</label> -->
                <div class="form-check" style="float:left;" @click="checked()">
                  <base-checkbox
                    name="autoLogin"
                    id="autoLogin"
                    v-model="login.autologin"
                    text="아이디 기억하기"
                  ></base-checkbox>
                </div>

                <div class="text-center" style="float:right;">
                  <a
                    class="control-label joinText pointer"
                    @click="moveSignupPage"
                  >
                    회원가입</a
                  >
                  <!-- <a class="control-label" href="#"> id/pw 찾기</a> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center idpwRow">
                <router-link to="/find/id">아이디 찾기 </router-link>
                <span class="idpwRowHipen">|</span>
                <router-link to="/find/pswd">비밀번호 찾기</router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <span class="loginNoticeText">{{ this.loginNoticeText }}</span>
                <button
                  type="button"
                  class="btn btn-info btn-fill loginBtn"
                  @click="userLogin()"
                >
                  로그인
                </button>
              </div>
            </div>

            <div class="clearfix"></div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import VueCookies from "vue-cookies";

export default {
  components: {
    Card,
    BaseInput
  },
  data() {
    return {
      login: {
        email: this.$store.state.user_email,
        password: "",
        autologin: false
      },
      loginNoticeText: ""
      //자동로그인 여부
    };
  },
  async created() {
    if (this.$store.state.isLogin === true) {
      //기본 URL 입력시 무조건 로그인페이지로 이동하는 형태로 작업되어있어서 로그인페이지에서 이동처리
      this.$router.push("/admin/overview");
      return false;
    }
    this.$store.state.isLogin = false;
    //need to continue doing here
    // if (VueCookies.get("id_remember") === "true") {
    //   login.email = "abcabc";
    //   const a = await this.$store.dispatch("GET_PROVIDER_DETAIL");
    //   sessionStorage.setItem("token", true);
    //   sessionStorage.setItem("userprofile", a);
    //   sessionStorage.setItem("login", true);
    //   // this.$router.push("/admin/overview");
    //   window.location.href = "/admin/overview";
    // }
    if (VueCookies.get("id_remember") === "true") {
      this.login.email = VueCookies.get("emailCookie"); //230117 jak 추가 쿠키에서 받은 ID 자동입력
      this.login.autologin = true; //230117 jak 추가 쿠키에 체크박스 상태 변경
      const a = await this.$store.dispatch("GET_PROVIDER_DETAIL");
      sessionStorage.setItem("token", true);
      sessionStorage.setItem("userprofile", a);
      sessionStorage.setItem("login", true);
      // this.$router.push("/admin/overview");
      // window.location.href = "/admin/overview";
    }
  },
  methods: {
    checked() {
      VueCookies.set("id_remember", false, "7d");
      if (this.login.autologin === false) {
        VueCookies.set("id_remember", true, "7d");
      }
    },
    enterkey() {
      //엔터키 입력 시 로그인
      if (window.event.keyCode == 13) {
        if (!this.login.email) {
          this.loginNoticeText = "아이디를 입력해주세요.";
        } else if (!this.login.password) {
          this.loginNoticeText = "비밀번호를 입력해주세요.";
        } else {
          this.userLogin();
        }
      }
    },
    async userLogin() {
      const response = await this.$store.dispatch("LOGIN_PROVIDER", this.login);

      if (response.data.result) {
        // 로그인 성공
        VueCookies.set("emailCookie", this.login.email, "7d");
        const a = await this.$store.dispatch("GET_PROVIDER_DETAIL");
        sessionStorage.setItem("userprofile", a);
        sessionStorage.setItem("login", true);
        sessionStorage.setItem("token", true);
        // this.$router.push("/admin/overview");
        window.location.href = "/admin/overview";
      } else {
        alert("로그인에 실패했습니다. 이메일 혹은 비밀번호가 틀립니다.");
      }
    },
    moveSignupPage() {
      this.$router.push("/signup");
    }
  }
};
</script>
<style scoped>
.card-title {
  margin: 40px 0;
  font-weight: bold;
  font-size: 40px;
}
.signUpDiv {
  width: 700px;
}

.loginBtn {
  width: 100%;
  margin-top: 10px;
  background-color: #42d0ed;
  height: 40px;
  border: 0;
}

.form-check .form-check-sign::before,
.form-check .form-check-sign::after {
  margin-top: 0px;
}

.custom-control-label {
  font-size: 16px;
}

.loginNoticeText {
  color: red;
}

@media (max-width: 768px) {
  .signUpDiv {
    padding: 0;
  }
}

/* 여기서부터 */
.joinText {
  margin-right: 20px;
}

.idpwRow {
  color: #c2bbbb;
  margin: 10px 0 25px 0;
}

.idpwRow .idpwRowHipen {
  margin: 0 30px;
}
</style>
