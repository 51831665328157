<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card
            class="strpied-tabled-with-hover"
            body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title">{{ $t("useStatus") }}</h4>
            </template>
            <!-- <l-table class="table-hover table-striped"
                     :columns="table1.columns"
                     :data="table1.data">
            </l-table> -->
            <dashboard-device-table
              class="table-hover table-striped"
              p_status="using"
            ></dashboard-device-table>
          </card>
        </div>
      </div>
      <!-- //row -->

      <div class="row">
        <div class="col-12">
          <card
            class="strpied-tabled-with-hover"
            body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title">{{ $t("waitStatus") }}</h4>
            </template>
            <!-- <l-table class="table-hover table-striped"
                     :columns="table1.columns"
                     :data="table1.data">
            </l-table> -->
            <dashboard-device-table
              class="table-hover table-striped"
              p_status="Available"
            ></dashboard-device-table>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartCard from "src/components/Cards/ChartCard.vue";
import StatsCard from "src/components/Cards/StatsCard.vue";
import LTable from "src/components/Table.vue";
import DashboardDeviceTable from "src/components/Table/DashboardDeviceTable.vue";
import Card from "src/components/Cards/Card.vue";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "App",

  components: {
    // LTable,
    DashboardDeviceTable,
    // ChartCard,
    // StatsCard,
    // Hooper,
    // Slide,
    Card,
  },
  methods: {},
  data() {
    return {
      choiceGraph: 0,
      editTooltip: "Edit Task",
      deleteTooltip: "Remove",
      p_status: "Wait",
      pieChart: {
        data: {
          labels: ["31.8%", "27.5%", "40.7%"],
          series: [31.8, 27.5, 40.7],
        },
      },
      lineChart: {
        data: {
          labels: ["9", "12", "15", "18", "21", "00", "03", "06"],
          series: [
            [287, 385, 490, 492, 554, 586, 698, 695],
            [67, 152, 143, 240, 287, 335, 435, 437],
            [23, 113, 67, 108, 190, 239, 307, 308],
          ],
        },
        options: {
          low: 0,
          high: 800,
          showArea: false,
          height: "245px",
          axisX: {
            showGrid: false,
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 50,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      barChart: {
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mai",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
            [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695],
          ],
        },
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: false,
          },
          height: "245px",
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      tableData: {
        data: [
          {
            title:
              'Sign contract for "What are conference organizers afraid of?"',
            checked: false,
          },
          {
            title:
              "Lines From Great Russian Literature? Or E-mails From My Boss?",
            checked: true,
          },
          {
            title:
              "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            checked: true,
          },
          {
            title: "Create 4 Invisible User Experiences you Never Knew About",
            checked: false,
          },
          { title: 'Read "Following makes Medium better"', checked: false },
          { title: "Unfollow 5 enemies from twitter", checked: false },
        ],
      },
      // table1: {
      //   columns: [...tableColumns],
      //   data: [...tableData]
      // },
      // table2: {
      //   columns: [...tableColumns],
      //   data: [...tableData]
      // }
    };
  },
};
</script>
<style scoped>
.card-title {
  padding-top: 5px;
  font-weight: 400;
}
.table {
  margin-bottom: 0px;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 590px;
  height: 50px;
  margin: auto;
  position: relative;
  background-color: #2c3e50;
  z-index: 7;
  display: flex;

  /* margin: 27px auto 0;
	position: relative;
	width: 590px;
	background-color: #34495e;
	border-radius: 8px;
	font-size: 0; */
}
.menu li {
  float: left;
  width: 25%;
  height: 100%;
  margin: 0;
  flex: 1;
  text-align: center;
  color: white;
  padding: 10px 0;
  /* line-height: 50px;
	height: 100%;
	font-size: 15px;
	display: inline-block;
	position: relative;
	z-index: 1;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	width: 100px; */
}

.menu li.click {
  background-color: #73bce6;
}

.menu li a {
  color: white;
}
</style>
