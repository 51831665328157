<template>
  <div class="form-group"
       :class="{
          'input-group': hasIcon,
          'input-group-focus': focused
       }">
    <slot name="label">
      <label v-if="label" class="control-label">
        {{label}}
      </label>
    </slot>
    <slot>
      <div :id="id" class="terms"></div>
    </slot>
    <slot name="checkMsg">
      <div class="div_chk">
        <input
          type="checkbox"
          class="form-check-box"
          v-model="model"
        />
        <span class="msgLable ml-1">{{msg}}</span>
      </div>
    </slot>
  </div>
</template>

<script>
  export default {
    name: "base-term",    
    model: {
      prop: 'checked',
      event: 'input',
    },
    props: {
      checked: [Array, Boolean],
      label: {
        type: String,
        description: "Input label"
      },
      value: {
        type: [String, Number],
        description: "Input value"
      },
      id: {
        type: String,
        description: "Contents Div Id"
      },
      contents: {
        type: String,
        description: "Contents"
      },
      msg: {
        type: String,
        description: "Msg"
      },
    },
    data() {
      return {
        focused: false,
      }
    },
    computed: {
      model: {
        get () {
          return this.checked
        },
        set (check) {
          this.$emit('input', check)
        }
      },
      hasIcon() {
        const { addonRight, addonLeft } = this.$slots;
        return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
      },
      listeners() {
        return {
          ...this.$listeners,
          input: this.onInput,
          blur: this.onBlur,
          focus: this.onFocus
        }
      }
    },
    mounted: function() {
      document.getElementById(this.id).innerHTML = this.contents;
    },
    methods: {
      
    }
  }
</script>
<style>
.terms {
  border:1px solid black; 
  height: 250px; 
  overflow-x:auto
}

.div_chk {
  margin-top: 10px;
}
</style>
