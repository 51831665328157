<template>
   <!-- <div>
      <div v-show="show">
         <div id="layer-return-bg" class="carrot-layer-bg">
            <div id="layer-return-add" class="carrot-layer">
               <div id="addr_wrap" class="addr-wrap" style="display:none">
                  <img src="//t1.daumcdn.net/postcode/resource/images/close.png" id="btnFoldWrap" class="btn-addr-hide"  @click="HidePostcode()" alt="접기 버튼" style="float: right;">
               </div>
            </div>
         </div>
      </div>
   </div> -->
   <div v-show="show">
   <div id="addr_wrap" style="display:none;border:1px solid;width:100%;max-width:500px;height:300px;margin:5px 0;position:relative">
      <img src="//t1.daumcdn.net/postcode/resource/images/close.png" id="btnFoldWrap" style="cursor:pointer;position:absolute;right:0px;top:-1px;z-index:1" @click="HidePostcode()" alt="접기 버튼">
   </div>
   </div>
</template>

<script>
export default{
   props: ['show'],
   data: function () {
        return {
            postcode : "",
            address:"",
            is_detail:false
        }
    },
    
   watch: {
      show: function (newVal) {
         if (newVal == true) {
            this.show = true;
            this.ShowPostcode();
         } else {
            this.show = false;
         }
        },
   },

   mounted: function () {
      this.dim = true;
      this.ShowPostcode();
   },

   methods: {
        ShowPostcode: function(){
         // 우편번호 찾기 찾기 화면을 넣을 element
         var element_wrap = document.getElementById('addr_wrap');
         var addr_height = "0px";
         var me = this;
         var currentScroll = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
         new daum.Postcode({
            oncomplete: function (data) {
               // 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

               // 각 주소의 노출 규칙에 따라 주소를 조합한다.
               // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
               var fullAddr = data.address; // 최종 주소 변수
               var extraAddr = ''; // 조합형 주소 변수

               // 기본 주소가 도로명 타입일때 조합한다.
               if (data.addressType === 'R') {
                  //법정동명이 있을 경우 추가한다.
                  if (data.bname !== '') {
                     extraAddr += data.bname;
                  }
                  // 건물명이 있을 경우 추가한다.
                  if (data.buildingName !== '') {
                     extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                  }
                  // 조합형주소의 유무에 따라 양쪽에 괄호를 추가하여 최종 주소를 만든다.
                  fullAddr += (extraAddr !== '' ? ' (' + extraAddr + ')' : '');
               }

               me.postcode = data.zonecode;
               me.address = fullAddr;
               me.$emit('close', true, me.postcode, me.address);
               // iframe을 넣은 element를 안보이게 한다.
               // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
               // element_wrap.style.display = 'none';

               // 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
               // document.body.scrollTop = currentScroll;

               // initLayerPosition();
            },
            // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
            onresize: function (size) {
               addr_height = 470 + 'px';
               element_wrap.style.height = addr_height;
            },
            width: '100%',
            height: '100%',     
         }).embed(element_wrap);

         // iframe을 넣은 element를 보이게 한다.
         element_wrap.style.display = 'block';
        },
        HidePostcode:function() {
            // 우편번호 찾기 찾기 화면을 넣을 element
            var element_wrap = document.getElementById('addr_wrap');
            var addr_height = "0px";
            // iframe을 넣은 element를 안보이게 한다.
            element_wrap.style.height = "0px";
            setTimeout(function (e) {
                element_wrap.style.display = 'none';
            }, 400);
         this.$emit('close',false);
        }
   }
}
</script>

<style scoped>
#addr_wrap {
   display:none;
   border:1px solid;
   width:500px;
   height:300px;
   margin:5px 0;
   position:relative;
}
</style>
