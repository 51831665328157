<template>
  <div class="content">
    <div class="container-fluid">
      <card>
        <h4 class="settitle">설정</h4>
        <div class="col-md-10">
          <div class="row">
            <label class="passtext">
              본인확인을 위한 비밀번호를 입력해주세요.</label
            >
            <base-input
              type="password"
              placeholder="비밀번호 입력"
              v-model="user.pw"
              id="pwd1"
              class="passinput1"
              @keyup.enter="checkBtn()"
            ></base-input>

            <p class="warn" v-if="condition.pass === 2" style="color: red">
              잘못된 비밀번호입니다
            </p>
          </div>
          <div class="row">
            <button
              type="button"
              class="btn btn-info btn-fill float-right"
              id="saveBtn"
              value="확인"
              @click="checkBtn()"
            >
              확인
            </button>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import { validatePassword } from "src/components/Utils/Validate.js";

export default {
  components: {
    Card,
    BaseInput,
  },
  data() {
    return {
      user: {
        pw: "",
      },
      condition: {
        pass: 0,
      },
    };
  },
  computed: {
    vPass() {
      return validatePassword(this.user.pw);
    },
  },
  methods: {
    async checkpwd() {
      const response = await this.$store.dispatch(
        "COMPARE_PASSWORD",
        this.user.pw
      );
      if (response.data.result) {
        this.condition.pass = 1;
      } else {
        this.condition.pass = 2;
      }
    },
    async checkBtn() {
      await this.checkpwd();
      console.log("condition : ", this.condition.pass);
      if (this.condition.pass === 1) {
        this.$router.push("/admin/user/myInfo");
      }
    },
    async link(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.col-md-10 {
  width: 300px;
  margin-left: -15px;
}
.passtext {
  width: 100%;
  display: inline-block;
}
.passinput1 {
  width: 100%;
  display: inline-block;
}
.btn-info.btn-fill:hover,
.btn-info.btn-fill:focus,
.btn-info.btn-fill:active,
.btn-info.btn-fill.active,
.open > .btn-info.btn-fill.dropdown-toggle {
  display: inline-block;
}
.float-right {
  width: 100px;
  height: 30%;
  padding: 0;
  margin-bottom: 20px;
}
.row {
  padding-left: 15px;
  padding-bottom: 0.5rem;
}
#saveBtn {
  height: 30px;
  width: 80px;
}
#link1 {
  height: 30px;
  width: 200px;
}
#link2 {
  height: 30px;
  width: 200px;
}
.warn {
  margin-bottom: 0;
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  color: #717171;
  padding: 1px 0 0 0px;
  margin-top: 7px;
  margin-left: 5px;
}
</style>
