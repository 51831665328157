<template>
  <div class="content">
    <div class="container-fluid">
      <card>
        <h4 slot="header" class="card-title">회원 정보 수정</h4>
        <form class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <label class="control-label"> ID(이메일)</label>
              <base-input type="text" placeholder="" disabled v-model="user.id">
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-10">
              <label class="control-label"> 이름</label>
              <base-input type="text" placeholder="이름" v-model="user.name">
              </base-input>
              <p class="status col-6" :style="status.name.style">
                {{ isNameValid }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-10">
              <label class="control-label"> 전화번호</label>
              <base-input
                type="text"
                placeholder="전화번호"
                v-model="user.tel_number"
              >
              </base-input>
              <p class="status col-12" :style="status.tel.style">
                {{ isTelValid }}
              </p>
            </div>
          </div>

          <!-- <label class="control-label"> 주소 </label>
          <div class="row">
            <div class="col-sm-9">
              <base-input
                type="text"
                readonly="true"
                placeholder="우편번호"
                v-model="user.postcode"
              >
              </base-input>
            </div>
            <div class="col-sm-3">
              <button
                type="button"
                class="btn find-address"
                @click="layer_pop_address = true"
              >
                찾기
              </button>
              <daum-address
                id="address"
                name="address"
                :show="layer_pop_address"
                @close="getPostcode"
              ></daum-address>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <base-input
                type="text"
                placeholder="주소"
                v-model="user.address_0"
              >
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <base-input
                type="text"
                placeholder="상세 주소"
                v-model="user.address_1"
              >
              </base-input>
            </div>
          </div> -->

          <div class="row">
            <base-input
              class="col-sm-9"
              type="text"
              placeholder="-를 제외한 숫자만 입력"
              label="휴대폰번호"
              v-model="user.mob_number"
              maxlength="11"
              oninput="this.value = this.value.replace(/[^0-9.]/g,
                '').replace(/(\..*)\./g, '$1');"
            ></base-input>
            <div class="col-sm-3 infoform-btn-div">
              <button
                type="button"
                class="btn btn-info btn-fill auth"
                @click="requestSignupCode"
              >
                인증번호 받기
              </button>
            </div>
            <p class="status col-12" :style="status.mobile.style">
              {{ isPhoneValid }}
            </p>
          </div>

          <div class="row">
            <base-input
              class="col-sm-9"
              type="text"
              placeholder="인증번호"
              label="인증번호"
              v-model="user.code"
            ></base-input>
            <div class="col-sm-3 infoform-btn-div">
              <button
                type="button"
                class="btn btn-info btn-fill auth"
                @click="verifySignupCode"
              >
                확 인
              </button>
            </div>
            <p class="status col-6" :style="status.code.style">
              {{ status.code.message }}
            </p>
          </div>
          <div class="clearfix"></div>

          <div class="text-center row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-info btn-fill float-right"
                id="saveBtn"
                @click.prevent="modifying()"
              >
                저 장
              </button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import BaseSelect from "src/components/Inputs/BaseSelect.vue";
import SectionInput from "src/components/Inputs/SectionInput.vue";
// import DaumAddress from "src/components/DaumAddress.vue";
import {
  validatePhone,
  validateTel,
  validateName,
} from "src/components/Utils/Validate.js";

// import EditProfileForm from './UserProfile/EditProfileForm.vue'
// import UserCard from './UserProfile/UserCard.vue'

export default {
  components: {
    Card,
    BaseInput,
    BaseSelect,
    SectionInput,
    // DaumAddress,
  },
  data() {
    return {
      user: {
        id: "",
        name: "",
        tel_number: "",
        // postcode: "",
        // address_0: "",
        // address_1: "",
        mob_number: "",
        code: "",
        check_code: "",
      },
      originalUser: {},
      status: {
        code: { message: "", style: "", complete: false },
        mobile: { style: "", complete: false },
        tel: { style: "", complete: false },
        name: { style: "", complete: false },
      },
      style: {
        warn: "color: red",
        success: "color: green",
      },
      layer_pop_address: false,
    };
  },
  computed: {
    isPhoneValid() {
      if (this.user.mob_number === "") {
        return;
      } else if (validatePhone(this.user.mob_number)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.mobile.style = this.style.success;
        this.status.mobile.complete = true;
        return "사용 가능한 번호입니다.";
      } else {
        this.status.mobile.style = this.style.warn;
        this.status.mobile.complete = false;
        return "형식에 맞지 않는 번호입니다.";
      }
    },
    isTelValid() {
      if (this.user.tel_number === "") {
        return;
      } else if (validateTel(this.user.tel_number)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.tel.style = this.style.success;
        this.status.tel.complete = true;
        return "사용 가능한 전화번호입니다.";
      } else {
        this.status.tel.style = this.style.warn;
        this.status.tel.complete = false;
        return "지역번호를 포함한 전화번호를 입력해주세요. 하이픈(-)을 빼고 입력해주세요.";
      }
    },
    isNameValid() {
      if (this.user.name === "") {
        return;
      } else if (validateName(this.user.name)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.name.style = this.style.success;
        this.status.name.complete = true;
        return "사용 가능한 이름입니다";
      } else {
        this.status.name.style = this.style.warn;
        this.status.name.complete = false;
        return "영어 또는 한글만 입력 가능합니다.";
      }
    },
  },
  async created() {
    var response = await this.$store.dispatch("GET_PROVIDER_DETAIL");

    this.user.id = response.data.data.provider_profile.email;
    this.user.name = response.data.data.provider_profile.name;
    this.user.address_0 = response.data.data.provider_profile.address_0;
    this.user.address_1 = response.data.data.provider_profile.address_1;
    this.user.postcode = response.data.data.provider_profile.postcode;
    this.user.mob_number = response.data.data.provider_profile.mob_number;
    this.user.tel_number = response.data.data.provider_profile.tel_number;

    this.originalUser = this.user;
  },
  methods: {
    maxLength(id, maxLen) {
      //input 텍스트박스 글자수 제한
      var inputValue = document.getElementById(id).value;
      if (maxLen == null) {
        maxLen =
          $("#" + id).attr("maxLength") != null
            ? $("#" + id).attr("maxLength")
            : 1000;
      }

      if (inputValue.length > maxLen) {
        document.getElementById(id).value = inputValue.substring(0, maxLen);
      }
    },
    // getPostcode: function (show, zipcode, address) {
    //   var me = this;
    //   if (show) {
    //     me.layer_pop_address = false;
    //     me.user.address_0 = address;
    //     me.user.postcode = zipcode;
    //     me.user.address_1 = "";
    //   } else {
    //     me.layer_pop_address = false;
    //   }
    //   console.log("me", me.user.address_0 + "  " + me.user.postcode);
    // },
    // checkValidate: function() {

    //   //필수값 체크

    //  return true;
    // },
    saveBtn() {},
    async requestSignupCode() {
      var regPhone = this.user.mob_number;
      alert("인증번호가 발송되었습니다.");
      const result = await this.$store.dispatch(
        "FETCH_REQUEST_SIGNUP_CODE",
        regPhone
      );
      console.log("checkcode response", result);
      this.user.check_code = result.data.data.check_code;
      console.log("user.checkcode", this.user.check_code);
      //   휴대전화 본인인증 API를 이용한 본인인증
    },
    async verifySignupCode() {
      var verify = new Object();
      verify.code = this.user.code;
      verify.check_code = this.user.check_code;
      verify.mobile_number = this.user.mob_number;

      const response = await this.$store.dispatch(
        "FETCH_VERIFY_SIGNUP_CODE",
        verify
      );
      console.log("입력한 본인인증 코드 : ", this.user.check_code);
      console.log("signupcode response", response);
      if (response.data.data === null) {
        //todo  api 생기면 수정해야함
        this.status.code.style = this.style.success;
        this.status.code.complete = true;
        this.status.code.message = "본인인증에 성공하였습니다.";
      } else {
        this.status.code.style = this.style.warn;
        this.status.code.complete = false;
        this.status.code.message = "본인인증에 실패하였습니다.";
      }
    },
    async modifying() {
      const modifying = new Object();
      modifying.name = this.user.name;
      modifying.mob_number = this.user.mob_number;
      modifying.tel_number = this.user.tel_number;
      // modifying.postcode = this.user.postcode;
      // modifying.address_0 = this.user.address_0;
      // modifying.address_1 = this.user.address_1;
      modifying.code = this.user.code;
      modifying.check_code = this.user.check_code;

      if (
        !this.status.name.complete ||
        !this.status.tel.complete ||
        !this.status.mobile.complete
      ) {
        alert("잘못된 입력사항이 있습니다. 수정하고 다시 저장해주세요.");
        return false;
      }
      let checkData = this.check_param(modifying);
      if (!checkData) {
        alert("모든 항목을 입력해주세요.");
        return false;
      }
      console.log(modifying);
      const response = await this.$store.dispatch("UPDATE_PROVIDER", modifying);
      console.log(response);
      if (response.data.result === true) {
        alert("수정이 완료되었습니다");
        const a = await this.$store.dispatch("GET_PROVIDER_DETAIL");
        sessionStorage.setItem("userprofile", a);
        window.location.href = "/admin/overview";
      } else {
        alert("핸드폰 인증 절차를 진행하시기 바랍니다");
      }
    },
    check_param(data) {
      var ret = true;
      for (var key in data) {
        if (data[key] === "" || null) {
          ret = false;
        }
      }
      return ret;
    },
  },
};
</script>
<style scoped>
/* .find-address {
  width: 90px;
  height: 25px;
  padding: 0;
  margin: 11px 0px 11px 15px;
  background-color: #e9ecef;
} */

.card-wrapper {
  min-width: 800px;
}

.card-wrapper form {
  width: 800px;
}

.electric_type-group {
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.electric_type {
  margin-right: 4px;
}

.electric_type-img {
  width: 134px;
  height: 120px;
}

.input-group-text {
  width: 35px;
}

.input-group-text span {
  text-align: center;
  margin: 0 auto;
}

.section1 {
  padding-left: 15px !important;
  padding-right: 5px !important;
}

/***********joinform css */
* {
  font-family: Noto Sans KR;
  font-style: normal;
}
.joinForm-title {
  margin-bottom: 25px;
}

#accountAuth {
  height: 25px;
  padding: 2px 0;
  top: 603px;
  right: 38px;
  position: absolute;
  font-weight: 500;
  font-size: 12.5px;
  line-height: 21px;
  width: 90px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
}
#overlap {
  height: 25px;
  padding: 2px 0;
  top: 6.3rem;
  right: 2rem;
  position: absolute;
  font-weight: 500;
  font-size: 12.5px;
  line-height: 21px;
  width: 90px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
}

.auth {
  min-width: 110px;
}

.twice-check {
  min-width: 60px;
  width: 100%;
  height: 40px;
  padding: 2px 0;
  font-weight: 500;
  font-size: 15px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
  margin: 0px;
}

/* .find-address {
  min-width: 60px;
  width: 100%;
  height: 40px;
  padding: 2px 0;
  font-weight: 500;
  font-size: 15px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
  margin: 0px;
} */
.signup {
  height: 65px;
  margin: 15px 0px 15px 0px;
  background: #156ab2;
  border-radius: 3px;
}
.status {
  position: relative;
  top: -10px;
  left: 10px;
  font-size: 12.5px;
  margin: 0px 0px 10px 0px;
  height: 10px;
}

.backBtn {
  position: absolute;
  left: 15px;
  height: 35px;
  padding: 5px 10px;
}

.infoform-btn-div {
  margin: auto auto 16px auto;
  line-height: 40px;
}

.daum-address {
  margin-bottom: 25px;
}

.account_notice {
  padding: 5px 35px;
}

.account_notice span {
  display: block;
  font-weight: 400;
  font-size: 13px;
  color: #9a9a9a;
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

#tb_acoount_history {
  font-size: 12px;
}

#tb_acoount_history tbody {
  border-top: 1px solid #156ab2;
}

.accountImgName {
  font-size: 12px;
  color: #156ab2;
  margin: -10px 0px 0px 35px;
}
</style>

<style lang="scss">
@mixin can-toggle-branding(
  $can-toggle-off-color: #777,
  $can-toggle-on-color: #42d0ed,
  $can-toggle-inactive-text: rgba(white, 0.5),
  $can-toggle-transition: cubic-bezier(0, 1, 0.5, 1)
) {
  input[type="checkbox"] {
    &[disabled] ~ label {
      color: rgba($can-toggle-off-color, 0.5);
    }

    &:focus ~ label,
    &:hover ~ label {
      .can-toggle__switch {
        background-color: $can-toggle-off-color;
        &:after {
          color: darken($can-toggle-off-color, 10%);
        }
      }
    }
    &:hover ~ label {
      color: darken($can-toggle-off-color, 5%);
    }
    &:checked {
      ~ label {
        &:hover {
          color: darken($can-toggle-on-color, 3%);
        }

        .can-toggle__switch {
          background-color: lighten($can-toggle-on-color, 5%);
          &:after {
            color: darken($can-toggle-on-color, 5%);
          }
        }
      }

      &:focus,
      &:hover {
        ~ label {
          .can-toggle__switch {
            background-color: $can-toggle-on-color;
            &:after {
              color: darken($can-toggle-on-color, 10%);
            }
          }
        }
      }
    }
  }

  label {
    .can-toggle__switch {
      transition: background-color 0.3s $can-toggle-transition;
      background: lighten($can-toggle-off-color, 5%);
      &:before {
        color: $can-toggle-inactive-text;
      }
      &:after {
        // Autoprefixer choked here, so making the prefixes explicit
        -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
        transition: transform 0.3s $can-toggle-transition;
        color: $can-toggle-off-color;
      }
    }
  }
}

@mixin can-toggle-appearance(
  $can-toggle-width: 134px,
  $can-toggle-height: 36px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 14px,
  $can-toggle-switch-font-size: 12px,
  $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
) {
  $can-toggle-switch-width: $can-toggle-width/2;

  input[type="checkbox"] {
    &:focus ~ label,
    &:hover ~ label {
      .can-toggle__switch {
        &:after {
          box-shadow: $can-toggle-shadow;
        }
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:after {
            transform: translate3d(
              $can-toggle-width -
                ($can-toggle-switch-width + $can-toggle-offset),
              0,
              0
            );
          }
        }
      }
      &:focus,
      &:hover {
        ~ label {
          .can-toggle__switch {
            &:after {
              box-shadow: $can-toggle-shadow;
            }
          }
        }
      }
    }
  }

  label {
    font-size: $can-toggle-label-font-size;

    .can-toggle__switch {
      height: $can-toggle-height;
      flex: 0 0 $can-toggle-width;
      border-radius: $can-toggle-border-radius;

      &:before {
        left: $can-toggle-width/2;
        font-size: $can-toggle-switch-font-size;
        line-height: $can-toggle-height;
        width: $can-toggle-width/2;
        padding: 0 12px;
      }

      &:after {
        top: $can-toggle-offset;
        left: $can-toggle-offset;
        border-radius: $can-toggle-border-radius/2;
        width: $can-toggle-switch-width - $can-toggle-offset;
        line-height: $can-toggle-height - ($can-toggle-offset * 2);
        font-size: $can-toggle-switch-font-size;
      }

      &:hover {
        &:after {
          box-shadow: $can-toggle-shadow;
        }
      }
    }
  }
}

.can-toggle {
  position: relative;
  margin-bottom: 15px;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  //overflow: hidden;

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &[disabled] ~ label {
      pointer-events: none;
      .can-toggle__switch {
        opacity: 0.4;
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:before {
            content: attr(data-unchecked);
            left: 0;
          }

          &:after {
            content: attr(data-checked);
          }
        }
      }

      &:focus,
      &:hover {
        ~ label {
        }
      }
    }
  }

  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;

    .can-toggle__switch {
      position: relative;

      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
      }

      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: white;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  // Default values for .can-toggle class
  @include can-toggle-branding;
  @include can-toggle-appearance;

  // &.can-toggle--size-large {
  //   @include can-toggle-appearance(
  //     160px,
  //     0px, // Toggle
  //     50px,
  //     px,  // Toggle h
  //     4px,
  //     x,   // Toggle border r
  //     2px,
  //     x,   // Offset (distance btw switch and
  //     14px,
  //     px,  // Label font
  //     14px,
  //     px,  // Switch font
  //     0 4px 4px rgba(black, 0.4) 0.4) // Switch shadow on hover/
  //   );
  // }
}
</style>
