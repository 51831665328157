import axios from "axios";

import accessToken from "../accessToken";

var access = accessToken.host;
if (access === true) {
  var webURL = "http://localhost:80/admin_php/api.php";
} else {
  var webURL = "https://console.shareplug.co.kr/web/api";
}

// otpissueModal otp발급
function make_otp(otp_time, pay_code, seed_num) {
  const params = new URLSearchParams();
  params.append("request", "make_otp");
  params.append("otp_time", otp_time);
  params.append("pay_code", pay_code);
  params.append("seed_num", seed_num);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// otpissueModal otp 메세지 발송
function send_otp(mobile_number, otp_number, plug_id, otp_time, plug_name) {
  const params = new URLSearchParams();
  params.append("request", "send_otp");
  params.append("mobile_number", mobile_number);
  params.append("otp_code", otp_number);
  params.append("plug_id", plug_id);
  params.append("otp_time", otp_time);
  params.append("plug_name", plug_name);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// joinForm 시리얼넘버 체크 20230712 KIMJAK 추가
function check_provider_serial_num(serial_num) {
  const params = new URLSearchParams();
  params.append("request", "check_provider_serial_num");
  params.append("serial_num", serial_num);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// joinForm 이메일 체크
function check_provider_email(email) {
  const params = new URLSearchParams();
  params.append("request", "check_provider_email");
  params.append("email", email);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 플러그 사용 내역 리스트
function get_plug_record_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_plug_record_list");
  params.append("pages", pages);
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// TicketTable 정기권 구매내역 페이지 테이블 리스트
function get_ticket_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_ticket_list");
  params.append("pages", pages);
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// NomalTable 정산내역 리스트
function get_plug_record_list_money(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_plug_record_list_money");
  params.append("pages", pages);
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// accountTable 등록 계좌 리스트
function get_account_record_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_account_record_list");
  params.append("pages", pages);
  params.append("page", pages.page);
  params.append("site_id", pages.site_id);
  params.append("lines", pages.pageSize);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// addsite 등록 계좌 취소
function get_account_cancel(data) {
  const params = new URLSearchParams();
  params.append("request", "get_account_cancel");
  params.append("account_id", data.account_id);
  params.append("site_id", data.site_id);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// deviceDetailTable 플러그 레코드 디테일 정보
function get_plug_record_detail(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_plug_record_detail");
  params.append("pages", pages);
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);
  params.append("plug_id", pages.plug_id);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// deviceTable 플러그 리스트
function get_plug_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_plug_list");
  params.append("pages", pages);
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);
  params.append("status", pages.status);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// DashboardDeviceTable 플러그 수정
function update_get_plug_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "update_get_plug_list");
  params.append("pages", pages);
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);
  params.append("status", pages.status);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// siteDetailTable 사이트 리스트
function get_plug_site_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_plug_site_list");
  params.append("pages", pages);
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);
  params.append("status", pages.status);
  params.append("site_id", pages.site_id);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 사이트 리스트
function get_site_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_site_list");
  params.append("pages", pages);
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 사이트 삭제는 현재 제거되어있음 수정만가능하기때문에
function delete_site(siteId) {
  const params = new URLSearchParams();
  params.append("request", "delete_site");
  params.append("site_id", siteId);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 인증번호 발송
function request_signup_code(mobile_num) {
  const params = new URLSearchParams();
  params.append("request", "request_signup_code");
  params.append("mobile_number", `${mobile_num}`);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 인증번호 확인
function verify_signup_code(verify) {
  const params = new URLSearchParams();
  params.append("request", "verify_signup_code");
  params.append("code", `${verify.code}`);
  params.append("check_code", `${verify.check_code}`);
  params.append("mobile_number", `${verify.mobile_number}`);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 로그인
function login_provider(provider) {
  const params = new URLSearchParams();
  params.append("request", "login_provider");
  params.append("email", provider.email);
  params.append("password", provider.password);
  params.append("autologin", provider.autologin);
  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 매니저 디테일 정보
function get_provider_detail() {
  const params = new URLSearchParams();
  params.append("request", "get_provider_detail");

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 회원가입
function create_provider(memberInf) {
  const params = new URLSearchParams();
  params.append("request", "create_provider");
  params.append("email", `${memberInf.email}`);
  params.append("password", `${memberInf.password}`);
  params.append("name", `${memberInf.name}`);
  params.append("mob_number", `${memberInf.mob_number}`);
  params.append("tel_number", `${memberInf.tel_number}`);
  // params.append("postcode", `${memberInf.postcode}`);
  // params.append("address_0", `${memberInf.address_0}`);
  // params.append("address_1", `${memberInf.address_1}`);
  params.append("code", `${memberInf.code}`);
  params.append("checkcode", `${memberInf.checkcode}`);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 아이디 찾기
function find_provider(check) {
  const params = new URLSearchParams();
  params.append("request", "find_provider");
  params.append("check", check);
  params.append("name", check.name);
  params.append("mob_number", check.phone);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 플러그 디테일 정보
function get_plug_detail(deviceId) {
  const params = new URLSearchParams();
  params.append("request", "get_plug_detail");
  params.append("plug_uid", deviceId);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 섹션 리스트
function get_section_list(section) {
  const params = new URLSearchParams();
  params.append("request", "get_section_list");
  params.append("section", section);
  params.append("page", section.page);
  params.append("lines", section.pageSize);
  params.append("site_id", section.siteId);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 플러그 생성
function create_plug(plug) {
  const params = new URLSearchParams();
  params.append("request", "create_plug");
  params.append("plug", plug);
  params.append("section_id", plug.selectSection);
  params.append("site_id", plug.siteId);
  params.append("plug_id", plug.serial_num);
  params.append("name", plug.device_name);
  params.append("location", plug.device_location);
  params.append("type", plug.device_type);
  params.append("unit", JSON.stringify(plug.useTime));
  params.append("plug_status", plug.isBroken);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 사이트 생성
function create_site(site) {
  const params = new URLSearchParams();
  params.append("request", "create_site");
  params.append("site", site);
  params.append("name", site.site_name);
  params.append("type", site.electric_type);
  params.append("address_0", site.address_0);
  params.append("address_1", site.address_1);
  params.append("zipcode", site.zipcode);
  params.append("subscribe", site.ticket_flag);
  params.append("subscribe_price", site.price);
  params.append("purchase_code", site.voucherCode);
  params.append("status", site.selected);
  params.append("otp", site.status);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 인증 계좌 생성
function create_account(accountnum) {
  const params = new URLSearchParams();
  params.append("request", "create_account");
  params.append("site_id", `${accountnum.site_id}`);
  params.append("acc_requested", `${accountnum.acc_requested}`);
  params.append("bank_name", `${accountnum.bank_name}`);
  params.append("acc_holder", `${accountnum.acc_holder}`);
  params.append("acc_num", `${accountnum.acc_num}`);
  params.append("ip_address", `${accountnum.ip_address}`);
  params.append("acc_upload", `${accountnum.acc_upload}`);
  // params.append("acc_check", `${accountnum.acc_checked}`);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 계좌 수정
function update_account(accountnum) {
  const params = new URLSearchParams();
  params.append("request", "update_account");
  params.append("site_id", `${accountnum.site_id}`);
  params.append("acc_requested", `${accountnum.acc_requested}`);
  params.append("bank_name", `${accountnum.bank_name}`);
  params.append("acc_holder", `${accountnum.acc_holder}`);
  params.append("acc_num", `${accountnum.acc_num}`);
  params.append("ip_address", `${accountnum.ip_address}`);
  params.append("acc_upload", `${accountnum.acc_upload}`);
  // params.append("acc_check", `${accountnum.acc_checked}`);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 사이트 디테일 정보
function get_site_detail(siteId) {
  const params = new URLSearchParams();
  params.append("request", "get_site_detail");
  params.append("site_id", siteId);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 사이트 수정
function update_site(site) {
  const params = new URLSearchParams();
  params.append("request", "update_site");
  params.append("site", site);
  params.append("site_id", site.site_id);
  params.append("name", site.site_name); //todo 해야함
  params.append("type", site.electric_type);
  params.append("address_0", site.address_0);
  params.append("address_1", site.address_1);
  params.append("zipcode", site.zipcode);
  params.append("subscribe", site.ticket_flag);
  params.append("subscribe_price", site.price);
  params.append("purchase_code", site.voucherCode);
  params.append("status", site.selected);
  params.append("otp", site.status);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 매니저 정보 수정
function update_provider(memberInf) {
  const params = new URLSearchParams();
  params.append("request", "update_provider");
  params.append("name", `${memberInf.name}`);
  params.append("mob_number", `${memberInf.mob_number}`);
  params.append("tel_number", `${memberInf.tel_number}`);
  // params.append("postcode", `${memberInf.postcode}`);
  // params.append("address_0", `${memberInf.address_0}`);
  // params.append("address_1", `${memberInf.address_1}`);
  params.append("code", `${memberInf.code}`);
  params.append("check_code", `${memberInf.check_code}`);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

function comp_password(password) {
  const params = new URLSearchParams();
  params.append("request", "comp_password");
  params.append("password", password);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 플러그 무제한 사용 정지
function unlimit_cancel(plug_id, start_time, plug_rec_id) {
  const params = new URLSearchParams();
  params.append("request", "unlimit_cancel");
  params.append("plug_id", plug_id);
  params.append("time_from", start_time);
  params.append("plug_record_id", plug_rec_id);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 플러그아이디 체크
function check_plug_id(plug_id) {
  const params = new URLSearchParams();
  params.append("request", "check_plug_id");
  params.append("plug_id", plug_id);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 사용내역 검색
function search_using(data) {
  const params = new URLSearchParams();
  params.append("request", "search_using");
  params.append("searchId", data.id);
  params.append("searchValue", data.searchValue);
  params.append("dateFrom", data.dateFrom);
  params.append("dateTo", data.dateTo);
  params.append("site", data.site);
  params.append("page", data.page);
  params.append("lines", data.lines);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 정기권 검색
function search_ticket(data) {
  const params = new URLSearchParams();
  params.append("request", "search_ticket");
  params.append("searchId", data.id);
  params.append("searchValue", data.searchValue);
  params.append("dateFrom", data.dateFrom);
  params.append("dateTo", data.dateTo);
  params.append("page", data.page);
  params.append("lines", data.lines);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 정산 내역 검색
function search_billing(data) {
  const params = new URLSearchParams();
  params.append("request", "search_billing");
  params.append("dateFrom", data.dateFrom);
  params.append("dateTo", data.dateTo);
  params.append("site", data.site);
  params.append("page", data.page);
  params.append("lines", data.lines);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 플러그 리스트 검색
function search_plug_list(data) {
  const params = new URLSearchParams();
  params.append("site", data.site);
  params.append("request", "search_plug_list");
  params.append("searchValue", data.searchValue);
  params.append("siteName", data.siteName);
  params.append("sectionName", data.sectionName);
  params.append("useStatus", data.useStatus);
  params.append("plugStatus", data.plugStatus);
  params.append("onoff", data.onoff);
  params.append("page", data.page);
  params.append("lines", data.lines);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 비밀번호 찾기
function request_password_change(id, name, phone) {
  const params = new URLSearchParams();
  params.append("request", "request_password_change");
  params.append("email", id);
  params.append("name", name);
  params.append("phone", phone);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 비밀번호 수정
function reset_password(password) {
  const params = new URLSearchParams();
  params.append("request", "reset_password");
  params.append("new_password", password);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 로그아웃
function logout() {
  const params = new URLSearchParams();
  params.append("request", "logout");

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 섹션수정
function update_section(data) {
  const params = new URLSearchParams();
  params.append("request", "update_section");
  params.append("site_id", data.id);
  params.append("name", JSON.stringify(data.name));

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

export {
  make_otp,
  send_otp,
  check_provider_serial_num,
  check_provider_email,
  get_plug_record_list,
  get_plug_record_list_money,
  get_ticket_list,
  request_signup_code,
  verify_signup_code,
  login_provider,
  get_provider_detail,
  create_provider,
  find_provider,
  get_plug_list,
  update_get_plug_list,
  get_site_list,
  delete_site,
  get_plug_detail,
  get_section_list,
  create_plug,
  create_site,
  get_site_detail,
  get_plug_site_list,
  update_site,
  get_plug_record_detail,
  get_account_record_list,
  comp_password,
  update_provider,
  unlimit_cancel,
  create_account,
  update_account,
  get_account_cancel,
  check_plug_id,
  search_using,
  search_ticket,
  search_billing,
  request_password_change,
  reset_password,
  logout,
  search_plug_list,
  update_section,
};
