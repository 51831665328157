function validateSerialNum(serialNum) {
  const re = /^(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]|.*[0-9]).{15,15}$/;
  // const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+[.][a-zA-Z0-9-]+$/;
  return re.test(String(serialNum).toLowerCase());
}

function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+[.][a-zA-Z0-9-]+$/;
  return re.test(String(email).toLowerCase());
}

function validatePhone(phone) {
  var num = phone.split("-").join("");
  var rePhone = /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/;
  return rePhone.test(num);
}

function validateTel(tel) {
  var re =
    /^((0[2|31|32|33|41|42|43|44|51|52|53|54|55|61|62|63|67])[0-9]+[0-9]{6,7})/;
  return re.test(tel);
}

function validatePassword(password) {
  const rePass = /^(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]|.*[0-9]).{8,20}$/;
  return rePass.test(password);
}

function validateId(id) {
  const re = /^[a-z0-9]{8,20}$/;
  return re.test(id);
}

// function validateName(name) {
//   const re = /^[a-zA-Zㄱ-힣]{1,30}$/;
//   return re.test(name);
// }
function validateName(name) {
  const re = /^[가-힣a-zA-Z\s+]{1,30}$/;
  return re.test(name);
}

export {
  validateTel,
  validateId,
  validateSerialNum,
  validateEmail,
  validatePhone,
  validatePassword,
  validateName,
};
