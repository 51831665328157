<template>
  <div class="content">
    <div class="container-fluid" style="padding-top: 12vh">
      <div class="row d-flex justify-content-center align-items-center">
        <card class="signUpDiv">
          <!-- <h4 slot="header" class="card-title text-center">아이디 찾기</h4> -->
          <div slot="header" class="joinForm-title">
            <img
              class="btn btn-default backBtn col-xs-2"
              @click="$router.go(-1)"
              src="~@/assets/imgs/return.png"
            />
            <h4 class="card-title text-center col-xs-10">아이디 찾기</h4>
          </div>
          <form>
            <div class="row">
              <div class="col-md-12">
                <label class="control-label"> 이름</label>
                <base-input
                  type="text"
                  v-model="check.name"
                  placeholder="가입 시 입력한 이름을 입력하세요."
                  @blur="checkname()"
                  maxlength="30"
                >
                </base-input>
              </div>
              <span
                class="warn"
                v-if="auth.name.status === false"
                :style="auth.name.style"
              >
                {{ isNameValid }}
              </span>
            </div>

            <div class="row">
              <div class="col-md-12">
                <label class="control-label"> 핸드폰 번호</label>
                <base-input
                  type="num"
                  @keyup="enterkey()"
                  placeholder="가입 시 입력한 핸드폰 번호를 입력하세요."
                  v-model="check.phone"
                  @blur="checkphone()"
                  maxlength="11"
                >
                </base-input>
                <p
                  class="warn"
                  v-if="auth.mobile_number.status === false"
                  :style="auth.mobile_number.style"
                >
                  {{ isPhoneValid }}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 text-center">
                <span class="idFindText"
                  ><p>
                    {{ this.idFindText }}
                  </p>
                </span>
                <button
                  type="button"
                  class="btn btn-info btn-fill loginBtn"
                  @click="findAdminUser()"
                >
                  아이디 찾기
                </button>
              </div>
            </div>

            <div class="clearfix"></div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import { validatePhone, validateName } from "src/components/Utils/Validate.js";
export default {
  components: {
    Card,
    BaseInput,
  },
  data() {
    return {
      check: {
        name: "",
        phone: "",
      },
      style: {
        warn: "color: red",
        success: "color: #4caf50",
      },
      auth: {
        name: { status: false, message: "", style: "", complete: false },
        mobile_number: {
          status: false,
          message: "",
          style: "",
          complete: false,
        },
      },
      idFindText: "",
    };
  },
  computed: {
    isPhoneValid() {
      if (this.check.phone === "") {
        return;
      } else if (validatePhone(this.check.phone)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.auth.mobile_number.style = this.style.success;
        this.auth.mobile_number.complete = true;
        return;
      } else {
        this.auth.mobile_number.style = this.style.warn;
        this.auth.mobile_number.complete = false;
        return "형식에 맞지 않는 번호입니다.";
      }
    },
    isNameValid() {
      if (this.check.name === "") {
        return;
      } else if (validateName(this.check.name)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.auth.name.style = this.style.success;
        this.auth.name.complete = true;
        return;
      } else {
        this.auth.name.style = this.style.warn;
        this.auth.name.complete = false;
        return "한글과 영문만 입력해주시기 바랍니다.";
      }
    },
  },
  methods: {
    // checkphone: function () {
    //   this.auth.mobile_number.status = true;
    //   this.auth.mobile_number.style = this.style.warn;
    //   var regPhone = this.isPhoneValid;
    //   if (this.check.phone === "") {
    //     this.auth.mobile_number.message = "필수정보입니다.";
    //   } else if (regPhone === false) {
    //     this.auth.mobile_number.message = "형식에 맞지 않는 번호입니다.";
    //   }
    // },
    // checkname: function () {
    //   this.auth.name.status = true;
    //   this.auth.name.style = this.style.warn;
    //   if (this.check.name === "") {
    //     this.auth.name.message = "필수정보입니다.";
    //   } else {
    //     this.auth.name.message = "";
    //     this.auth.name.complete = true;
    //   }
    // },
    enterkey() {
      //엔터키 입력 시 로그인
      if (window.event.keyCode == 13) {
        this.findAdminUser();
      }
    },
    async findAdminUser() {
      if (!this.check.name || !this.check.phone) {
        this.idFindText = "정보를 정확하게 입력하시기 바랍니다.";
        return;
      }
      const response = await this.$store.dispatch(
        "FETCH_FIND_PROVIDER",
        this.check
      );
      console.log(response.data.data);

      if (response.data.result === true) {
        this.idFindText = response.data.data.login_id;
        if (this.idFindText) {
          this.maskingId();
        } else {
          this.idFindText = "해당 정보로 가입된 아이디는 없습니다.";
        }
      } else {
        this.idFindText =
          "해당 정보로 등록된 아이디가 없습니다. 입력 정보를 확인하시기 바랍니다";
      }
      //TODO  아이디 찾기 백엔드 추가 필요
      // console.log("asd");
      // this.idFindText = 'dd@ddd.com'; //TODO 테스트용 아이디 -> 추후 삭제 필요
      // if(this.idFindText) {
      //   this.maskingId();
      // }else {
      //   this.idFindText = '해당 정보로 가입된 아이디는 없습니다.';
      // }
    },
    maskingId() {
      var id = "";
      var temp = this.idFindText.split("@");
      var i = temp[0].length < 3 ? 1 : 2;

      temp[0] = temp[0].substr(0, temp[0].length - i) + "**";
      id = temp[0] + "@";

      temp = temp[1].split(".");
      temp[0] = temp[0].substr(0, temp[0].length - 1) + "*";
      id += temp[0] + "." + temp[1];

      this.idFindText = id;
    },
  },
};
</script>
<style scoped>
.card-title {
  margin: 40px 0;
}
.signUpDiv {
  width: 700px;
}

.loginBtn {
  width: 100%;
  margin-top: 10px;
}

.form-check .form-check-sign::before,
.form-check .form-check-sign::after {
  margin-top: 0px;
}

.custom-control-label {
  font-size: 16px;
}

.loginNoticeText {
  color: red;
}

@media (max-width: 768px) {
  .signUpDiv {
    padding: 0;
  }
}

.joinText {
  margin-right: 20px;
}

.idpwRow {
  color: #c2bbbb;
  margin: 10px 0 25px 0;
}

.idpwRow .idpwRowHipen {
  margin: 0 30px;
}

/* 여기서부터 */
.backBtn {
  position: absolute;
  left: 15px;
  height: 35px;
  padding: 5px 10px;
}
.warn {
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  color: #717171;
  padding: 0px 7px 7px 30px;
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
