<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid" @click="$router.push('/admin/overview')">
      <div
        type="button"
        class="navbar-toggler navbar-toggler-right"
        :class="{ toggled: $sidebar.showSidebar }"
        aria-controls="navigation-index"
        aria-expanded="true"
        aria-label="Toggle navigation"
        @click="toggleSidebar"
      >
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </div>

      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav ml-auto">
          <!-- <base-dropdown tag="li">
            <template slot="title">
              <i class="nc-icon nc-planet"></i>
              <b class="caret"></b>
              <span class="notification">1</span>
            </template>
            <a class="dropdown-item" href="#">Notification 1</a>
            <a class="dropdown-item" href="#">Notification 2</a>
            <a class="dropdown-item" href="#">Notification 3</a>
            <a class="dropdown-item" href="#">Notification 4</a>
            <a class="dropdown-item" href="#">Another notification</a>
          </base-dropdown> -->

          <!--다국어 선택-->
          <base-dropdown title="Languages">
            <a class="dropdown-item" v-on:click="$i18n.locale = 'ko'" href="#"
              >한국어</a
            >
            <a class="dropdown-item" v-on:click="$i18n.locale = 'en'" href="#"
              >English</a
            >
          </base-dropdown>
          <!-- <li class="nav-item">
            <router-link to="/admin/user/login" class="nav-link">
              Log In
            </router-link>
          </li> -->
          <!-- 로그아웃 -->
          <!-- <li class="nav-item">
              <a @click="logout()" class="nav-link">
              {{$t("logout")}}
            </a>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout() {
      this.$store.commit("logout");
      localStorage.setItem("token", "");
      alert("로그아웃 되었습니다.");
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
.container-fluid {
  background: url("~@/assets/imgs/logos2.png") no-repeat;
  margin: 5px 0 0 10px;
}
</style>
