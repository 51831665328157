import axios from "axios";

export default {
  install(Vue) {
    // String을 'YYYY-MM-DD HH:mm:ss'의 형태로 리턴한다.
    Vue.prototype.$getDateYYYYMMDD = function(str) {
      var time = str ? String(str) : "";
      var date = "";

      if (time) {
        date =
          time.substr(0, 4) +
          "-" +
          time.substr(4, 2) +
          "-" +
          time.substr(6, 2) +
          " " +
          time.substr(8, 2) +
          ":" +
          time.substr(10, 2) +
          ":" +
          time.substr(12, 2);
      }

      return date;
    };

    //string 형을 date 형으로 변환
    Vue.prototype.$getDateFromString = function(str) {
      var time = str ? String(str) : "";
      var date = "";

      if (time) {
        date = new Date(
          time.substr(0, 4),
          time.substr(4, 2) - 1,
          time.substr(6, 2),
          time.substr(8, 2),
          time.substr(10, 2),
          time.substr(12, 2)
        );
      }

      return date;
    };
    //시작시간과 종료시간의 차이를 구함
    Vue.prototype.$getDuringTime = function(time_from, time_to) {
      var date_to = this.$getDateFromString(time_to);
      var date_from = this.$getDateFromString(time_from);

      var result = " ";

      if (date_to && date_from) {
        //정확한 15분자리로 바꾸면, 시작시간과 종료시간 차이 조금 날 수 있어서 사용시간이 이상하게 나올 수 있음
        var use_min =
          (date_to.getTime() - date_from.getTime() - 14.95 * 60 * 1000) /
          (1000 * 60); // this is minus 15 minutes for users to see
        var use_hour = parseInt(use_min / 60);
        use_min = use_min % 60;
        if (use_hour > 1000) {
          use_min = "-";
          use_hour = "-";
          return (result = "무제한");
        } // this is for unlimit time
        if (use_min <= 1) {
          result += use_hour + "시간";
        } else if (use_hour > 0) {
          result += use_hour + "시간 " + Math.round(use_min) + "분";
        } else if (use_hour < 1) {
          result += Math.round(use_min) + "분";
        } // this is for not showing 0 minutes
      } else {
        result = "-";
      }
      return result;
    };

    //시작시간과 종료시간의 차이를 구함 (결과값 :초)
    Vue.prototype.$getDuringTimeSeconds = function(time_from, time_to) {
      var date_to = this.$getDateFromString(time_to);
      var date_from = this.$getDateFromString(time_from);

      var result = " ";

      if (date_to && date_from) {
        var use_min = (date_to.getTime() - date_from.getTime()) / 1000; // this is minus 15 minutes for users to see
        result = use_min;
      } else {
        result = "-";
      }

      return result;
    };
  }
};
