<template>
  <div id="modal-template">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <!-- <div css="float:right">
            <div class="modal-default-button x-button pointer" @click="$emit('close')">x</div> 
            <div class="modal-default-button x-button pointer" @click="$emit('close')">
               <img src="~@/assets/imgs/close.jpg"/>
            </div>
          </div> -->
            <div class="closeDiv">
              <img
                class="close"
                @click="$emit('close')"
                src="~@/assets/imgs/close.jpg"
              />
            </div>

            <div class="modal-header">
              <slot name="header">
                <div class="aria-center">플러그 ID</div>
                <div class="aria-center">
                  {{
                    serialNumber
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                  }}
                </div>
              </slot>
            </div>

            <div class="modal-body aria-center page1" v-if="page == 1">
              <slot name="body">
                <span class="aria-center title">관리자 OTP 발급</span>
                <table id="timeChocie-table">
                  <tr>
                    <td
                      @click="(timeChoice = 0.5), (payCode = 1)"
                      :class="{ on: timeChoice == 0.5 }"
                    >
                      30분
                    </td>
                    <td
                      @click="(timeChoice = 1), (payCode = 2)"
                      :class="{ on: timeChoice == 1 }"
                    >
                      1시간
                    </td>
                  </tr>
                  <tr>
                    <td
                      @click="(timeChoice = 2), (payCode = 3)"
                      :class="{ on: timeChoice == 2 }"
                    >
                      2시간
                    </td>
                    <td
                      @click="(timeChoice = 4), (payCode = 4)"
                      :class="{ on: timeChoice == 4 }"
                    >
                      4시간
                    </td>
                  </tr>
                  <tr>
                    <td
                      @click="(timeChoice = 6), (payCode = 5)"
                      :class="{ on: timeChoice == 6 }"
                    >
                      6시간
                    </td>
                    <td
                      @click="(timeChoice = 8), (payCode = 6)"
                      :class="{ on: timeChoice == 8 }"
                    >
                      8시간
                    </td>
                  </tr>
                  <tr>
                    <td
                      @click="(timeChoice = 12), (payCode = 7)"
                      :class="{ on: timeChoice == 12 }"
                    >
                      12시간
                    </td>
                    <td
                      @click="(timeChoice = 24), (payCode = 8)"
                      :class="{ on: timeChoice == 24 }"
                    >
                      24시간
                    </td>
                  </tr>
                  <!-- 20230201 jak 단말기 무제한 사용 불가능 이슈로 웹 무제한 사용 숨김처리 -->
                  <!-- <tr>
                    <td
                      colspan="2"
                      @click="timeChoice = '-1', payCode = 9"
                      :class="{ on: timeChoice == '-1' }"
                    >
                      무제한
                    </td>
                  </tr> -->
                </table>
              </slot>
            </div>
            <div class="modal-body aria-center page1" v-else-if="page == 2">
              <slot name="body">
                <span class="aria-center title">관리자 OTP 발급</span>
                <base-input
                  type="text"
                  class="text-center otpNumber"
                  v-model="otpNumber"
                  readOnly="true"
                ></base-input>

                <div class="phoneNumber-area">
                  <div>OTP를 발송할 휴대폰 번호를 입력하세요.</div>
                  <base-input
                    maxlength="3"
                    type="text"
                    class="text-center phoneNumber"
                    v-model="phoneNumber[0]"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                  ></base-input>
                  -
                  <base-input
                    maxlength="4"
                    type="text"
                    class="text-center phoneNumber"
                    v-model="phoneNumber[1]"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                  ></base-input>
                  -
                  <base-input
                    maxlength="4"
                    type="text"
                    class="text-center phoneNumber"
                    v-model="phoneNumber[2]"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                  ></base-input>

                  <div>
                    <button
                      @click="send_msg()"
                      id="send_btn"
                      class="btn btn-wd aria-center"
                    >
                      문자발송
                    </button>
                  </div>
                  <div>
                    <p class="status col-6" :style="status.code.style">
                      {{ status.code.message }}
                    </p>
                  </div>
                </div>
                <div class="last-mobile">
                  <p>
                    마지막 사용자 휴대폰 번호 <br />
                    <span class="aria-center title">{{ lastMobile }}</span>
                  </p>
                </div>
                <p class="otpDesc">
                  플러그 ID를 확인하시고 발급된 OTP를 15분 내에 <br />
                  정확한 쉐어플러그에 입력하고 사용하시기 바랍니다. <br />
                  OTP 입력 시간이 만료된 경우 재발급하십시오. <br />
                </p>
              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <button
                  id="disabled"
                  style="visibility: hidden"
                  class="btn btn-wd btn-info aria-center"
                  @click="make_otp(), (page = 2)"
                  v-if="page == 1"
                >
                  발급
                </button>
                <button
                  class="btn btn-wd btn-info aria-center"
                  @click="$emit('close'), refresh()"
                  v-else-if="page == 2"
                >
                  확인
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseInput from "src/components/Inputs/BaseInput.vue";

export default {
  components: {
    BaseInput,
  },
  props: {
    serialNumber: {
      type: String,
    },
    seedNumber: {
      type: String,
    },
    plug_name: {
      type: String,
    },
    lastMobile: {
      type: String,
    },
  },
  data() {
    return {
      status: {
        code: { message: "", style: "", complete: false },
      },
      timeChoice: 0,
      payCode: 0,
      page: 1,
      otpNumber: "OTP 발급 중",
      phoneNumber: {
        type: String[3],
      },
      style: {
        warn: "color: red; max-width:100%",
        success: "color: green; max-width:100%;",
      },
    };
  },
  updated() {
    if (this.page === 1) {
      console.log("timeChoice : ", this.timeChoice);
      console.log("payCode : ", this.payCode);
      var choiced = document.getElementById("disabled");
      choiced.style = "visibility:visible";
    }
  },
  methods: {
    refresh() {
      this.$router.go();
    },
    send_mobile() {
      if (this.phoneNumber[1].length !== "4") {
        this.status.code.complete = false;
      }
    },
    async send_msg() {
      this.send_mobile();
      var mobilenumber;
      mobilenumber =
        this.phoneNumber[0] + this.phoneNumber[1] + this.phoneNumber[2];
      console.log(mobilenumber);
      if (isNaN(mobilenumber)) {
        this.status.code.style = this.style.warn;
        this.status.code.complete = false;
        return (this.status.code.message = "휴대폰번호 입력해주세요");
      } else {
        var otpdata = {
          mobile: mobilenumber,
          otp: this.otpNumber,
          plug_id: this.serialNumber,
          otp_time: this.timeChoice,
          plug_name: this.plug_name,
        };
        if (
          this.phoneNumber[0] == "010" &&
          this.phoneNumber[1].length + this.phoneNumber[2].length == "8" &&
          this.phoneNumber[1] !== "0000"
        ) {
          const response = await this.$store.dispatch(
            "FETCH_SEND_OTP",
            otpdata
          );
          console.log("message sent:", response.data);
          document.getElementById("send_btn").disabled = true;
          this.status.code.style = this.style.success;
          this.status.code.complete = true;
          return (this.status.code.message = "문자가 발송됐습니다.");
        } else {
          this.status.code.style = this.style.warn;
          this.status.code.complete = false;
          return (this.status.code.message =
            "정확한 연락처를 입력하시기 바랍니다");
        }
      }
    },
    async make_otp() {
      var data = {
        otp_time: this.timeChoice,
        pay_code: this.payCode,
        seed_num: this.seedNumber,
      };
      const response = await this.$store.dispatch("FETCH_MAKE_OTP", data);

      console.log("restest : ", response);
      this.otpNumber = response.data.data.otp_code;
    },
  },
};
</script>

<style scoped>
/* input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
} */
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 540px;
  margin: 0px auto;
  padding: 20px 0px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.aria-center {
  margin: 0px auto;
  text-align: center;
}

#timeChocie-table td {
  border: 1px solid #dee2e6;
}

#timeChocie-table td:hover {
  background-color: #42d0ed;
}

#timeChocie-table td.on {
  background-color: #42d0ed;
}

.x-button {
  padding: 15px;
  text-align: center;
}

.phoneNumber {
  width: 20%;
  display: inline-block;
}

.phoneNumber-area {
  border: 1px solid #dee2e6;
  padding: 10px;
  width: 450px;
  margin: 10px auto;
  /* text-align: -webkit-center; */
}

.close {
  float: right;
  width: 45px;
}

.modal-header {
  display: block;
}

.modal-header div {
  font-size: 22px;
}

.modal-body .title {
  font-weight: bold;
  font-size: 24px;
}

.modal-footer {
  border-top: none;
}

#timeChocie-table {
  margin-top: 20px;
}
.otpNumber {
  width: 450px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.otpNumber input[type="text"] {
  font-size: 60px;
}

.otpDesc {
  width: 450px;
  margin: 30px auto -10px auto;
  margin-bottom: -10px;
}

.closeDiv {
  width: 540px;
  position: absolute;
}

table {
  width: 100%;
  border-collapse: collapse;
}
table th {
  font-size: 1.2rem;
}
/* table tr {
      height: 2rem;
      text-align: center;
      border-bottom: 1px solid #505050;
  }
  table tr:first-of-type {
      border-top: 2px solid #404040;
  } */
table tr td {
  padding: 1rem 0;
  font-size: 1.1rem;
}
.btn-cover {
  margin-top: 1.5rem;
  text-align: center;
  display: flex;
}
.btn-cover .page-btn {
  width: 5rem;
  height: 2rem;
  letter-spacing: 0.5px;
}
.btn-cover .page-count {
  padding: 0 1rem;
}
.btn-cover .pagination {
  margin: 0 auto;
}
</style>
