<template>
  <div>
    <!-- <paging-table :list-array="pageArray" :columns="table1.columns"
        :data="table1.data" @showAlert="showAlert"> <template slot="title"><h1>사이트
        리스트</h1></template> </paging-table> -->
    <h4 class="pageTitle">
      {{ $t("site.all") }}
      <button class="SitePlus" @click="$router.push('/admin/site/add')">
        사이트 추가
      </button>
    </h4>

    <div v-if="tableData.length > 0">
      <div class="pc-table">
        <div class="div-table">
          <div class="div-row">
            <div class="div-cell" v-for="column in tableColumns" :key="column">
              <!-- <span v-if="column.substr(0, 3) == 'btn'"></span>
              <span v-else>
                {{ $t(column) }}
              </span> -->
              <span> {{ $t(column) }} </span>
            </div>
          </div>
          <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
          <div class="div-row" v-for="(item, index) in tableData" :key="index">
            <div
              class="div-cell"
              v-for="column in tableColumns"
              :key="column"
              @click.stop="moveSiteDetail(item.siteId, $event)"
            >
              <div v-if="column == 'status'">
                <span
                  v-if="itemValue(item, 'status') == 'Available'"
                  class="status_notuse"
                  >대기중</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'ACTIVE'"
                  class="status_use"
                  >사용중</span
                >
                <span
                  v-else-if="itemValue(item, 'status') == 'NOTWORK'"
                  class="status_wait"
                  >대기중</span
                >
              </div>
              <div v-else-if="column == 'btn-modify'">
                <button
                  class="btn btn-info btn-sm"
                  @click.self="siteModify(item.siteId, $event)"
                >
                  수정
                </button>
                <!-- site 삭제 기능 안 하고 있음 -->
                <!-- <button class="btn btn-info small"  @click.self="siteDelete(item.siteId, $event)">삭제</button> -->
              </div>
              <div v-else>
                {{ itemValue(item, column) }}
                <div v-if="column == 'bank_name' && item.bank_name != '-'">
                  <div class="note_area">
                    <a class="bank_btn"
                      ><img
                        class="note_btn"
                        src="~@/assets/imgs/siteList/icon_notice-03.svg"
                      /><span
                        >{{ itemValue(item, "bank_name") }} /
                        {{ itemValue(item, "acc_holder") }} /
                        {{ itemValue(item, "acc_num") }}
                      </span></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="mobile-table" > -->
      <!-- <div class="card text-center"  v-for="(item, index) in paginatedData" :key="index"  @click="moveSiteDetail(item.siteId)"> -->
      <!-- <div class="card" v-for="(item, index) in tableData" :key="index" @click="moveSiteDetail(item.siteId, $event)"> -->
      <!-- <div class="div-more"><img src="~@/assets/imgs/icon-more.png"/></div> -->
      <!-- <base-dropdown class="div-more">
                            <template slot="title">
                                    <img src="~@/assets/imgs/icon-more.png"/>
                            </template>
                            <a class="dropdown-item" @click="siteModify(item.siteId, $event)">수정</a>
                            <a class="dropdown-item" @click="siteDelete(item.siteId, $event)">삭제</a>
                        </base-dropdown>
                        <div class="card-body">
                            <div class="sitename">  {{ itemValue(item, 'siteName') }}</div>
                            <div class="status"> 
                                <span v-if="itemValue(item,'status') == 'Available'" class="status_notuse"> 대기중</span>
                                <span v-else-if="itemValue(item,'status') == 'ACTIVE'"  class="status_use"> 사용중</span>
                                <span v-else-if="itemValue(item,'status') == 'NOTWORK'"  class="status_wait"> 대기중</span>
                            </div>  
                            <div class="address">  {{ itemValue(item, 'address_0'+ 'address_1') }}</div>
                        </div>
                </div>      
            </div> -->

      <div class="btn-cover">
        <ul class="pagination pagination-no-border pagination">
          <li class="page-item prev-page">
            <button
              aria-label="Previous"
              @click="getSiteList(pageNum - 1)"
              v-bind:class="{ 'page-link': true, disabled: pageNum == 1 }"
            >
              <span>&lt;&lt;</span>
            </button>
          </li>
          <li
            class="page-item"
            v-for="(item, index) in pageCount"
            :key="item.id"
            :class="{ active: pageNum == index + 1 }"
          >
            <a class="page-link" @click="getSiteList(index + 1)">{{
              index + 1
            }}</a>
          </li>
          <li class="page-item page-pre next-page">
            <button
              @click="getSiteList(pageNum + 1)"
              v-bind:class="{
                'page-link': true,
                disabled: pageNum >= pageCount
              }"
            >
              <span>&gt;&gt;</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <div class="emptyContent">
        표시할 사이트가 없습니다. 사이트를 추가해주세요.
      </div>
    </div>
  </div>
</template>

<script>
const tableColumns = [
  "siteName",
  "address_0",
  "address_1",
  // 'operationDevice', <!-- 시운영할 때에는 제외 -->
  "status",
  "acc_approval",
  // "bank_name",
  "btn-modify"
];

const site_acc_account = ["error", "승인", "승인대기"];

export default {
  name: "site-list",
  data() {
    return {
      pageNum: 0,
      tableColumns: tableColumns,
      tableData: [],
      totalNum: 0,
      pages: { page: 1, pageSize: 15 }
    };
  },
  props: {
    listArray: {
      type: Array,
      required: false
    },
    item: {
      type: Object
    },
    column: {
      type: String
    }
  },
  created() {
    //초기 데이터 셋팅
    this.getSiteList();
  },
  computed: {
    pageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      console.log(page);
      return page;
    }
  },
  watch: {
    pageNum: function() {
      console.log("pageNum : " + this.pageNum);
    }
  },
  methods: {
    moveSiteDetail(siteId, event) {
      event.stopPropagation();
      this.$router.push("/admin/site/detail/" + siteId);
    },
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column];
    },
    siteModify(siteId, event) {
      event.stopPropagation();
      alert("수정 페이지로 이동합니다.");
      this.$router.push("/admin/site/modify/" + siteId);
    },

    // site 삭제 기능을 안 하고 있음
    // async siteDelete(siteId, event) {
    //   //사이트 삭제
    //   event.stopPropagation();
    //   $(".div-more").trigger("click");

    //   const response = await this.$store.dispatch("FETCH_DELETE_SITE", siteId);
    //   console.log("response:", response.data);
    //   // 새로고침
    //   window.location.reload();
    //   alert("삭제되었습니다.");
    //   if (this.tableData.length == 1) {
    //     this.pageNum--;
    //   }

    //   // this.getSiteList(this.pageNum, this.pageSize);
    //   //리스트 새로고침
    //   //todo 상단의 총 사이트, 총 플러그, 총 시간, 총 가격도 새로고침
    // },
    async getSiteList(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;
      const response = await this.$store.dispatch(
        "FETCH_GET_SITE_LIST",
        this.pages
      );
      console.log("response:", response.data.result);
      console.log(response.data);

      //사이트 리스트 불러오기

      this.tableData = [];
      this.totalNum = response.data.data.total;
      var list = response.data.data.site_list;

      for (var item of list) {
        // if (item.acc_status == "N") {
        //   item.bank_name = "-";
        //   item.acc_status = "승인 대기";
        // } else if (item.acc_status == "R") {
        //   item.bank_name = "-";
        //   item.acc_status = "재요청";
        // } else if (item.acc_status == "Y") {
        //   item.acc_status = "승인";
        // } else if (item.acc_status == "D") {
        //   item.acc_status = "재요청 취소";
        // }
        var temp = {
          siteId: item.site_id,
          siteName: item.name,
          address_0: item.address_0,
          address_1: item.address_1,
          status: item.status,
          acc_approval: site_acc_account[item.site_acc_status]
          // bank_name: item.bank_name,
          // acc_holder: item.acc_holder,
          // acc_num: item.acc_num
        };
        // console.log(item.status);
        this.tableData.push(temp);
      }
    }
    // hasValue(item, column) {     return item[column.toLowerCase()] !==
    // 'undefined' }, itemValue(item, column) {     console.log("itemValue");
    // return item[column.toLowerCase()] }, paginatedData() {     const start =
    // this.pageNum * this.pageSize,         end = start + this.pageSize;     return
    // this         .data         .slice(start, end); }
  }
};
</script>

<style scoped>
.pageTitle {
  font-weight: 400;
  padding-left: 15px;
  padding-top: 5px;
}
.note_area {
  margin-right: -100px;
  margin-top: -25px;
}
.note_btn {
  width: 17px;
}
.sitename {
  font-size: 25px;
  font-weight: bold;
}

.bank_btn {
  height: 12px;
  font-size: 14px;
  color: #454545;
  text-decoration: none;
}

.bank_btn:hover {
  position: relative;
}

.bank_btn span {
  display: none;
}

.bank_btn:hover span {
  border: #c0c0c0 1px dotted;
  padding: 5px 20px 5px 5px;
  display: block;
  z-index: 100;
  background: white no-repeat 100% 5%;
  left: 0px;
  margin: 10px;
  width: 250px;
  position: absolute;
  top: 10px;
  text-decoration: none;
}

/* 사이트추가 */
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  text-align: right;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  /* background-color: #e9ecef; */
}
.SitePlus {
  display: block;
  width: 100px;
  clear: both;
  font-weight: 400;
  /* color: #212529; */
  text-align: inherit;
  white-space: nowrap;
  border-radius: 4px;
  background-color: #73bce6;
  border: 1px solid #4388c1;
  color: white;
  font-size: 13px;
  padding: 0;
  padding-left: 15px;
  margin-left: 90%;
  margin-top: -34px;
}
</style>
