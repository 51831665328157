<template>
  <div class="content">
    <div class="container-fluid card">
      <div class="row">
        <div class="pageTitle">{{ siteName }}</div>

        <base-dropdown
          tag="div"
          class="small"
          style="position: absolute; right: 50px"
          allow="left"
        >
          <template slot="title">
            <i class="nc-icon"
              ><img class="icon-img20" src="~@/assets/imgs/icon-plus.png"
            /></i>
            <b class="caret"></b>
          </template>
          <a class="dropdown-item" @click="$router.push('/admin/device/add')"
            >플러그 추가</a
          >
        </base-dropdown>

        <!-- <base-dropdown
          tag="div"
          class="small"
          style="position: absolute; right: 0px"
          allow="left"
        >
          <template slot="title">
            <i class="nc-icon"
              ><img class="icon-img20" src="~@/assets/imgs/icon-more.png"
            /></i>
            <b class="caret"></b>
          </template>
          <a
            class="dropdown-item"
            @click="$router.push('/admin/site/modify/' + siteId)"
          ></a>
          <a class="dropdown-item" @click="siteDelete(siteId)">사이트 삭제</a>
        </base-dropdown> -->
      </div>

      <div class="pageTitleSub">
        {{ location }}
        <!-- <h6 class="pageTitleSub">{{location}}</h6> -->
      </div>
    </div>

    <!-- <div class="row">
        <div class="col-xl-4 col-md-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title text-center">총 10 플러그</h4>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-md-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title text-center">총 56 시간 </h4>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-md-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title text-center">총 515,000원</h4>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-12">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <site-detail-table
            class="table-hover table-striped"
            :filterStatus="filterStatus"
            :siteId="siteId"
          ></site-detail-table>
        </card>
      </div>
    </div>
    <!-- //row -->
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import SiteDetailTable from "src/components/Table/SiteDetailTable.vue";

export default {
  components: {
    Card,
    SiteDetailTable
  },
  data() {
    return {
      siteId: "",
      siteName: "에이스 하이테크 2차",
      location: "서울시 영등포구 문래동",
      filterStatus: "all"
    };
  },
  created() {
    if (this.$route.params.siteId) {
      this.siteId = this.$route.params.siteId;

      this.getSiteDetail(this.siteId);
    }
  },
  methods: {
    async getSiteDetail(siteId) {
      console.log("getSiteDetail");
      const response = await this.$store.dispatch(
        "FETCH_GET_SITE_DETAIL",
        siteId
      );
      console.log("response site_detail: ", response.data);
      if (
        response.data.result == false ||
        (response.data.data && response.data.data.error)
      ) {
        var error = response.data.data.error;
        var errorText = "";
        if (error == "SESSION_EXPIRED") {
          errorText = "세션 만료 되었습니다.";
          //todo 홈으로 이동
        } else if (error == "INVALID_CORP") {
          errorText = "유효하지 않은 사업자 입니다.";
        } else if (error == "DB_ERROR") {
          errorText = "DB에 오류가 발생했습니다.";
        } else {
          errorText = "작업을 처리하는데 실패하였습니다.";
        }
        alert(errorText);

        //저장 버튼 비활성화
        var btn = document.getElementById("saveBtn");
        btn.disabled = "disabled";

        return false;
      }

      this.siteName = response.data.data.name;
      this.location = response.data.data.address;
      //todo 상단의 총 사이트, 총 플러그, 총 시간, 총 가격도 새로고침
    }
    // siteDelete(siteId) {
    //   //사이트 삭제
    //   $(".div-more").trigger("click");
    //   const params = new URLSearchParams();
    //   params.append("request", "delete_site");
    //   params.append("site_id", siteId);

    //   this.$axios.post("", params).then((res) => {
    //     const data = res.data;

    //     if (data.result == false || (data.data && data.data.error)) {
    //       var error = data.data.error;
    //       var errorText = "";
    //       if (error == "SESSION_EXPIRED") {
    //         errorText = "세션 만료 되었습니다.";
    //         //todo 홈으로 이동
    //       } else if (error == "INVALID_CORP") {
    //         errorText = "유효하지 않은 사업자 입니다.";
    //       } else if (error == "DB_ERROR") {
    //         errorText = "DB에 오류가 발생했습니다.";
    //       } else {
    //         errorText = "작업을 처리하는데 실패하였습니다.";
    //       }
    //       alert(errorText);
    //       return false;
    //     }

    //     alert("삭제되었습니다.");
    //     this.$router.push("/admin/site/list");
    //   });
    // },
  }
};
</script>
<style scoped>
.nav-link {
  display: inline;
  line-height: 25px;
}

/* 사이트 상세 페이지 css */

.pageTitleSub {
  font-size: 16px;
  margin-top: -25px;
  margin-bottom: 15px;
}

.container-fluid {
  padding: 15px;
}

.container-fluid .row:first-child {
  height: 100px;
}

.container-fluid .row .card {
  height: 80%;
}
</style>
