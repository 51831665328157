<template>
  <div class="wrapper">
    <side-bar>
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/admin/overview">
        <i class="nc-icon icon-dashboard"></i>
        <p>Dashboard</p>
      </sidebar-link>

      <sidebar-dropdwon-link>
        <template slot="title">
          <i class="fa icon-site"></i>
          <b class="caret"></b>
          <span class="notification">{{ $t("menu.site.manage") }}</span>
        </template>
        <div class="dropdown-menu-custom">
          <router-link
            class="sidebar-dropdown-item"
            to="/admin/site/list"
            @click.native="toggleSidebar"
            >{{ $t("menu.site.list") }}</router-link
          >
          <router-link
            class="sidebar-dropdown-item"
            to="/admin/site/add"
            @click.native="toggleSidebar"
            >{{ $t("menu.site.add") }}</router-link
          >
        </div>
      </sidebar-dropdwon-link>

      <sidebar-dropdwon-link>
        <template slot="title">
          <i class="nc-icon icon-apparatus"></i>
          <b class="caret"></b>
          <span class="notification">{{ $t("menu.device.manage") }}</span>
        </template>
        <div class="dropdown-menu-custom">
          <router-link
            class="sidebar-dropdown-item"
            to="/admin/device/list"
            @click.native="toggleSidebar"
            >{{ $t("menu.device.list") }}</router-link
          >
          <router-link
            class="sidebar-dropdown-item"
            to="/admin/device/add"
            @click.native="toggleSidebar"
            >{{ $t("menu.device.add") }}</router-link
          >
        </div>
      </sidebar-dropdwon-link>

      <sidebar-dropdwon-link>
        <template slot="title">
          <i class="nc-icon icon-use"></i>
          <b class="caret"></b>
          <span class="notification">{{ $t("menu.use.manage") }}</span>
        </template>
        <div class="dropdown-menu-custom">
          <router-link
            class="sidebar-dropdown-item"
            to="/admin/using/list"
            @click.native="toggleSidebar"
            >{{ $t("menu.use.useDetail") }}</router-link
          >
        </div>
        <div class="dropdown-menu-custom">
          <router-link
            class="sidebar-dropdown-item"
            to="/admin/billing/list"
            @click.native="toggleSidebar"
            >{{ $t("menu.use.paidDetail") }}</router-link
          >
        </div>
        <div class="dropdown-menu-custom">
          <router-link
            class="sidebar-dropdown-item"
            to="/admin/ticket/list"
            @click.native="toggleSidebar"
            >{{ $t("menu.use.ticketDetail") }}</router-link
          >
        </div>
      </sidebar-dropdwon-link>

      <sidebar-link to="/admin/user/manual">
        <i class="nc-icon icon-manual"></i>
        <p>매뉴얼&App</p>
      </sidebar-link>

      <!-- 시운영할 때에는 제외 -->
      <!-- <sidebar-dropdwon-link>
        <template slot="title">
          <i class="nc-icon icon-calculate"></i>
          <b class="caret"></b>
          <span class="notification">정산 관리</span>
        </template>
        <div class="dropdown-menu-custom">
          <router-link class="sidebar-dropdown-item" to="/admin/paid/list" @click.native="toggleSidebar">정산 내역</router-link>
        </div>
       </sidebar-dropdwon-link>

        <sidebar-dropdwon-link>
        <template slot="title">
          <i class="nc-icon icon-stats"></i>
          <b class="caret"></b>
          <span class="notification">통계</span>
        </template>
        <div class="dropdown-menu-custom">
          <router-link class="sidebar-dropdown-item" to="/admin/device/list" @click.native="toggleSidebar">요약</router-link>
        </div>
       </sidebar-dropdwon-link>

        <sidebar-dropdwon-link>
        <template slot="title">
          <i class="fa icon-center"></i>
          <b class="caret"></b>
          <span class="notification">고객센터</span>
        </template>
        <div class="dropdown-menu-custom">
          <router-link class="sidebar-dropdown-item" to="/admin/device/list" @click.native="toggleSidebar">FAQ</router-link>
          <router-link class="sidebar-dropdown-item" to="/admin/device/list" @click.native="toggleSidebar">1:1문의</router-link>
        </div>
       </sidebar-dropdwon-link> -->

      <!-- <sidebar-link to="/admin/user/signup">
        <i class="nc-icon icon-signup"></i>
        <p>{{$t("user.signup")}}</p>
      </sidebar-link> -->
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click="toggleSidebar"> </dashboard-content>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu.vue";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
  },
  methods: {
    Research() {
      this.$router.go(this.$route.currentRoute);
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    refresh() {
      this.$router.go();
    },
  },
};
</script>

<style scoped>
.dropdown-item {
  margin: 5px 15px 0px 40px;
  border-radius: 4px;
  color: #ffffff;
  opacity: 0.86;
  padding: 8px 50px;
}
</style>
