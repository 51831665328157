<template>
  <div>
    <div class="pc-table">
      <div class="div-table">
        <div class="div-row">
          <div class="div-cell" v-for="column in tableColumns" :key="column">
            <span v-if="column.substr(0, 3) == 'adm'"></span>
            <span v-else>
              {{ $t(column) }}
            </span>
          </div>
        </div>
        <div
          class="div-row"
          v-for="(item, index) in paginatedDate"
          :key="index"
        >
          <div class="div-cell" v-for="column in tableColumns" :key="column">
            <span v-if="hasValue(item, column) && column == 'acc_status'">
              <div
                v-if="
                  itemValue(item, 'acc_status') == 'R' ||
                    itemValue(item, 'acc_status') == 'N'
                "
              >
                <button
                  class="btn btn-info btn-cancle float-right"
                  name="data"
                  type="button"
                  @click="cancel_upload(index)"
                >
                  취소
                </button>
              </div>
              <div v-if="itemValue(item, 'acc_status') == 'Y'">
                <span>
                  {{ item.acc_check }}
                </span>
              </div>
              <div v-if="itemValue(item, 'acc_status') == 'C'">
                <span> - </span>
              </div>
            </span>

            <div v-else-if="hasValue(item, column) && column == 'plug_id'">
              <span class="div-cell">{{ itemValue(item, column) }} </span>
            </div>
            <div v-else>
              {{ itemValue(item, column) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="moreview btn btn-default btn-block"
      v-show="listSize > i"
      @click="moreview"
      style="border: none"
    >
      {{ $t("moreview") }}▼
    </div>

    <otp-check-modal
      v-if="showOtpCheckModal"
      @close="showOtpCheckModal = false"
      :serialNumber="serialNumber"
    ></otp-check-modal>
    <otp-issue-modal
      v-if="showOtpIssueModal"
      @close="showOtpIssueModal = false"
      :serialNumber="serialNumber"
    ></otp-issue-modal>
  </div>
</template>

<script>
// const tableColumns = ['requested', 'account_number', 'IP_address','checked']
const tableColumns = [
  "requested",
  "bank_name",
  "account_holder",
  "account_number",
  "acc_status"
];

export default {
  components: {},
  name: "accountTable",
  data() {
    return {
      i: 5,
      // maxLine: 99,
      moreLine: 5,
      listSize: 99,
      tableColumns: tableColumns,
      useList: [],
      showOtpCheckModal: false,
      showOtpIssueModal: false,
      serialNumber: "",
      paginatedDate: [],
      pages: { page: 1, pageSize: 5, status: "" }
    };
  },
  props: {
    _status: {
      type: String
    }

    //   columns: Array,
    //   data: Array
  },
  created() {
    this.pages.status = this.status;
    //초기 데이터 셋팅
    this.getList();
    console.log("status : " + this.status);
  },
  computed: {
    // paginatedDate(){
    //   return this.useData.slice(0, this.i);
    // },
    // pageCount() { //전체 페이지 수 count
    //     let listLeng = this.totalNum,
    //         listSize = this.pageSize,
    //         page = Math.floor(listLeng / listSize);
    //     if (listLeng % listSize > 0)
    //         page += 1;
    //     return page;
    // },
    //  paginatedData() { //전체 데이터를 다 가져올 경우 페이징 처리하는 함수로 현재는 페이지에 보여줄만큼 가져오므로 주석 처리
    //      const start = this.pageNum * this.pageSize,
    //              end = start + this.pageSize;
    //      return this
    //          .tableData
    //          .slice(start, end);
    //  }
  },
  methods: {
    // 계좌 취소
    async cancel_upload(index) {
      if (
        confirm("다음 요청하신 계좌 변경을 취소하는 것이 맞습니까?") == true
      ) {
        //확인
        // this.paginatedDate.splice(index, 1);  //리스트 삭제 20230130 jak
        this.paginatedDate[index].acc_status = "D";
        this.$emit("dele_id", this.useList[index].account_id);
        var data = [];
        data.account_id = this.useList[index].account_id;
        data.site_id = this.pages.site_id;
        const response = await this.$store.dispatch(
          "FETCH_GET_ACCOUNT_CANCEL",
          data
        );
        console.log("jak", response.data);
        //document.removefrm.submit();
      } else {
        //취소
        return false;
      }
      //console.log(response.data);
    },
    hasValue(item, column) {
      return item[column] !== "undefined";
    },
    itemValue(item, column) {
      return item[column];
    },

    // 더보기
    moreview() {
      this.i = this.i + this.moreLine;
      this.paginatedDate = this.useList.slice(0, this.i);
    },

    // 계좌 리스트
    async getList() {
      //debugger;
      //var response = await get_plug_record_list(this.pages);
      console.log("paramtest : ", this.pages);
      this.pages.site_id = this.$route.params.siteId;
      const response = await this.$store.dispatch(
        "FETCH_GET_ACCOUNT_RECORD_LIST",
        this.pages
      );
      console.log("test : ", response);
      // var background = "";
      var list = response.data.data.account_list;
      this.listSize = list.length;
      // this.listSize = response.data.data.total; //need to get totalNum= response.data.data.total;
      var ac_status = "";
      for (var item of list) {
        var temp = {
          account_id: item.account_id,
          provider_id: item.provider_id,
          requested: item.acc_requested,
          account_holder: item.acc_holder,
          bank_name: item.bank_name,
          account_number: item.acc_num,
          ip_address: item.ip_address,
          acc_check: item.acc_check,
          acc_status: item.acc_status
        };

        if (item.acc_status === "R") {
          ac_status = "R";
        }

        this.useList.push(temp);
      }
      this.$emit("acc_count", this.useList.length);
      this.$emit("acc_account", ac_status);
      this.paginatedDate = this.useList.slice(0, this.i);
    }
  }
};
</script>
<style scoped>
.float-right {
  margin-right: 23%;
  height: 40px;
}
/* 버튼 */

@media (max-width: 768px) {
  #tickets thead {
    display: none;
  }

  #tickets,
  #tickets tbody,
  #tickets tr,
  #tickets th,
  #tickets td {
    display: block;
  }

  #tickets tr {
    border-bottom: 1px solid #ddd;
  }

  #tickets th,
  #tickets td {
    border-top: none;
    border-bottom: none;
  }

  #tickets td:first-child {
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    padding: 12px 8px;
  }

  #tickets td:nth-child(2) {
    display: block;
  }

  #tickets td:nth-child(3) {
    width: 50%;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0px;
    right: 0px;
    text-align: center;
    padding: 68px 0;
  }

  #tickets td:nth-child(3) span {
    font-size: 20px;
    font-weight: bold;
    color: #4caf50;
  }

  #tickets td:nth-child(4),
  #tickets td:nth-child(5),
  #tickets td:nth-child(6) {
    display: table-cell;
  }
}

/* 여기서부터 */

.moreview {
  text-align: center;
  height: 45px;
  padding: 13px 0px;
}
</style>
