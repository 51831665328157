<template>
  <div>
    <div class="pc-table">
      <div class="div-table">
        <div class="div-row">
          <div class="div-cell" v-for="column in tableColumns" :key="column">
            <span v-if="column.substr(0, 3) == 'btn'"></span>
            <span v-else>
              {{ $t(column) }}
            </span>
          </div>
        </div>
        <div class="div-row" v-for="(item, index) in tableData" :key="index">
          <div class="div-cell" v-for="column in tableColumns" :key="column">
             <!-- <div v-if="column == 'status'">
              <span
                v-if="itemValue(item, 'status') == 'Available'"
                class="status_notuse"
              >
                준비중</span
              >
              <span
                v-else-if="itemValue(item, 'status') == 'ACTIVE'"
                class="status_use"
                >사용중</span
              >
              <span
                v-else-if="itemValue(item, 'status') == 'Wait'"
                class="status_wait"
                >대기중</span
              >
              <span
                v-else-if="itemValue(item, 'status') == 'OTP_ACTIVE'"
                class="status_use"
                >사용중</span
              >
              <span
                v-else-if="itemValue(item, 'status') == 'UNLIMIT'"
                class="status_use"
                >사용중</span
              >
            </div> -->
            <div v-if="column == 'price'">
              {{ itemValue(item, column) | numCommaFilter}}
            </div>
            <div v-else>
              {{ itemValue(item, column) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-cover">
      <ul class="pagination pagination-no-border pagination-">
        <li class="page-item prev-page">
          <button
            aria-label="Previous"
            @click="getDeviceRecordDetail(pageNum - 1)"
            v-bind:class="{ 'page-link': true, disabled: pageNum <= 1 }"
          >
            <span>&lt;&lt;</span>
          </button>
        </li>
        <li
          class="page-item"
          v-for="(item, index) in pageCount"
          :key="item"
          :class="{ active: pageNum == index + 1 }"
        >
          <a class="page-link" @click="getDeviceRecordDetail(index + 1)">{{
            index + 1
          }}</a>
        </li>
        <li class="page-item page-pre next-page">
          <button
            @click="getDeviceRecordDetail(pageNum + 1)"
            v-bind:class="{ 'page-link': true, disabled: pageNum >= pageCount }"
          >
            <span>&gt;&gt;</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const tableColumns = [
  "userNumber",
  // "status",
  "price",
  "date",
  "startTime",
  "endTime",
  "time"
];
import PagingTable from "./PagingTable";

export default {
  name: "device-detail-table",
  components: {
    PagingTable
  },
  data() {
    return {
      pageNum: 0,
      deviceId: "",
      pages: { page: 0, pageSize: 15, plug_id: "" },
      tableColumns: tableColumns,
      tableData: [],
      totalNum: 0,
      plugId: ""
    };
  },
  props: {
    listArray: {
      type: Array,
      required: false
    },
    item: {
      type: Object
    },
    column: {
      type: String
    },
    filterStatus: {
      type: String
    }
  },
  async created() {
    //초기 데이터 셋팅
    this.deviceId = this.$route.params.deviceId;
    console.log("Asds")
    await this.getDeviceDetail();

    this.getDeviceRecordDetail();
  },
  computed: {
    pageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);
      if (listLeng % listSize > 0) page += 1;

      return page;
    }
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column];
    },
    async getDeviceDetail() {
      const response = await this.$store.dispatch(
        "FETCH_GET_PLUG_DETAIL",
        this.deviceId
      );
      console.log("response plug_detail:", response.data);

      //플러그 상세 불러오기
      const data = response.data;

      if (data.result == false || data.data.error) {
        var error = data.data.error;
        var errorText = "";
        if (error == "SESSION_EXPIRED") {
          errorText = "세션 만료 되었습니다.";
          //todo 홈으로 이동
        } else if (error == "INVALID_CORP") {
          errorText = "유효하지 않은 사업자 입니다.";
        } else if (error == "DB_ERROR") {
          errorText = "DB에 오류가 발생했습니다.";
        } else {
          errorText = "작업을 처리하는데 실패하였습니다.";
        }
        alert(errorText);
        return false;
      }
      this.plugId = response.data.data.plug_id;
      this.$emit("useStatus", response.data.data.status);

      this.pages.plug_id = this.plugId;
    },
    async getDeviceRecordDetail(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;
      this.pages.plug_id = this.plugId;
      const response = await this.$store.dispatch(
        "FETCH_GET_PLUG_RECORD_DETAIL",
        this.pages
      );
      console.log("response plug_record_detail: ", response.data);

      //플러그 리스트 불러오기
      const data = response.data;

      if (data.result == false || data.data.error) {
        var error = data.data.error;
        var errorText = "";
        if (error == "SESSION_EXPIRED") {
          errorText = "세션 만료 되었습니다.";
          //todo 홈으로 이동
        } else if (error == "INVALID_CORP") {
          errorText = "유효하지 않은 사업자 입니다.";
        } else if (error == "DB_ERROR") {
          errorText = "DB에 오류가 발생했습니다.";
        } else if (error == "LOGIN_REQUIRED") {
          errorText = "작업을 처리하는데 실패하였습니다.";
        } else {
          errorText = "something wrong";
        }
        alert(errorText);
        return false;
      }
      this.tableData = [];
      this.totalNum = data.data.total || data.data.plug_record_list.length;
      var list = data.data.plug_record_list;

      for (var item of list) {
        var time_from = this.$getDateYYYYMMDD(item.time_from).split(" ");
        var time_to = this.$getDateYYYYMMDD(item.time_to).split(" ");

        time_to[1] =
          time_from[0] != time_to[0] ? time_to[1].concat("(+1)") : time_to[1];

        var temp = {
          userNumber: item.mobile_number,
          status: item.status,
          price: item.charge == -2 ? "관리자" : item.charge,
          date: time_from[0],
          startTime: time_from[1],
          endTime: time_to[1],
          time: this.$getDuringTime(item.time_from, item.time_to)
        };
        this.tableData.push(temp);
      }
      
    }
  }
};
</script>

<style scoped>
h1 {
  color: #454545;
  text-align: center;
}
</style>
