<template>
  <div class="content">
    <div class="row">
      <template slot="title"> </template>
    </div>

    <div class="row">
      <div class="col-12">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <!-- <h4 class="card-title">사이트 리스트</h4> -->
          </template>
          <site-table class="table-hover table-striped"></site-table>
        </card>
      </div>
    </div>
    <!-- //row -->
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import SiteTable from "src/components/Table/SiteTable.vue";

export default {
  components: {
    Card,
    SiteTable
  },
  data() {
    return {
      table1: {}
    };
  },
  methods: {}
};

const tableColumns = ["Id", "Name", "Salary", "Country", "City"];
const tableData = [
  {
    id: 1,
    name: "Dakota Rice",
    salary: "$36.738",
    country: "Niger",
    city: "Oud-Turnhout"
  },
  {
    id: 2,
    name: "Minerva Hooper",
    salary: "$23,789",
    country: "Curaçao",
    city: "Sinaai-Waas"
  },
  {
    id: 3,
    name: "Sage Rodriguez",
    salary: "$56,142",
    country: "Netherlands",
    city: "Baileux"
  },
  {
    id: 4,
    name: "Philip Chaney",
    salary: "$38,735",
    country: "Korea, South",
    city: "Overland Park"
  },
  {
    id: 5,
    name: "Doris Greene",
    salary: "$63,542",
    country: "Malawi",
    city: "Feldkirchen in Kärnten"
  }
];
</script>
<style scoped>
.icon-img20 {
  width: 20px;
  height: 20px;
}

.nav-link {
  display: inline;
  line-height: 25px;
}

/* 사이트 리스트 페이지 css */

.div0 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.container-fluid {
  padding: 15px;
}

.container-fluid .row .card {
  height: 80%;
}
</style>
