<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">{{ $t("useRecord") }}</h4>
          </template>
          <using-table class="table-hover table-striped"></using-table>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import UsingTable from "src/components/Table/UsingTable.vue";

export default {
  components: {
    Card,
    UsingTable
  },
  created() {},
  computed: {
    name() {
      return this.data;
    }
  },
  data() {
    return {
      siteName: "에이스 하이테크 2차",
      i: 5,
      pages: { page: 1, lines: 30 },
      paginatedDate: [],
      maxLine: 30,
      moreLine: 5,
      listSize: 15,
      useList: [],
      location: "서울시 영등포구 문래동",
      plug_record: {
        site_id: "1"
      },
      userName: "",
      status: ["ACTIVE", "NORMAL"],
      filter: document.getElementsByTagName("option")
    };
  },
  created() {
    if (sessionStorage.getItem("login") !== null) {
      this.log = sessionStorage.getItem("login");
      this.userName = this.$store.state.provider_profile.provider_profile.name;
    } else {
      this.log = false;
    }
    console.log(this.userName);
  }
};
</script>

<style scoped>
.card-title {
  padding-top: 5px;
  font-weight: 400;
}
.row {
  height: 50px;
}
.icon-img20 {
  width: 20px;
  height: 20px;
}

.nav-link {
  display: inline;
  line-height: 25px;
}

/* 플러그 상세 페이지 css */

.div0 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.container-fluid {
  padding: 15px;
}

.container-fluid .row .card {
  height: 80%;
}

.container-fluid .row .card .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-fluid .row .dropdown .dropdown-menu {
  left: -130px;
}
</style>
